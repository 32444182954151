import React, { Component, useState } from 'react';
import './PriceList.css';
import DataHandler from './DataHandler';
import ContextMenu from './ContextMenu';
import TaimerComponent from '../TaimerComponent';
import { MenuItem, Button, Switch } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { withSnackbar } from 'notistack';
import { cloneDeep, isEqual } from 'lodash';
import styles from './PriceList.module.scss';
import fieldEditSliderStyles from './FieldEditSlider.module.scss';
import OutlinedField from './OutlinedField';
import DataList from './DataList';
import Slider from './Slider';
import LoaderButton from './LoaderButton';
import { Delete, Edit, MoreHoriz, Star } from '@mui/icons-material';
import StatusTag from './StatusTag';
import colors from '../colors';
import VersionContentManager from './VersionContentManager';

const PriceListEditSection = (props) => {
    const { title, items, allItems, groupOverrides, currency, tr, onAdd, onFieldEdited, onFieldError, onItemDeleted, onListClear, type, additionalComponent, getIdKey } = props;
    const [addMode, setAddMode] = useState(false);
    const idKey = getIdKey(type);
    return (
        <div className={styles.section}>
            <div className={styles.header}>
                <h3>{title}</h3>
            </div>
            {additionalComponent}
            <div className={styles.buttons}>
                {!addMode && (
                    <button className={`${styles.button} ${styles.add}`} onClick={() => setAddMode(!addMode)}>
                        {tr('Add')}
                    </button>
                )}
                {onListClear && <button className={`${styles.button} ${styles.add}`} onClick={() => onListClear(type)}>
                        {tr('Clear all selections')}
                </button>}
            </div>
            {addMode && (
                <div className={styles.addRow}>
                    <DataList
                        label={tr('Select item')}
                        name="new_item"
                        autoFocus
                        openMenuOnFocus
                        options={allItems.filter((i) => items.findIndex((si) => si[idKey] == i.id) == -1).map((i) => ({ ...i, label: i.name || i.label, value: i.id || i.value }))}
                        onChange={(value) => {
                            setAddMode(false);
                            onAdd(value, type);
                        }}
                        shownCount={20}
                        menuWidth={100}
                    />
                    <button
                        className={`${styles.button} ${styles.cancel}`}
                        onClick={() => {
                            setAddMode(false);
                        }}
                    >
                        {tr('Cancel')}
                    </button>
                </div>
            )}
            {(items || []).map((item, i) => {
                return (
                    <div key={item.id} className={styles.row}>
                        {groupOverrides ? (
                            <DataList
                                label={item.label}
                                value={groupOverrides.find((g) => g.id == item.groups_id)}
                                options={groupOverrides}
                                onChange={(value) => onFieldEdited(value, i, type)}
                                shownCount={20}
                                menuWidth={100}
                            />
                        ) : (
                            <OutlinedField
                                key={item.id}
                                label={item.label}
                                value={item.price}
                                autoFocus={type == 'users' && Number(item.id || 0) < 0}
                                format="currency"
                                currency={currency}
                                onChange={(e) => onFieldEdited(e.target.value, i, type)}
                                onError={() => onFieldError(i, type)}
                                maximumFractionDigits={4}
                                validation={['numeric', 'empty']}
                                placeholder="0"
                                shrinkLabel
                            />
                        )}
                        <button className={`${styles.button} ${styles.delete}`} onClick={() => onItemDeleted(item, type)}>
                            <Delete />
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

class PriceListEditSlider extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'general/PriceListEditSlider');

        this.notSelectedItem = { id: 0, value: 0, label: this.tr('Not selected'), name: this.tr('Not selected') };
        this.state = {
            worktypes: cloneDeep(this.props.worktypes),
            protitles: cloneDeep(this.props.protitles),
            usertitles: cloneDeep(this.props.usertitles),
            users: cloneDeep(this.props.users),
            pricelists: cloneDeep(this.props.pricelists),
            editablePricelist: this.props.pricelists && this.props.pricelists.length == 0 ? { id: -1, name: '' } : undefined,
            deleted_worktypes: [],
            deleted_protitles: [],
            deleted_usertitles: [],
            deleted_users: [],
            deleted_pricelists: [],
            onlySelectedWorktasks: this.props.onlySelectedWorktasks,
            activePricelist: this.props.activePricelist,
            invalidFields: {},
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!isEqual(prevProps.worktypes, this.props.worktypes)) {
            this.setState({
                worktypes: cloneDeep(this.props.worktypes),
            });
        }

        if (!isEqual(prevProps.protitles, this.props.protitles)) {
            this.setState({
                protitles: cloneDeep(this.props.protitles),
            });
        }

        if (!isEqual(prevProps.usertitles, this.props.usertitles)) {
            this.setState({
                usertitles: cloneDeep(this.props.usertitles),
            });
        }

        if (!isEqual(prevProps.users, this.props.users)) {
            this.setState({
                users: cloneDeep(this.props.users),
            });
        }

        if (prevProps.onlySelectedWorktasks != this.props.onlySelectedWorktasks) {
            this.setState({
                onlySelectedWorktasks: this.props.onlySelectedWorktasks,
            });
        }

        if (!isEqual(prevProps.activePricelist, this.props.activePricelist)) {
            this.setState({
                activePricelist: this.props.activePricelist,
            });
        }

        if (!isEqual(prevProps.pricelists, this.props.pricelists)) {
            this.setState({
                pricelists: cloneDeep(this.props.pricelists),
                editablePricelist: this.props.pricelists && this.props.pricelists.length == 0 ? { id: -1, name: '' } : this.state.editablePricelist,
            });
        }

        if (prevState.activePricelist?.id > 0 && this.state.activePricelist?.id == -1) {
            this.setState({
                worktypes: [],
                protitles: [],
                usertitles: [],
                users: [],
                deleted_worktypes: [],
                deleted_protitles: [],
                deleted_usertitles: [],
                deleted_users: [],
            });
        }

        if (!prevProps.open && this.props.open) {
            this.setState({
                worktypes: cloneDeep(this.props.worktypes),
                protitles: cloneDeep(this.props.protitles),
                usertitles: cloneDeep(this.props.usertitles),
                users: cloneDeep(this.props.users),
                pricelists: cloneDeep(this.props.pricelists),
                editablePricelist: this.props.pricelists && this.props.pricelists.length == 0 ? { id: -1, name: '' } : undefined,
                deleted_worktypes: [],
                deleted_protitles: [],
                deleted_usertitles: [],
                deleted_users: [],
                deleted_pricelists: [],
                onlySelectedWorktasks: this.props.onlySelectedWorktasks,
                activePricelist: this.props.activePricelist,
                invalidFields: {},
            });
        }
    };

    getIdKey = (type) => {
        switch (type) {
            case 'worktypes':
                return 'worktypes_id';
            case 'protitles':
                return 'groups_id';
            case 'usertitles':
            case 'users':
                return 'users_id';
            default:
                return 'id';
        }
    };

    onItemSelected = (item, type) => {
        const data = cloneDeep(this.state[type]);
        const idKey = this.getIdKey(type);
        let newItem = { ...item, id: -(data.length + 1), [idKey]: item.id };
        if (type == 'usertitles') {
            newItem = {
                ...newItem,
                groups_id: this.props.allProtitles[0]?.id,
            };
        }
        data.unshift(newItem);
        this.setState({ [type]: data });
    };

    onListClear = (type) => {
        const { worktypes, protitles, usertitles, users, deleted_worktypes, deleted_protitles, deleted_usertitles, deleted_users } = this.state;

        this.setState({
            worktypes: [],
            protitles: [],
            usertitles: [],
            users: [],
            deleted_worktypes: [
                ...deleted_worktypes,
                ...worktypes.map(x => ({...x, deleted: 1})),
            ],
            deleted_protitles: [
                ...deleted_protitles,
                ...protitles.map(x => ({...x, deleted: 1})),
            ],
            deleted_usertitles: [
                ...deleted_usertitles,
                ...usertitles.map(x => ({...x, deleted: 1})),
            ],
            deleted_users: [
                ...deleted_users,
                ...users.map(x => ({...x, deleted: 1})),
            ],
        });
    }

    onItemDeleted = (value, type) => {
        const data = cloneDeep(this.state[type]);
        const deleteKey = `deleted_${type}`;
        const deleteData = cloneDeep(this.state[deleteKey]);
        const index = data.findIndex((d) => d.id == value.id);
        if (index != -1) {
            data.splice(index, 1);
        }
        if (value.id > 0) {
            deleteData.push({ ...value, deleted: 1 });
        }
        this.setState({
            [type]: data,
            [deleteKey]: deleteData,
            invalidFields: {
                ...this.state.invalidFields,
                [`${type}_${index}`]: false,
            },
        });
    };

    onFieldEdited = (value, index, type) => {
        const data = cloneDeep(this.state[type]);
        if (type == 'usertitles') {
            data[index] = {
                ...data[index],
                groups_id: value?.id,
            };
        } else {
            data[index] = {
                ...data[index],
                price: value,
            };
        }
        this.setState({
            [type]: data,
            invalidFields: {
                ...this.state.invalidFields,
                [`${type}_${index}`]: false,
            },
        });
    };

    onSave = () => {
        this.props.onSave(this.state);
    };

    getPricelist = () => {
        const { activePricelist } = this.state;
        const { baseUrl } = this.props;
        this.setState(
            {
                worktypes: [],
                protitles: [],
                usertitles: [],
                users: [],
                deleted_worktypes: [],
                deleted_protitles: [],
                deleted_usertitles: [],
                deleted_users: [],
            },
            () => {
                DataHandler.get({ url: activePricelist ? `${baseUrl}/${activePricelist?.id}` : baseUrl }).then((response) => {
                    this.setState(response);
                });
            }
        );
    };

    onlySelectedWorktasksChanged = (e) => {
        this.setState({ onlySelectedWorktasks: e.target.checked });
    };

    setActivePricelist = (activePricelist) => {
        this.setState({ activePricelist, changePriceList: false }, () => {
            this.getPricelist();
        });
    };

    addPriceList = () => {
        this.setState({
            editablePricelist: { id: -1, name: '' },
        });
    };

    renamePriceList = () => {
        this.setState({ editablePricelist: cloneDeep(this.state.activePricelist) });
    };

    deletePricelist = async () => {
        const pricelist = this.state.activePricelist;
        const pricelists = cloneDeep(this.state.pricelists);
        const deleted_pricelists = cloneDeep(this.state.deleted_pricelists);
        if (pricelist.id != -1) {
            deleted_pricelists.push(pricelist);
        }
        const index = pricelists.find((p) => p.id == pricelist?.id);
        if (index != -1) {
            pricelists.splice(index, 1);
        }
        this.setState({
            worktypes: [],
            protitles: [],
            usertitles: [],
            users: [],
            deleted_worktypes: [],
            deleted_protitles: [],
            deleted_usertitles: [],
            deleted_users: [],
            pricelists,
            deleted_pricelists,
            activePricelist: this.notSelectedItem,
        });
    };

    onPricelistNameEdited = (e) => {
        this.setState({
            editablePricelist: {
                ...this.state.editablePricelist,
                name: e.target.value,
            },
        });
    };

    savePricelistName = () => {
        this.setState({ activePricelist: this.state.editablePricelist, editablePricelist: undefined });
    };

    changePriceList = () => {
        this.setState({ changePriceList: true });
    };

    cancelEdit = () => this.setState({ editablePricelist: undefined });
    cancelChange = () => this.setState({ changePriceList: false });

    onFieldError = (index, type) => {
        this.setState({
            invalidFields: {
                ...this.state.invalidFields,
                [`${type}_${index}`]: true,
            },
        });
    };

    render() {
        const { open, onClose, allWorktypes, allProtitles, employees, currency, showUsertitles } = this.props;
        const { worktypes, protitles, users, usertitles, onlySelectedWorktasks, activePricelist, pricelists, editablePricelist, changePriceList, invalidFields } = this.state;
        return (
            <Slider open={open} onClose={onClose} title={this.tr('Edit hourly rates')}>
                <div className={styles.sliderContent}>
                    <div className={styles.sections}>
                        {pricelists &&
                            (editablePricelist ? (
                                <div className={styles.addRow} style={{ marginTop: 0 }}>
                                    <OutlinedField
                                        label={editablePricelist.id == -1 ? this.tr('Name new pricelist') : this.tr('Rename pricelist')}
                                        value={editablePricelist?.name}
                                        onChange={this.onPricelistNameEdited}
                                    />
                                    <button className={`${styles.button} ${styles.cancel}`} onClick={this.cancelEdit}>
                                        {this.tr('Cancel')}
                                    </button>
                                    <button className={styles.button} onClick={this.savePricelistName}>
                                        {this.tr('Save')}
                                    </button>
                                </div>
                            ) : changePriceList ? (
                                <div className={styles.addRow} style={{ marginTop: 0 }}>
                                    <DataList
                                        label={this.tr('Active pricelist')}
                                        name="activePricelist"
                                        autoFocus
                                        openMenuOnFocus
                                        value={activePricelist ? { ...activePricelist, label: activePricelist.name, value: activePricelist.id } : undefined}
                                        options={[this.notSelectedItem, ...pricelists.map((i) => ({ ...i, label: i.name || i.label, value: i.id || i.value }))]}
                                        onChange={(value) => this.setActivePricelist(value)}
                                        shownCount={20}
                                        menuWidth={100}
                                    />
                                    {!!activePricelist && (
                                        <button className={`${styles.button} ${styles.cancel}`} onClick={this.cancelChange}>
                                            {this.tr('Cancel')}
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <div className={styles.activePricelist}>
                                    <div>
                                        <div>
                                            <p>{activePricelist?.id == -1 ? this.tr('New pricelist') : this.tr('Active pricelist')}:</p>
                                            <StatusTag text={activePricelist?.name} color={colors.bluey_purple} />
                                        </div>
                                        {activePricelist?.id != 0 && (
                                            <ContextMenu className="priceListContextMenu row-menu" label={<MoreHoriz />} noExpandIcon>
                                                <MenuItem onClick={this.renamePriceList}>
                                                    <Edit /> {this.tr('Rename')}
                                                </MenuItem>
                                                <MenuItem className="deleteList" onClick={this.deletePricelist}>
                                                    <Delete className="Delete" /> {this.tr('Delete')}
                                                </MenuItem>
                                            </ContextMenu>
                                        )}
                                    </div>
                                    <div>
                                        <button className={styles.button} onClick={this.changePriceList}>
                                            {this.tr('Change')}
                                        </button>
                                        {activePricelist?.id != -1 && (
                                            <button className={styles.button} onClick={this.addPriceList}>
                                                {this.tr('New pricelist')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        {activePricelist?.id != 0 && (!editablePricelist || editablePricelist?.id != -1) && (
                            <>
                                <div className={styles.buttons}>
                                    <button className={styles.button} onClick={this.onListClear}>
                                        {this.tr('Clear all selections')}
                                    </button>
                                </div>

                                <PriceListEditSection
                                    tr={this.tr}
                                    type="worktypes"
                                    currency={currency}
                                    title={this.tr('Worktypes')}
                                    items={worktypes}
                                    allItems={allWorktypes}
                                    onAdd={this.onItemSelected}
                                    onFieldEdited={this.onFieldEdited}
                                    onItemDeleted={this.onItemDeleted}
                                    onFieldError={this.onFieldError}
                                    getIdKey={this.getIdKey}
                                    additionalComponent={
                                        onlySelectedWorktasks != undefined ? (
                                            <div className={styles.switch}>
                                                <p>{this.tr('Use only selected worktasks')}</p>
                                                <Switch checked={onlySelectedWorktasks} onChange={this.onlySelectedWorktasksChanged} />
                                            </div>
                                        ) : undefined
                                    }
                                />
                                {!VersionContentManager.isFeatureHidden(this.namespace, 'professionalTitles') && <PriceListEditSection
                                    tr={this.tr}
                                    type="protitles"
                                    currency={currency}
                                    title={this.tr('Professional Titles')}
                                    items={protitles}
                                    allItems={allProtitles}
                                    onAdd={this.onItemSelected}
                                    onFieldEdited={this.onFieldEdited}
                                    onItemDeleted={this.onItemDeleted}
                                    onFieldError={this.onFieldError}
                                    getIdKey={this.getIdKey}
                                />}
                                {!VersionContentManager.isFeatureHidden(this.namespace, 'users') && <PriceListEditSection
                                    tr={this.tr}
                                    type="users"
                                    currency={currency}
                                    title={this.tr('Users')}
                                    items={users}
                                    allItems={employees}
                                    onAdd={this.onItemSelected}
                                    onFieldEdited={this.onFieldEdited}
                                    onItemDeleted={this.onItemDeleted}
                                    onFieldError={this.onFieldError}
                                    getIdKey={this.getIdKey}
                                />}
                                {showUsertitles && !VersionContentManager.isFeatureHidden(this.namespace, 'userTitles') && (
                                    <PriceListEditSection
                                        tr={this.tr}
                                        type="usertitles"
                                        title={this.tr('Exceptional titles')}
                                        items={usertitles}
                                        allItems={employees}
                                        groupOverrides={allProtitles.map((i) => ({ ...i, label: i.name || i.label, value: i.id || i.value }))}
                                        onAdd={this.onItemSelected}
                                        onFieldEdited={this.onFieldEdited}
                                        onItemDeleted={this.onItemDeleted}
                                        onFieldError={this.onFieldError}
                                        getIdKey={this.getIdKey}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <div className={fieldEditSliderStyles.actions}>
                        <Button onClick={onClose} variant="text" size="large">
                            {this.tr('Cancel')}
                        </Button>
                        <div className={fieldEditSliderStyles.right}>
                            <LoaderButton
                                text={this.tr('Save')}
                                disabled={editablePricelist?.id == -1 || Object.values(invalidFields).indexOf(true) != -1}
                                loading={false}
                                onClick={this.onSave}
                                size="large"
                                color="primary"
                            />
                        </div>
                    </div>
                </div>
            </Slider>
        );
    }
}

class PriceList extends TaimerComponent {
    constructor(props, context) {
        super(props, context, 'general/PriceList');

        this.currencyFormatter = new Intl.NumberFormat(this.context.taimerAccount.numberFormat, {
            style: 'currency',
            currency: this.props.currency || 'EUR',
            maximumFractionDigits: 4,
        }).format;

        this.state = {
            source: null,
            edited: false,
            users: [],
            worktypes: [],
            protitles: [],
            usertitles: [],
            activePricelist: cloneDeep(this.props.activePricelist),
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.activePricelist, this.props.activePricelist)) {
            this.setState({ activePricelist: cloneDeep(this.props.activePricelist) }, () => {
                setTimeout(() => {
                    this.fetchData();
                }, 1000);
            });
        }

        if (prevProps.currency != this.props.currency) {
            this.currencyFormatter = new Intl.NumberFormat(this.context.taimerAccount.numberFormat, {
                style: 'currency',
                currency: this.props.currency || 'EUR',
                maximumFractionDigits: 4,
            }).format;
        }
    }

    fetchData = async (refresh) => {
        const { baseUrl } = this.props;
        const { activePricelist } = this.state;

        try {
            const response = await DataHandler.get({ url: activePricelist ? `${baseUrl}/${activePricelist.id}` : baseUrl, refresh: refresh ? 1 : 0 });

            this.setState(response);
        } catch (error) {
            this.setState({
                source: null,
                edited: false,
                users: [],
                worktypes: [],
                protitles: [],
                usertitles: [],
            });
        }
    };

    closeEditSlider = () => {
        this.setState({ editSliderOpen: false });
    };

    showEditSlider = () => {
        this.setState({ editSliderOpen: true });
    };

    onSave = async ({
        worktypes,
        protitles,
        usertitles,
        users,
        deleted_worktypes,
        deleted_protitles,
        deleted_usertitles,
        deleted_users,
        deleted_pricelists,
        onlySelectedWorktasks,
        activePricelist,
    }) => {
        this.setState({ worktypes, protitles, usertitles, users, activePricelist }, () => {
            this.closeEditSlider();
        });
        const showUsertitles = this.showUsertitles();
        let pricelistId = activePricelist?.id;
        if (pricelistId == -1 && this.props.createNewUrl) {
            const response = await DataHandler.post(
                { url: this.props.createNewUrl },
                {
                    name: activePricelist?.name,
                }
            );
            pricelistId = response?.id;
        }
        if (deleted_pricelists.length > 0) {
            deleted_pricelists.forEach((pl) => {
                DataHandler.delete({ url: `accounts/pricelists/${pl?.id}` });
            });
        }
        if (pricelistId && pricelistId > 0) {
            await DataHandler.put(
                { url: `${this.props.baseUrl}/${pricelistId}` },
                {
                    name: activePricelist?.name,
                    active: true,
                }
            );
        } else if (pricelistId == 0) {
            await DataHandler.put(
                { url: `${this.props.baseUrl}/${this.props.activePricelist?.id}` },
                {
                    active: false,
                }
            );
        }
        if (pricelistId != 0) {
            await DataHandler.post(
                { url: pricelistId ? `${this.props.baseUrl}/${pricelistId}/data` : this.props.baseUrl },
                {
                    worktypes: [...deleted_worktypes, ...worktypes],
                    protitles: [...deleted_protitles, ...protitles],
                    users: [...deleted_users, ...users],
                    ...(showUsertitles ? { usertitles: [...deleted_usertitles, ...usertitles] } : {}),
                }
            );
        }
        
        this.fetchData(true);

        this.props.onOnlySelectedWorktasksChanged && this.props.onOnlySelectedWorktasksChanged(onlySelectedWorktasks);
        this.props.enqueueSnackbar(this.tr('Changed save                                                                                                                                                                                                                    d!'), { variant: 'success' });
        setTimeout(() => {
            this.props.afterSave && this.props.afterSave();
        }, 1000);                                               
    };
    
    showUsertitles = () => {
        return this.props.entity == 'project';
    };

    showUpgradeSlider = () => {
        this.context.functions.showUpgradeSlider();
    }

    render() {
        const { employees, worktypes: allWorktypes, protitles: allProtitles, editable, onlySelectedWorktasks, currency, onImportPrices, pricelists, baseUrl, importPricesLabel, needsUpgrade } = this.props;
        const { users, worktypes, protitles, usertitles, editSliderOpen, activePricelist, source, edited } = this.state;
        const showUsertitles = this.showUsertitles();
        const employeesWithTags = employees?.map(e => ({...e, 
            name: `${e.name} ${e.companies_id < 1 ? ` (${this.tr("freelancer")})` : ''}${e.locked > 0 && this.props.showLockedUsersWithTag ? ` (${this.tr("locked")})` : ''}`
        }));
        return (
            <div className={styles.priceList}>
                {editable && (
                    <div className={styles.actions}>
                        <button className={styles.button} onClick={needsUpgrade ? this.showUpgradeSlider : this.showEditSlider}>
                            {this.tr('Edit')}
                            {needsUpgrade && <Star />}
                        </button>
                        {onImportPrices && (!pricelists || (pricelists && Number(activePricelist?.id || 0) > 0)) && (
                            <Tooltip title={importPricesLabel || ''} arrow placement="right" classes={{ tooltip: 'darkblue-tooltip' }}>
                                <button className={styles.button} onClick={needsUpgrade ? this.showUpgradeSlider : () => onImportPrices(activePricelist)}>
                                    {this.tr('Import')}
                                    {needsUpgrade && <Star />}
                                </button>
                            </Tooltip>
                        )}
                    </div>
                )}
                {activePricelist && (
                    <div className={`${styles.row} ${styles.center}`} style={{ marginTop: 12 }}>
                        <p>{this.tr('Active pricelist')}</p>
                        <StatusTag text={activePricelist?.name} color={colors.bluey_purple} />
                    </div>
                )}
                {source && (
                    <div className={`${styles.row} ${styles.center}`} style={{ marginTop: 12 }}>
                        <p>{this.tr('Imported from')}</p>
                        <StatusTag text={`${source} ${(edited ? this.tr('(edited)') : '')}`} color={colors.bluey_purple} />
                    </div>
                )}
                {(worktypes.length > 0 || onlySelectedWorktasks) && (
                    <div className={styles.section}>
                        <div className={styles.header}>
                            <h3>{this.tr('Worktypes')}</h3>
                            {onlySelectedWorktasks && <StatusTag text={this.tr('Only use selected jobtypes')} color={colors.bluey_purple} />}
                        </div>
                        {worktypes.map((item) => {
                            return (
                                <div className={styles.row} key={item.id}>
                                    <p>{item.label}</p>
                                    <p>{this.currencyFormatter(item.price)}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
                {protitles.length > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'professionalTitles') && (
                    <div className={styles.section}>
                        <h3>{this.tr('Professional Titles')}</h3>
                        {protitles.map((item) => {
                            return (
                                <div className={styles.row} key={item.id}>
                                    <p>{item.label}</p>
                                    <p>{this.currencyFormatter(item.price)}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
                {users.length > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'users') && (
                    <div className={styles.section}>
                        <h3>{this.tr('Users')}</h3>
                        {users.map((item) => {
                            return (
                                <div className={styles.row} key={item.id}>
                                    <p>{item.label}</p>
                                    <p>{this.currencyFormatter(item.price)}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
                {showUsertitles && usertitles.length > 0 && !VersionContentManager.isFeatureHidden(this.namespace, 'userTitles') && (
                    <div className={styles.section}>
                        <h3>{this.tr('Exceptional titles')}</h3>
                        {usertitles.map((item) => {
                            return (
                                <div className={styles.row} key={item.id}>
                                    <p>{item.label}</p>
                                    <p>{allProtitles.find((p) => p.id == item.groups_id)?.name}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
                <PriceListEditSlider
                    open={editSliderOpen}
                    onClose={this.closeEditSlider}
                    worktypes={worktypes}
                    protitles={protitles}
                    usertitles={usertitles}
                    users={users}
                    allWorktypes={allWorktypes}
                    allProtitles={allProtitles}
                    employees={employeesWithTags}
                    currency={currency}
                    onSave={this.onSave}
                    showUsertitles={showUsertitles}
                    onlySelectedWorktasks={onlySelectedWorktasks}
                    activePricelist={activePricelist}
                    pricelists={pricelists}
                    baseUrl={baseUrl}
                />
            </div>
        );
    }
}

export default withSnackbar(PriceList);
