import { Error, FileCopy, Lock, MoreHoriz } from '@mui/icons-material';
import { MenuItem, Tooltip } from '@mui/material';
import { isEqual } from 'lodash';
import { withSnackbar } from 'notistack';
import ContextMenu from '../../general/ContextMenu';
import DataHandler from '../../general/DataHandler';
import AutoCompleteCell from '../cells/AutoCompleteCell';
import CheckboxCell from '../cells/CheckboxCell';
import TextInputCell from '../cells/TextInputCell';
import CurrencyListCell from '../CurrencyListCell';
import LinkListCell from '../LinkListCell';
import ListCell from '../ListCell';
import PropsOnlyListRow from '../PropsOnlyListRow';

class MassInvoicingListRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, { addresses: [], isLoading: false }, {}, 'list/rows/MassInvoicingListRow');
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(this.state.addresses, nextState.addresses) || this.state.isLoading !== nextState.isLoading) {
            return true;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }

    getAddresses = () => {
        const { data } = this.props;
        this.setState({ isLoading: true }, () => {
            DataHandler.get({ url: `accounts/${data.account_id}/invoicing_addresses/${data.companies_id}` }).done((addresses) => {
                this.setState({
                    isLoading: false,
                    addresses: addresses.map((addressData) => ({
                        ...addressData,
                        name: `${addressData.name}, ${addressData.address}, ${addressData.postalcode} ${addressData.city}, ${addressData.country}`,
                    })),
                });
            });
        });
    };

    onAddressEdited = (value) => {
        const { data, enqueueSnackbar } = this.props;
        const { addresses } = this.state;
        this.cellEdited({ address: value.name, bill_language: value.bill_language, reverse_charge: value.reverse_charge });
        const address = addresses.find((a) => a.id == value.id);
        if (address) {
            DataHandler.put({ url: `projects/${data.id}` }, { invoicing_address: address })
                .done(() => {
                    enqueueSnackbar(this.tr("Project's invoicing address changed successfully!"), { variant: 'success' });
                })
                .fail((err) => {
                    console.error(err);
                    enqueueSnackbar(this.tr("Changing project's invoicing address failed!"), { variant: 'error' });
                });
        }
    };

    defineCells() {
        const {
            data,
            columnWidthMap,
            rowProps: { onLockRow, onCreateRegularInvoice, currency },
        } = this.props;
        const { addresses, isLoading } = this.state;
        const currencyCells = ['invoiced_total', 'invoice_material', 'scheduled_invoices', 'hours', 'expenses', 'bills', 'quotes'];
        const cells = {};
        
        Object.keys(this.props.columnConfig).forEach((key) => {   
            if (currencyCells.includes(key)) {
                cells[key] = <CurrencyListCell allowNaN={true} showZero={true} value={data[key]} currency={currency} editable={false} />;
            } else {
                cells[key] = <TextInputCell width={columnWidthMap[key]} name={key} editable={false} value={data[key]} />;
            }
        });

        return {
            ...cells,
            menu: (
                <ContextMenu
                    label={<MoreHoriz />}
                    buttonProps={{ className: 'action-menu' }}
                    className="cell row-menu"
                    style={{ width: columnWidthMap['menu'] + 'px', flex: columnWidthMap['menu'] + ' 1 0px' }}
                    noExpandIcon
                >
                    <MenuItem onClick={() => onLockRow(data)}>
                        <Lock title="" />
                        {this.tr('Lock Material and Generate Invoice')}
                    </MenuItem>
                    <MenuItem onClick={() => onCreateRegularInvoice(data)}>
                        <FileCopy title="" />
                        {this.tr('Create Regular Invoice')}
                    </MenuItem>
                </ContextMenu>
            ),
            checked: <CheckboxCell checked={this.props.checked} onClick={() => this.props.listRef.check(data.id)} />,
            account: (
                <LinkListCell
                    urlHandler={(value) => `index.html?module=customers&action=view&id=${data.account_id}`}
                    asText={data.can_see_account == 0}
                    style={{ width: columnWidthMap.account + 'px' }}
                    width={columnWidthMap.account}
                    value={data.account}
                    editable={false}
                    noTab={true}
                />
            ),
            project: (
                <LinkListCell
                    urlHandler={(value) => `index.html?module=projects&action=view&id=${data.id}`}
                    asText={data.can_see_project == 0}
                    style={{ width: columnWidthMap.project + 'px' }}
                    width={columnWidthMap.project}
                    value={data.project}
                    editable={false}
                    noTab={true}
                />
            ),
            reverse_charge: <TextInputCell width={columnWidthMap.reverse_charge} name="reverse_charge" editable={false} value={data.reverse_charge == 1 ? this.tr('Yes') : this.tr('No')} />,
            custom_grouping: <TextInputCell width={columnWidthMap.custom_grouping} name="reverse_charge" editable={false} value={data.custom_grouping == 1 ? this.tr('Yes') : this.tr('No')} />,
            bill_language: <TextInputCell width={columnWidthMap.bill_language} name="bill_language" editable={false} value={(data.bill_language || '').toUpperCase()} />,
            has_grouping_settings: (
                <ListCell onlyDisplay width={columnWidthMap.has_grouping_settings}>
                    {!(!Number(data.invoicing_hour_task_grouping || 0) && !Number(data.invoicing_hour_grouping || 0) && !Number(data.invoicing_expense_grouping || 0) && !Number(data.invoicing_quote_grouping || 0)) && (
                        <Tooltip title={this.tr('This project has invoice material grouping settings that will override the settings chosen in this dialog.')}>
                            <Error />
                        </Tooltip>
                    )}
                </ListCell>
            ),
            address: (
                <AutoCompleteCell
                    width={columnWidthMap.address}
                    value={data.address}
                    selectProps={{ onFocus: this.getAddresses, isLoading }}
                    autoCompleteData={addresses}
                    showStringValue
                    searchable
                    name="address"
                    onEdited={this.onAddressEdited}
                    editable={true}
                />
            ),
        };
    }
}

export default withSnackbar(MassInvoicingListRow);
