import React from 'react';

import _ from 'lodash';
import isEqual from "lodash/isEqual";
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import { FormLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ReactSelect, { createFilter, NonceProvider } from 'react-select';
import validator from 'validator';
import { SettingsContext } from '../SettingsContext';

import { AsyncPaginate } from 'react-select-async-paginate';

import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import OutlinedField from "./../general/OutlinedField";
import { List, AutoSizer } from 'react-virtualized';

import './DataList.css';
import colors from '../colors';
import customStyles from './DataList.module.scss';

export const validators = {
  empty: e => {
    if(e === undefined) {
      return true;
    }
    return !validator.isEmpty(e, { ignore_whitespace: true })
  },
  numeric: e => !e || !e.length || validator.isNumeric(e),
  email: e => !e || !e.length || validator.isEmail(e),
};

// import { Menu as Menuaa } from './react-select/lib/index'

const styles = theme => ({
  //  root: {
  //    flexGrow: 1,
  //    height: 250,
  //    border: 0,
  //  },
  //  input: {
  //    display: 'flex',
  //    padding: 0,
  //    border: 0
  // },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    padding: `${theme.spacing()} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: '12px !important',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  arrowdropdown: {
    width: "18px",
    height: "18px",
    top: "50%",
    right: "6px",
    position: "absolute",
    "margin-top": "-9px",
    "border-radius": "18px",
    "background-color": "#f7f8fc",
    "cursor": "pointer",
    color: 'rgba(0, 0, 0, 0.54)',
  },
  multiValueLabel: {
    overflow: "hidden",
    "text-overflow": "ellipsis",
    "white-space": "nowrap",
    "border-radius": "2px",
    color: "white",
    "font-size": "85%",
    padding: "3px",
    "padding-left": "6px",
    "box-sizing": "border-box",
  },
  multiValue: {
    display: "flex",
    "min-width": 0,
    "background-color": "#6b7897",
    "border-radius": "3px",
    margin: "0px",
    "box-sizing": "border-box",
    "margin-right": "8px",
    "margin-bottom": "4px",
    color: "white",
    padding: "0 3px",
  }
});

const globalCustomStyles = {
  multiValue: (provided) => ({
    ...provided,
    marginRight: 8,
    marginBottom: 4,
    backgroundColor: colors.steel,
    color: 'white',
    borderRadius: 3,
    padding: '0 3px'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white'
  }),
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 666,
      minWidth: "max-content",
      overflow: 'hidden',
    }
  }, menuPortal: (provided, state) => {
    return {
      ...provided,
      zIndex: 666
    }
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      cursor: "pointer",
    }
  },
};

function NoOptionsMessage(props) {
  return (
    <FormLabel
      // color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </FormLabel>
  );
}


function inputComponent({ inputRef, ...props }) {
  return <span style={{ background: 'blue' }} className="sick" ref={inputRef} {...props} />;
}

const MenuList = props => {
  if (!props.children.length) {
    return props.children;
  }

  const rows = props.children;
  let menuAutoWidth = props.menuWidth || props.selectProps?.menuWidth || 'auto';

  if (rows && rows.length > 0 && !props.menuWidth && !props.selectProps?.menuWidth) {
    const len = Math.max(...rows.map(x => String(x.props.label || "").length));
    menuAutoWidth = (len + 4) + "ch";
  }

  const getRowTitle = (rowIndex) => {
    if (!props.selectProps?.showOptionTitle) {
      return undefined;
    }

    const row = rows[rowIndex];
    let rowLabel = row?.props?.label || "";
    const additionalText = row?.props.data?.additionalText;

    if (additionalText) {
      rowLabel += " " + additionalText;
    }
    return rowLabel;
  }

  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
    <div title={getRowTitle(index)} className="datalistRow" key={key} style={{...style}}>{rows[index]}</div>
  );

  return (
    <List
      style={{ width: menuAutoWidth, minWidth: "100%" }}
      width={1000}
      height={Math.min(rows.length * 46, 300)}
      rowHeight={46}
      rowCount={rows.length}
      rowRenderer={rowRenderer}
    />
  )
}

function Control(props) {
  const style = {
    display: 'flex',
    height: props.isMulti ? "auto" : undefined
  };

  return (
    <TextField
      // variant={props.selectProps.variant || "outlined"}
      variant="filled"
      fullWidth
      disabled={props.isDisabled}
      InputProps={{
        inputComponent,
        ...props.selectProps.inputBaseProps,
        inputProps: {
          style,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  /* mousemover ja over lagaa jostain syystä. Mutta hoitaa hover efektiä */
  //delete props.innerProps.onMouseMove;
  //delete props.innerProps.onMouseOver;
  let icon = undefined;

  if (props.data.icon) {
    const headerIconClass = props.data.type === "header" ? "datalist-listsubheader-icon" : "";
    icon = <props.data.icon className={`datalist-menuitem-icon ${headerIconClass} ${props.data.iconClass}`} />
  }

  if (props.data?.iconComponent) {
    icon = props.data.iconComponent;
  }
  
  const rows = props.options;
  let menuAutoWidth = props.menuWidth || props.selectProps.menuWidth || 'auto';
  if (rows && rows.length > 0 && !props.menuWidth && !props.selectProps.menuWidth) {
    const len = Math.max(...rows.map(x => String(x.label || "").length));
    menuAutoWidth = (len + 4) + "ch";
  }

  if (props.data.type === "header") {
    return (
      <ListSubheader className={`datalist-listsubheader ${props.data.className}`}>
        {icon} {props.children}
      </ListSubheader>
    )
  }

  const showDisabledSubtext = props.isDisabled && props.selectProps.disabledSubtext;
  const showDataSubText = props.data.subText && !showDisabledSubtext;
  let menuItemClassName = customStyles.option;
  if (showDisabledSubtext || showDataSubText) {
    menuItemClassName += " " + customStyles['subtext-option'];
  }

  return (
        <MenuItem
          buttonRef={props.innerRef}
          selected={props.isFocused}
          disabled={props.isDisabled}
          component="div"
          className={menuItemClassName}
          style={{
            fontWeight: props.isSelected ? 500 : 400,
            width: menuAutoWidth,
          }}
          {...props.innerProps}
          data-testid={props.data['data-testid'] || `datalist_option_${props.data.label}`}
        >
          {icon} {props.data.useParagraph ? <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.children}</p> : props.children}
          
          {props.data.additionalText && 
            <div className="additional-option-text">&nbsp;{props.data.additionalText}</div>}

          {showDataSubText && 
            <div className="listitem-subtext light">{props.data.subText}</div>}
    
          {showDisabledSubtext && 
            <div className="listitem-subtext">{props.selectProps.disabledSubtext}</div>}
    
        </MenuItem>
    
  );
}

function Placeholder(props) {
  return (
    <FormLabel
      // color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </FormLabel>
  );
}

function SingleValue(props) {
  const subText = props?.data?.subText ? props?.data?.subText : "";

  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children} <span className="additional-text">{subText}</span>
    </Typography>
  );
}

function MultiValueContainer(props) {
  const style = props.data?.bgColor 
    ? { "background-color": props.data?.bgColor  }
    : {};

  return (
    <div className={props.selectProps.classes.multiValue} style={style}>
      {props.children}
    </div>
  );
}

function MultiValueLabel(props) {
  const label = props.data?.additionalText
    ? props.data?.label + props.data?.additionalText
    : props.data?.label;

  return (
    <div className={props.selectProps.classes.multiValueLabel}>
      {label}
    </div>
  );
}

function ValueContainer(props) {
  const value = props.selectProps.value;
  return (
      <div className={`${props.selectProps.classes.valueContainer} datalistValue ${props.isMulti ? 'multi' : ''}`}>
        {value?.iconComponent}
        {props.children}
      </div>
  );
}



function Menu(props) {
  return (
    <Paper square role="tooltip" className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}


function OwnDropIndicator(props) {
  return <ArrowDropDown className={props.selectProps.classes.arrowdropdown} />;
}

const components = {
  Control,
  //Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator: null,
  DropdownIndicator: OwnDropIndicator,
  ClearIndicator: null,
  MultiValueContainer,
  MultiValueLabel
};

class DataList extends React.Component {
  components = components;

  constructor(props) {
    super(props);

    this.state = {
      noOptionsOpen: false,
      noOptionsTarget: false,
      options: [],
      initialValue: undefined,
      value: "",
      valid: false,
      focus: false,
      inputValue: undefined,
      emptied: false
    }

    this.nextOptions = this.nextOptions.bind(this);

    this.refField = React.createRef();
    this.selectRef = React.createRef();

    this.components = {...components};

    this.asyncPaginateKey = 1;

    if (props.noOptions)
      this.components = { ...this.components, NoOptionsMessage: props.noOptions };

    if (props.customOption) {
      this.components = { ...this.components, Option: props.customOption };
    }

    if (props.customSingleValue) {
      this.components = { ...this.components, SingleValue: props.customSingleValue };
    }

    if (props.virtualized) {
      this.components.MenuList = MenuList;
    }

    ["focus", "mountNoOptions", "unmountNoOptions", /*"onChange"*/, "isValid", "onInputChange", "onBlur", "onKeyDown", "onSelectChange"].forEach(e => this[e] = this[e].bind(this));
  }

  componentDidUpdate(prevProps, prevState) {    
    const { allowCreate } = this.props;
    if(this.props.value !== prevProps.value && !allowCreate)
      this.setState({inputValue: undefined});
  }

   
  componentWillReceiveProps(nextProps) {
    if(!isEqual(this.props.options, nextProps.options)) {
      ++this.asyncPaginateKey;
    }
  }

  focus = () => {
    this.refField.current.focus && this.refField.current.focus();
  }

  blur = () => {
    this.selectRef.current.blur && this.selectRef.current.blur();
  }

  mountNoOptions(selectProps, target) {
    this.setState({ noOptionsOpen: selectProps, noOptionsTarget: target });
    return selectProps;
  }
  unmountNoOptions() {
    this.setState({ noOptionsOpen: false });
  }

  nextOptions(search, loadedOptions, { page }) {
    if (!this.props.shownCount){
      return {
        options: this.allOptions,
        hasMore: false,
        additional: {
          page: page,
        }
      };
    }
    
    const foundOptions = (this.allOptions || []).filter(el => (el.label ? el.label : '').toLowerCase().includes(search.toLowerCase()));
    const options = foundOptions.slice(this.props.shownCount * (page - 1), this.props.shownCount * page);
    return {
      options: options,
      hasMore: foundOptions.length - loadedOptions.length > options.length,
      additional: {
        page: page + 1,
      }
    };
  }

  isValid(value) {
    value = value !== undefined ? value : this.state.inputValue;
    const { validation } = this.props;
    const valid = !validation || validation.find(e => !validators[e](value)) === undefined;
    this.setState({ valid });
    return valid;
  }

  onInputChange(e) {
    const options = (this.allOptions || []).filter(el => (el.label ? el.label : '').toLowerCase().includes(e.target.value.toLowerCase()));
    const valid = this.isValid(e.target.value);
    this.setState({inputValue: e.target.value, valid: valid, options: options, emptied: false});
  }

  onKeyDown(e) {
    if (e.target.value === "" && e.key === "Backspace") {
      this.setState({inputValue: e.target.value, valid: true, options: this.allOptions});
      this.setState({emptied: true});
    } else if (!this.props.noEmptiedResetOnKeyDown) {
        this.setState({emptied: false});
    }
  }

  onSelectChange(e, item) {
    this.setState({emptied: false});
    this.props.onChange && this.props.onChange(e, item);
  }

  onBlur(e) {
    const { options, valid } = this.state;
    const { allowCreate, onCreate} = this.props;
    const matches = [];
    this.setState({emptied: false});

    if(allowCreate) {
      options.forEach(option => {
          if (option.name && option.name.toLowerCase() === e.target.value.toLowerCase()){
            matches.push(option);
          }
      });
      if(valid){
        const evt = {target: {name: this.props.name, value: e.target.value}}
        onCreate(evt)
      } 
    }
  }

  hasFilterMatches = (inputValue) => {
    if (inputValue === undefined) inputValue = "";

    const results = this.props.options ? this.props.options.filter((o) =>
       o.label.toLowerCase().includes(inputValue.toLowerCase())
    ) : [];

    return results.length > 0;
  }

  render() {
    const { value = "", label, validation, component: Component = AsyncPaginate, error, dropLabel, noOptions: NoOptions, disableNoOptions, isDisabled } = this.props;
    const { noOptionsOpen, noOptionsTarget, valid, inputValue, options, emptied } = this.state;
    this.allOptions = this.props.options;
    let textFieldProps = {
      label: dropLabel || label || "",
      name: this.props.name,
      className: 'textfield',
      error: validation ? !valid : error,
      onKeyDown: this.onKeyDown,
      onBlur: this.onBlur
    };

      textFieldProps = {
        ...textFieldProps,
        onChange: this.onInputChange,
        onBlur: this.onBlur
      }

    if (value && Object.keys(value).length > 0) {
      textFieldProps.InputLabelProps = {
        shrink: true,
        className: 'label'
      };
    }

    if (this.props.textFieldProps) {
      for (const i in this.props.textFieldProps) {
        textFieldProps[i] = this.props.textFieldProps[i];
      }
    }
    const addProps = {}
    if (Component === AsyncPaginate) {
      addProps.key = this.asyncPaginateKey;
      addProps.options = null;
      addProps.loadOptions = this.props.loadOptions || this.nextOptions;
      addProps.additional = { page: 1}
    }

    const prepareOptions = () => {
      const {options} = this.props;
      if (this.props.addNoneOption && !options.find(o => o.id == 0))
        options.unshift({id: 0, label: this.props.noneOptionLabel || '', name: this.props.noneOptionLabel || ''});
      return options;
    }

    const filterOptionsWithHeaders = (option, inputValue) => {
      if (option.data.type === "header") {
        // If don't find options with same headerName as header's name, don't show header.
        const headerOptions = this.props.options.filter(el => (el.headerName && el.headerName.toLowerCase() === option.data.name.toLowerCase() && el.label.toLowerCase().includes(inputValue.toLowerCase())));
        if (headerOptions.length === 0)
          return false;
      }

      return option.label.toLowerCase().includes(inputValue.toLowerCase()) || option.data.type === "header";
    };

    let customStyles = { ...globalCustomStyles };

    if (this.props.menuWidth) {
      customStyles = {
        ...customStyles,
        menu: (provided, state) => {
          return {
            ...provided,
            zIndex: 666,
            minWidth: this.props.menuWidth + 50 // wow. quick fix for proposal, because of box-sizing on option
          }
        }
      };
    }
    if (this.props.menuMarginLeft) {
      // i dont know why this works but there was some issues with menu width at projectview and this fixes it
      customStyles = {
        ...customStyles,
        menu: (provided, state) => {
          return {
            ...provided,
            zIndex: 666,
            marginLeft: this.props.menuMarginLeft,
          }
        }
      };
    }

    if (this.props.menuListMaxHeight) {
      customStyles = {
        ...customStyles,
        menuList: (provided) => {
          return {
            ...provided,
            maxHeight: this.props.menuListMaxHeight,
          }
        }
      }
    }

    const noOptionsUp = this.props.noOptionsUp ? !this.hasFilterMatches(this.state.inputValue) : false;

    return (
      <div data-testid={this.props['data-testid'] || `datalist_${this.props.name}`} className={(isDisabled ? 'datalist-disabled ' : '') + (disableNoOptions && options.length == 0 ? 'options-hidden datalist-container' : 'react-select-root datalist-container') + (noOptionsUp ? " datalist-no-options-up " : " ") + (this.props.multiLineValue ? " datalist-multiline-value " : " ") + (this.props.className ? this.props.className : '')}>
        {((!noOptionsOpen || (noOptionsOpen && this.state.focus)) || !this.props.hideDatalistWhenNoOptionInFocus) &&
            <Component ref={this.refField}
            selectRef={this.selectRef}
            {...this.props}
            options={prepareOptions()}
            styles={customStyles}
            onMenuClose={this.onMenuClose}
            components={this.components}
            value={emptied ? "" : value}
            inputValue={inputValue}
            error={error || (validation && !valid)}
            placeholder={this.props.placeholder || ''}
            textFieldProps={textFieldProps}
            mountNoOptions={this.mountNoOptions}
            onChange={this.onSelectChange}
            noOptionsMessage={this.props.getNoOptionsMessage || this.props.noOptionsMessage && (()=>this.props.noOptionsMessage)}
            filterOption={this.props.hasHeaders ? (option, value) => filterOptionsWithHeaders(option, value) : createFilter({ignoreAccents: false})}
            openMenuOnFocus={this.props.openMenuOnFocus}
            tabSelectsValue={this.props.tabSelectsValue}
            {...addProps}
            />
        }
        {noOptionsOpen ? <NoOptions height={this.props.menuListMaxHeight} selectProps={{...noOptionsOpen, enqueueSnackbar: this.props.enqueueSnackbar}} target={noOptionsTarget} unMount={this.unmountNoOptions} /> : undefined}
      </div>
    );
  }
}

DataList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  //name: PropTypes.string.isRequired,
  validation: PropTypes.array,
  error: PropTypes.bool,
  //error: false,
  //shownCount: false
};

export default withStyles(styles, { withTheme: true })(DataList);
