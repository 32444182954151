import React from 'react';
import ReactDOM from 'react-dom';
import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from './../SettingsContext';

import DataList from "./../general/DataList";

import { Switch,ClickAwayListener } from '@mui/material';

import './CostsAutomation.css';
import './CostsAutomation.css';

class CostsAutomation extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/CostsAutomation");

        this.workhoursGrouping = [
            ...((this.context.addons.procountor_accounting || this.context.addons.heeros) ? [] : [{id: "5", label: this.tr("All in one")}]),
            {id: "1", label: this.tr("Hour entries specified")},
            {id: "2", label: this.tr("Per employee and task")},
            ...((this.context.addons.procountor_accounting || this.context.addons.heeros) ? [] : [{id: "3", label: this.tr("Per employee")}]),
            {id: "4", label: this.tr("Per task")}
        ];

        this.expensesGrouping = [
            {id: "3", label: this.tr("Travel expenses all in one")},
            {id: "1", label: this.tr("Daily allowance, mileage and other expenses specified")},
            {id: "2", label: this.tr("Daily allowance and mileage in one, other expenses specified")}
        ];

    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {

        const { costChargeData, editable } = this.props;

        const visibility = costChargeData.visibility.reduce((acc, cur, i) => {
            acc[Object.keys(cur)[0]] = Object.values(cur)[0];
            return acc;
        }, {});

        const overrideStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
              ...styles,
              backgroundColor: isFocused ? 'rgba(44,159,247,.08)' : (isSelected ? 'rgba(44,159,247,.08)' : (isFocused ? 'rgba(44,159,247,.08)' : null)),
              height: '100px'
            }),            
        }        

        return (
            <ClickAwayListener onClickAway={this.props.onClose}>
                <div className="costs-automation">
                    {visibility.charge_hours && <div className="costs-automation-row">
                        <div className="automation-item" >
                            <span>{this.tr('Charge workhours')}</span>                    
                            <Switch
                                name="charge_hours"
                                color="primary"
                                disabled={!editable}
                                onChange={(e, val) => editable && this.props.edit('charge_hours', val ? 1 : 0)}
                                checked={costChargeData.hours > 0} />
                        </div>                                         
                    </div>}

                    {visibility.charge_traveling && <div className="costs-automation-row">
                        <div className="automation-item" >
                            <span>{this.tr('Charge expenses')}</span>                    
                            <Switch
                                name="charge_traveling"
                                color="primary"
                                disabled={!editable}
                                onChange={(e, val) => editable && this.props.edit('charge_expenses', val ? 1 : 0)}
                                checked={costChargeData.expenses > 0} />
                        </div>                                         
                    </div>}

                    {visibility.charge_received_invoices && <div className="costs-automation-row">
                        <div className="automation-item" >
                            <span>{this.tr('Charge received invoices')}</span>                    
                            <Switch
                                name="charge_received_invoices"
                                color="primary"
                                disabled={!editable}
                                onChange={(e, val) => editable && this.props.edit('charge_received_invoices', val ? 1 : 0)}
                                checked={costChargeData.received_invoices > 0} />
                        </div>                                         
                    </div>}

                    {visibility.charge_others && <div className="costs-automation-row">
                        <div className="automation-item" >
                            <span>{this.tr('Charge scheduled invoices')}</span>                    
                            <Switch
                                name="charge_scheduled_invoices"
                                color="primary"
                                disabled={!editable}
                                onChange={(e, val) => editable && this.props.edit('charge_scheduled_invoices', val ? 1 : 0)}
                                checked={costChargeData.scheduled_invoices > 0} />
                        </div>                                         
                    </div>}

                    {true && visibility.charge_hours && <div className="costs-automation-row">
                        <div className="automation-item" >                 
                            <DataList
                                value={this.workhoursGrouping.find(w => w.id == costChargeData.hours_grouping) || (costChargeData.hours > 0 && this.workhoursGrouping.find(w => w.id == '4'))}
                                label={this.tr("Workhours grouping")}
                                className={`select-field ${costChargeData.hours < 1 ? 'disabled' : ''}`}
                                isLoading={false}
                                options={this.workhoursGrouping}
                                onChange={option => this.props.edit('hours_grouping', option.id)}
                                openMenuOnFocus
                                isClearable={false}
                                isDisabled={!editable || costChargeData.hours < 1}
                                overrideStyles={overrideStyles} />
                        </div>                                         
                    </div>}

                    {true && visibility.charge_traveling && <div className="costs-automation-row">
                        <div className="automation-item" >                 
                            <DataList
                                value={this.expensesGrouping.find(w => w.id == costChargeData.expenses_grouping) || (costChargeData.expenses > 0 && this.expensesGrouping.find(w => w.id == '1'))}
                                label={this.tr("Expenses grouping")}
                                className={`select-field ${costChargeData.expenses < 1 ? 'disabled' : ''}`}
                                isLoading={false}
                                options={this.expensesGrouping}
                                onChange={option => this.props.edit('expenses_grouping', option.id)}
                                openMenuOnFocus
                                isClearable={false}
                                isDisabled={!editable || costChargeData.expenses < 1}
                                overrideStyles={overrideStyles} />
                        </div>                                         
                    </div>}                


                </div>
            </ClickAwayListener>
            
        );
    }
}

CostsAutomation.defaultProps = {
    visible: 0
};

export default CostsAutomation;
