import React from 'react';
import _, { cloneDeep, uniqBy } from 'lodash';
import TaimerComponent from '../TaimerComponent';
import DataHandler from './DataHandler';
import FieldEditSlider, { EditableField, FieldEditSliderProps } from './FieldEditSlider';
import { AddCircleOutlined } from '@mui/icons-material';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import CustomFields from '../projects/customfields/CustomFields';
import Link from './Link';
import AddTag from './no-options/AddTag';
import EntityCreatedSnackbar from './EntityCreatedSnackbar';
import CreateSubUnitNotification from '../accounts/CreateSubUnitNotification';
import VersionContentManager from './VersionContentManager';

interface Props extends WithSnackbarProps, FieldEditSliderProps {
    initialSelectionProps?: any;
    onAccountCreated?: (account) => void;
}

interface State {
    account: any;
    open: boolean;
    companies: any[];
    accounts: any[];
    customershipGroups: any[];
    enterpriseGroups: any[];
    employees: any[];
    accountTypes: any[];
    tags: any[];
    accountName: string;
    customFields: any[];
    customFieldsErrors: any;
    saving: boolean;
}

class AddAccountSlider extends TaimerComponent<Props, State> {
    constructor(props, context) {
        super(props, context, 'general/AddAccountSlider');

        this.state = {
            accountName: '',
            account: {
                id: '-1',
                ...this.props.initialSelectionProps,
                companies_id: this.props.initialSelectionProps?.companies_id || this.context.userObject.companies_id,
            },
            companies: [],
            accounts: [],
            customershipGroups: [],
            enterpriseGroups: [],
            employees: [],
            customFields: [],
            accountTypes: [],
            tags: [],
            open: false,
            customFieldsErrors: {},
            saving: false,
        };
    }

    checkCustomFieldValidity = (fieldKey) => {
        const customFieldsErrors = this.getCustomFieldError(fieldKey);
        this.setState({ customFieldsErrors });
        return Object.values(customFieldsErrors).indexOf(true) == -1;
    };

    showCreateSubUnitNotification = (customerId, customerName, companyId) => {
        const { enqueueSnackbar, closeSnackbar } = this.props;

        const snackbarId = enqueueSnackbar(null, {
            persist: true,
            preventDuplicate: true,
            content: (key) => {
                return (
                    <CreateSubUnitNotification
                        //@ts-ignore
                        onCreateClick={() => {
                            closeSnackbar(snackbarId);
                            this.context.functions.addAccount({
                                main_unit: customerId,
                                companies_id: companyId,
                                parentname: customerName,
                                origin_point: "slider_create_sub_unit_notification",
                            });
                        }}
                        onDismissClick={() => closeSnackbar(snackbarId)}
                    />
                );
            },
        });
    };

    onSave = (account) => {
        this.setState({ saving: true }, async () => {
            const { enqueueSnackbar, closeSnackbar } = this.props;
            const { addons } = this.context;
            const creatingSnackbar: any = enqueueSnackbar(this.tr('Creating account...'), {
                variant: 'info',
                persist: true,
            });
            const data = {
                ...account,
                name: account.name?.label || account.name,
                tags: (account.tags || []).map((t) => t.label),
            };
            delete data.companies_id;
            if (!this.props.onAccountCreated) this.onClose();
            try {
                const response = await DataHandler.post({ url: `accounts/company/${account.companies_id}` }, data);
                closeSnackbar(creatingSnackbar);
                const viewProps = this.context.functions.getViewProps();
                const isInAccountList =
                    (viewProps.module == 'contacts' && viewProps.action == 'main' && viewProps.selectedTab == 'accounts') || (viewProps.module == 'customers' && viewProps.action == 'view');
                const id = addons.custom_customer_id && addons.custom_customer_id.used_by_companies.indexOf(account.companies_id) > -1 ? response.customer_id : response.id;
                const createdAccount = {
                    ...data,
                    id: response.id,
                };
                this.context.functions.sendMixpanelEvent('create_account', {
                    'origin_point': this.props.initialSelectionProps?.origin_point,
                });
                this.context.functions.sendMixpanelPeople('set_once', {
                    'first_create_account_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('set', {
                    'last_create_account_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('increment', {
                    'lifetime_create_account': 1,
                });
                if (this.props.onAccountCreated) {
                    this.props.onAccountCreated(createdAccount);
                    this.onClose();
                    return;
                }
                const createdSnackbar: any = enqueueSnackbar(
                    <EntityCreatedSnackbar
                        type="account"
                        id={`'${data.name}'`}
                        onClose={() => closeSnackbar(createdSnackbar)}
                        actions={[
                            {
                                key: 'view_account',
                                onClick: () => {
                                    this.context.functions.updateView({ module: 'customers', action: 'view', id: response.id, company: account.companies_id });
                                },
                                isHidden: () => !this.context.functions.hasPrivilege('customers', 'read'),
                                label: 'View account',
                            },
                            {
                                key: 'project',
                                onClick: () => {
                                    this.context.functions.addProject({
                                        customers_id: createdAccount.id,
                                        companies_id: createdAccount.companies_id,
                                        origin_point: "add_account_slider",
                                    });
                                },
                                isHidden: () => !this.context.functions.hasPrivilege('projects', 'write'),
                                label: 'Add project',
                            },
                            {
                                key: 'activity',
                                onClick: () => {
                                    this.context.functions.openActivitySlider({
                                        customers_id: createdAccount.id,
                                    });
                                },
                                isHidden: () => !this.context.userObject.hasCrmWritePermission,
                                label: 'Add activity',
                            },
                            {
                                key: 'contact',
                                onClick: () => {
                                    this.context.functions.addContact({
                                        customers_id: createdAccount.id,
                                    });
                                },
                                isHidden: () => !this.context.functions.hasPrivilege('persons', 'read'),
                                label: 'Add contact',
                            },
                            {
                                key: 'sub_unit',
                                onClick: () => {
                                    this.context.functions.addAccount({ main_unit: createdAccount.id, parentname: createdAccount.name, origin_point: "slider_add_sub_unit" });
                                },
                                isHidden: () => !this.context.functions.hasPrivilege('customers', 'unit_write'),
                                label: 'Add sub-unit',
                            },
                        ]}
                    />,
                    {
                        variant: 'default',
                        autoHideDuration: 5000,
                        className: 'entityCreatedSnackbar',
                    }
                );
                if (this.context.functions.hasPrivilege('customers', 'unit_write') && (!account.hasOwnProperty('main_unit') || parseInt(account.main_unit) === 0) && this.context.addons?.mediapro?.used_by_companies.indexOf(account.companies_id) > -1) {
                    this.showCreateSubUnitNotification(response.id, data.name, account.companies_id);
                }
                if (isInAccountList) {
                    this.context.functions.updateView({ module: 'customers', action: 'view', id: response.id, company: account.companies_id });
                }
            } catch (e) {
                console.error(e);
                const errorResponse: any = e;
                let errorDescription = this.tr('Creating account failed!');
                switch (errorResponse.responseJSON?.error) {
                    case 'EXISTING_ACCOUNT_ID':
                        errorDescription = ` ${this.tr('The given account number already exists. Please choose another account number.')}.`;
                        break;
                    default:
                        break;
                }
                this.setState({ saving: false });
                closeSnackbar(creatingSnackbar);
                enqueueSnackbar(errorDescription, {
                    variant: 'error',
                });
            }
        });
    };

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    componentDidUpdate = (_, oldState) => {
        if (oldState.open != this.state.open && this.state.open) {
            this.getCompanies();
            this.getAccounts();
            this.getAccountTypes();
            this.getCustomershipGroups();
            this.getEnterpriseGroups();
            this.getEmployees();
            this.getCustomFields();
            this.getTags();
        }
        if (oldState.account.companies_id != this.state.account.companies_id) {
            this.getCustomershipGroups();
            this.getEnterpriseGroups();
            this.getEmployees();
            this.getCustomFields();
            this.getTags();
        }
    };

    getAccounts = async () => {
        const accounts = await DataHandler.get({ url: `subjects/account_entities/vatid+customer_company+parent_id+customer_id` });
        this.setState({ accounts });
    };

    getAccountTypes = async () => {
        if (this.state.account.types == 'sub_contractor') return;
        const accountTypes = await DataHandler.get({ url: `subjects/account_types` });
        const defaultType = accountTypes.find((a) => a.is_default == 1);
        const account = cloneDeep(this.state.account);
        if (defaultType) {
            const types = [...(account.types || [])];
            types.push({ ...defaultType, value: defaultType.id, isFixed: true });
            account.types = types;
        }
        this.setState({ accountTypes: accountTypes.map((a) => ({ ...a, value: a.id })), account });
    };

    getTags = async () => {
        const { account } = this.state;
        const { tags } = await DataHandler.get({ url: `tags/${account.companies_id}`, type: 'account' });
        this.setState({ tags: tags.map((t) => ({ label: t.name, value: t.name })) });
    };

    getEnterpriseGroups = async () => {
        const { account } = this.state;
        const enterpriseGroups = await DataHandler.get({ url: `subjects/enterprise_groups/${account.companies_id}` });
        this.setState({ enterpriseGroups });
    };

    getCompanies = async () => {
        const companies = await DataHandler.get({ url: `subjects/companies/customers/${this.state.account?.main_unit ? 'unit_write' : 'write'}` });
        const companies_id = (companies.find((c) => c.id == this.state.account.companies_id) || companies[0])?.id;
        this.setState({
            account: {
                ...this.state.account,
                companies_id,
            },
            companies: companies.map((c) => ({ ...c, value: c.id, label: c.name })),
        });
    };

    getCustomershipGroups = async () => {
        const { account } = this.state;
        const customershipGroups = await DataHandler.get({ url: `subjects/customership_groups/${account.companies_id}` });
        this.setState({ customershipGroups: customershipGroups.filter((c) => c.deleted != 1) });
    };

    getEmployees = async () => {
        const { account } = this.state;
        const responses = await Promise.all([
            DataHandler.get({ url: `subjects/employees/${account.companies_id}`, dontIgnoreCompany: true, frTransl: this.tr('Freelancer') }),
            DataHandler.get({ url: `subjects/employees/${account.companies_id}`, dontIgnoreCompany: true, frTransl: this.tr('Freelancer'), locked: '1' }),
        ]);
        const activeEmployees = responses[0] || [];
        const lockedEmployees = responses[1] || [];
        const employees = [
            { id: '0', users_id: '0', name: this.tr('No account manager'), label: this.tr('No account manager'), deleted: '0', noAccountManager: true },
            ...lockedEmployees.map((u) => ({ ...u, name: `${u.name} (${this.tr('locked')})`, label: `${u.name} (${this.tr('locked')})` })),
            ...activeEmployees,
        ];
        this.setState({ employees });
    };

    getCustomFields = async () => {
        const { account } = this.state;
        const customFields = await DataHandler.get({ url: `settings/company/${account.companies_id}/account/customfields`, include_deleted: 0 });
        this.setState({ customFields });
    };

    loadAsiakastietoOptions = async (name) => {
        const { account } = this.state;
        this.setState({ accountName: name });
        let options: any = [];
        if (name.length > 2) {
            if (this.context.addons.use_estonian_business_register?.used_by_companies.includes(account.companies_id)) {
                options = await DataHandler.get({ url: '/accounts/ariregister', search: name });
            } else {
                options = await DataHandler.get({ url: '/accounts/asiakastieto', search: name });
            }
            options = uniqBy(options, 'vatid');
        }
        if (name.length > 0) {
            options.push({ id: 0, label: `${this.tr('Add account with name: ${name}', { name })}`, name, icon: AddCircleOutlined, useParagraph: true, 'data-testid': 'add-account-option' });
        }
        return {
            options,
            hasMore: false,
        };
    };

    getFields = () => {
        const {
            addons,
            taimerAccount: { isMulticompany, countryCode },
        } = this.context;
        const { account, accountName, companies, customershipGroups, enterpriseGroups, employees, accountTypes, tags, customFieldsErrors } = this.state;
        const useAsiakastieto = countryCode == 'FI';
        const useEstonianBusinessRegister = this.context.addons.use_estonian_business_register?.used_by_companies.includes(account.companies_id);
        const fields: EditableField[] = [
            {
                key: 'companies_id',
                title: this.tr('Company'),
                type: 'data_select',
                options: companies,
                isHidden: () => !isMulticompany,
                required: true,
            },
            ...(account.parent_company || account.main_unit
                ? [
                      {
                          key: 'parentname',
                          title: account.parent_company ? this.tr('Parent company') : this.tr('Main unit'),
                          disabled: true,
                      },
                  ]
                : []),
            {
                key: 'name',
                required: true,
                title: this.tr('Name'),
                type: useAsiakastieto ? 'select' : undefined,
                allowValueOutsideOptions: true,
                debounceTimeout: !accountName ? undefined : 800,
                loadOptions: useAsiakastieto ? this.loadAsiakastietoOptions : undefined,
                noOptionsMessage: ({ inputValue }) => {
                    return null;
                },
                afterEdit: () => {
                    if (this.state.customFieldsErrors['customer_name_exists']) {
                        this.setState({
                            customFieldsErrors: {
                                ...this.state.customFieldsErrors,
                                customer_name_exists: false,
                            },
                        });
                    }
                },
                error: customFieldsErrors['customer_name_exists'],
                errorMessage: customFieldsErrors['customer_name_exists']
                    ? () => {
                          return (
                              <>
                                  <p>
                                      {this.tr('An account with the given name already exists.')}
                                      <Link url={{ module: 'customers', action: 'view', id: customFieldsErrors['customer_name_exists'] }} openInNewTab>
                                          {' '}
                                          {this.tr('View account')}
                                      </Link>
                                  </p>
                              </>
                          );
                      }
                    : undefined,
                cacheUniq: this.state.accountName,
                setOtherValuesWithSelection: (item, value) => {
                    if (useEstonianBusinessRegister) {
                        return { name: { label: value.name }, vatid: value.vatid, source: value.source };
                    }                
                    if (useAsiakastieto) {
                        if (value.id == 0) {
                            return { name: { label: value.name } };
                        }

                        let vatid = value.vatid || '';

                        if (vatid) {
                            const split = vatid.match(/(\d{7})(\d)/);
                            if (split)
                                vatid = split[1] + "-" + split[2];
                        }

                        return { name: { label: value.name }, vatid: vatid };
                    }
                    return {};
                },
            },
            {
                key: 'vatid',
                title: this.tr('Business ID'),
                isHidden: () => this.state.account?.main_unit,
                afterEdit: () => {
                    if (this.state.customFieldsErrors['customer_vatid_exists']) {
                        this.setState({
                            customFieldsErrors: {
                                ...this.state.customFieldsErrors,
                                customer_vatid_exists: false,
                            },
                        });
                    }
                },
                error: customFieldsErrors['customer_vatid_exists'],
                errorMessage: customFieldsErrors['customer_vatid_exists']
                    ? () => {
                          return (
                              <>
                                  <p>
                                      {this.tr('An account with the given business id already exists.')}
                                      <Link url={{ module: 'customers', action: 'view', id: customFieldsErrors['customer_vatid_exists'] }} openInNewTab>
                                          {' '}
                                          {this.tr('View account')}
                                      </Link>
                                  </p>
                              </>
                          );
                      }
                    : undefined,
            },
            {
                key: 'telephone',
                title: this.tr('Company phone'),
                validation: 'phone',
            },
            {
                key: 'email',
                title: this.tr('Company email'),
                validation: 'email',
            },
            {
                key: 'www',
                title: this.tr('Company website'),
                validation: 'link',
            },
            {
                key: 'customer_id',
                title: this.tr('Customer number'),
                required: addons.custom_customer_id && addons.custom_customer_id.used_by_companies.indexOf(account.companies_id) > -1,
                isHidden: (item) => !(addons.custom_customer_id && addons.custom_customer_id.used_by_companies.indexOf(item.companies_id) > -1),
                error: customFieldsErrors['customer_id_exists'],
                errorMessage: customFieldsErrors['customer_id_exists']
                    ? () => {
                          return (
                              <>
                                  <p>
                                      {this.tr('The given customer number already exists.')}
                                      <Link url={{ module: 'customers', action: 'view', id: this.state.customFieldsErrors['customer_id_exists'] }} openInNewTab>
                                          {' '}
                                          {this.tr('View account')}
                                      </Link>
                                  </p>
                              </>
                          );
                      }
                    : undefined,
                afterEdit: () => {
                    if (this.state.customFieldsErrors['customer_id_exists']) {
                        this.setState({
                            customFieldsErrors: {
                                ...this.state.customFieldsErrors,
                                customer_id_exists: false,
                            },
                        });
                    }
                },
            },
            {
                key: 'branchofbusiness',
                title: this.tr('Branch of business'),
            },
            {
                key: 'customership_groups_id',
                title: this.tr('Account Group'),
                type: 'data_select',
                options: customershipGroups,
                required: this.context.taimerAccount.forceCustomerShipGroup,
                hideIfEmpty: !this.context.taimerAccount.forceCustomerShipGroup,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'accountGroups')
            },
            {
                key: 'enterprise_groups_id',
                title: this.tr('Enterprise Group'),
                type: 'data_select',
                options: enterpriseGroups,
                hideIfEmpty: true,
                isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'enterpriseGroups')
            },
            ...(account.types != 'sub_contractor'
                ? [
                      {
                          key: 'types',
                          title: this.tr('Account type'),
                          type: 'select',
                          isMulti: true,
                          options: accountTypes,
                          required: true,
                          isHidden: () => VersionContentManager.isFeatureHidden(this.namespace, 'accountTypes')
                      },
                  ]
                : []),
            {
                key: 'account_manager',
                title: this.tr('Account manager'),
                type: 'data_select',
                options: employees.filter((e) => [account.companies_id, '0'].includes(e.companies_id) || e.noAccountManager || e.id == account.account_manager),
            },
            {
                key: 'account_manager2',
                title: this.tr('Account responsible'),
                type: 'data_select',
                options: employees.filter((e) => [account.companies_id, '0'].includes(e.companies_id) || e.noAccountManager || e.id == account.account_manager),
                isHidden: () => !isMulticompany,
            },
            {
                key: 'tags',
                title: this.tr('Tags'),
                type: 'select',
                isMulti: true,
                options: tags,
                noOptions: AddTag,
                additionalProps: {
                    company: account.companies_id,
                    tagType: 1,
                },
            },
        ];

        return fields;
    };

    onCustomFieldEdited = (name, value) => {
        const { account } = this.state;
        if (name.startsWith('custom_')) name = name.substring(7);
        this.setState(
            {
                account: {
                    ...account,
                    custom: {
                        ...account.custom,
                        [name]: value,
                    },
                },
            },
            () => {
                this.checkCustomFieldValidity(name);
            }
        );
    };

    checkCustomFieldsValidity = () => {
        const { addons } = this.context;
        const { 
            companies_id, 
            main_unit 
        } = this.state.account;

        const customFieldsErrors = {};
        const custom = this.state.account.custom;
        _.forEach(this.state.customFields, (v) => {
            const value = (custom && custom[v.id]) || '';
            if (v.required && v.show_in_details && value === '') {
                customFieldsErrors[v.id] = true;
            }
        });
        const name = this.state.account.name?.label || this.state.account.name || '';
        const duplicateNameId = this.state.accounts.find((a) => a.name.toLowerCase() == name.toLowerCase() && a.name != this.state.account?.parentname)?.id;
        if (duplicateNameId && !(addons?.mediapro?.used_by_companies?.indexOf(companies_id) > -1 && main_unit)) {
            customFieldsErrors['customer_name_exists'] = duplicateNameId;
            customFieldsErrors['customer_name_exists_value'] = true;
        }
        if (this.state.account.customer_id && this.context.addons.custom_customer_id && this.context.addons.custom_customer_id.used_by_companies.indexOf(this.state.account.companies_id) > -1) {
            const duplicateId = this.state.accounts.find((a) => a.customer_id == this.state.account.customer_id)?.id;
            if (duplicateId) {
                customFieldsErrors['customer_id_exists'] = duplicateId;
                customFieldsErrors['customer_id_exists_value'] = true;
            }
        }

        const { accounts, account } = this.state;

        if (account.vatid) {
            const vatid = account.vatid.replace(/[^0-9]/g, "");

            const vatIdMatch = accounts.find(ee => (vatid && ee.vatid.replace(/[^0-9]/g, "") === vatid) || (ee.vatid === account.vatid));

            if (vatIdMatch) {
                customFieldsErrors['customer_vatid_exists'] = vatIdMatch.id;
                customFieldsErrors['customer_vatid_exists_value'] = true;
            }
        }

        this.setState({ customFieldsErrors });
        return Object.values(customFieldsErrors).indexOf(true) == -1;
    };

    getCustomFieldError = (fieldKey) => {
        const customFieldsErrors = cloneDeep(this.state.customFieldsErrors);
        const custom = this.state.account.custom;
        const field = this.state.customFields.find((c) => c.id == fieldKey);
        if (!field) {
            if (fieldKey == 'customer_name_exists') {
                const name = this.state.account.name?.label || this.state.account.name || '';
                const duplicateNameId = this.state.accounts.find((a) => a.name.toLowerCase() == name.toLowerCase())?.id;
                customFieldsErrors['customer_name_exists'] = duplicateNameId;
            } else if (fieldKey == 'customer_id_exists') {
                if (
                    this.state.account.customer_id &&
                    this.context.addons.custom_customer_id &&
                    this.context.addons.custom_customer_id.used_by_companies.indexOf(this.state.account.companies_id) > -1
                ) {
                    const duplicateId = this.state.accounts.find((a) => a.customer_id == this.state.account.customer_id)?.id;
                    customFieldsErrors['customer_id_exists'] = duplicateId;
                } else {
                    customFieldsErrors['customer_id_exists'] = false;
                }
            }
            return customFieldsErrors;
        }
        const value = (custom && custom[field.id]) || '';
        customFieldsErrors[field.id] = field.required && field.show_in_details && value === '';
        return customFieldsErrors;
    };

    onItemChanged = (account) => this.setState({ account });

    render() {
        const { account, open, customFields, customFieldsErrors, saving } = this.state;
        const { ref, ...rest } = this.props;
        return (
            <FieldEditSlider
                {...rest}
                open={open}
                onClose={this.onClose}
                title={account.main_unit ? this.tr('Add subunit') : account.parent_company ? this.tr('Add subsidiary') : this.tr('Add account')}
                onSave={this.onSave}
                saving={saving}
                onItemChanged={this.onItemChanged}
                item={account}
                fields={this.getFields()}
                additionalValidations={this.checkCustomFieldsValidity}
                additionalFields={<CustomFields fields={customFields} form={0} values={account.custom || {}} onChange={this.onCustomFieldEdited} hideOptional errors={customFieldsErrors} />}
            />
        );
    }
}

export default withSnackbar(AddAccountSlider);
