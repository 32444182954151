import React from 'react';

//css

//material-ui
import AddIcon from '@mui/icons-material/AddCircleOutline';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import { UnfoldMore, MoreHoriz as ContextMenuIcon, SyncAlt } from '@mui/icons-material';

//others
import ListRow from "../list/ListRow";
import ListCell from "../list/ListCell";
import FocusGroup from "../general/FocusGroup";
import classNames from 'classnames';
import ContextMenu from '../general/ContextMenu';
import TextInputCell from './TextInputCell';
import TextAreaCell from "../list/cells/TextAreaCell";
import TaimerComponent from "../TaimerComponent";
import AutoCompleteCell from "../list/cells/AutoCompleteCell";
import MultilineAutocompleteCell from "../list/cells/MultilineAutocompleteCell";
import InfoIconCell from '../list/cells/InfoIconCell';

import InfoSelect from "../list/cells/InfoSelect";
import { SettingsContext } from './../SettingsContext';
import { formatInputNumber } from '../helpers';

import { ReactComponent as RemoveIcon } from '../general/icons/remove.svg';
import { ReactComponent as DescriptionIcon } from '../general/icons/Description.svg';
import VersionContentManager from '../general/VersionContentManager';
import { cloneDeep } from 'lodash';

const styles = theme => ({
    headerRow: {
        display: "flex", 
        lineHeight: "26px",
        minHeight: "26px", 
        width: "100%"
    }
});

const localeStringObject = {minimumFractionDigits: 2, maximumFractionDigits: 2};

class HeaderRow extends ListRow {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "invoices/InvoiceRow");

        this.context = context;

    }

     // NOTE: overrides ListRow's shouldComponentUpdate, 
    // which used to return true. This broke invoicing 
    // rows, not letting them rerender.
    // The fact that it did suggests that either the state
    // or props of these components is edited directly
    // through a reference.
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    defineClassName() {
        return `${classNames(this.props.classes.headerRow)} invoice-row invoice-header`;
    }

    defineCells() {

        const { data, columnWidthMap, columnOrder, 
            rowProps: {editMode, type, billLanguage, itemDescriptionLimit, onOverLimit, overLimitRows, descriptionErrorRows}, editRow, addDescriptionRow, deleteRow } = this.props;

        const cellWidths = {
            context: columnWidthMap.context,
            move: columnWidthMap.move || 0,
            rest: Object.values(columnWidthMap).reduce((a, b) => a + b) - columnWidthMap.context - (columnWidthMap.move || 0) - (columnWidthMap.accounting || 0)
        }

        const contextWidth = {width: `${cellWidths.context}px`, flex: `${cellWidths.context} 1 0px`};

        const refundTranslations = {
            fi: "Hyvityslasku laskulle no.",
            en: "Refund invoice for bill no.",
            se: "Kreditnota för fakturanr.",
            ee: "Kreeditarve arvele nr."
        }
        const reminderTranslations = {
            fi: "Maksumuistutus laskulle no.",
            en: "Payment reminder to invoice nr.",
            se: "Betalningspåminnelse till fakturanr.",
            ee: "Meeldetuletus tasumata arvele nr."
        }

        let cells = {};

        columnOrder.forEach(colName => {
            cells[colName] = <div overrideWidth={0} />;
        });

        let description = data.description;
        const isTranslateableBill = type == "2" || type == "3";
        let isRefundHeader = false;
        let isReminderHeader = false;
        Object.keys(refundTranslations).forEach(refundLang => {
            const regex = new RegExp( "^" + refundTranslations[refundLang] + "\\s\\d+$");
            if (description && description.match(regex)) {
                isRefundHeader = isTranslateableBill;
            }
        });
        Object.keys(reminderTranslations).forEach(reminderLang => {
            const regex = new RegExp( "^" + reminderTranslations[reminderLang] + "\\s\\d+$");
            if (description && description.match(regex)) {
                isReminderHeader = isTranslateableBill;
            }
        });
        
        if (isRefundHeader || isReminderHeader) {
            const splittedDescription = data.description.split(" ");
            const invoiceNumber = splittedDescription ? splittedDescription[splittedDescription.length - 1] : false;
            if (Number(invoiceNumber)) {
                const text = isRefundHeader ? refundTranslations[billLanguage] : reminderTranslations[billLanguage];
                if (text)
                    description = text + " " + invoiceNumber;
            }
        }

        cells = {
            ...cells,
            context: (
                editMode ? <ListCell onlyDisplay={true} editable={false} overrideWidth={cellWidths.context}>
                    <ContextMenu
                    className="invoice-row-menu row-menu" 
                    label={<ContextMenuIcon />} 
                    buttonProps={{ className: 'action-menu' }} 
                    // style={contextWidth} 
                    noExpandIcon >

                    <MenuItem onClick={() => addDescriptionRow()}><DescriptionIcon />{this.tr('Add description row')}</MenuItem>
                    <MenuItem  className="delete" onClick={() => deleteRow('rows')}><RemoveIcon className="Delete"/> {this.tr('Delete')}</MenuItem>
                </ContextMenu> 
                </ListCell>
                    : 
                <ListCell editable={false} onlyDisplay={true} width={cellWidths.context} />
            ),
            move: (
                editMode ? (
                <ListCell onlyDisplay={true} editable={false} overrideWidth={cellWidths.move}>
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <UnfoldMore />
                        </div>
                </ListCell> ) : <ListCell editable={false} onlyDisplay={true} overrideWidth={0} />
            ),
            description: (
                <TextAreaCell 
                    limit={itemDescriptionLimit}
                    onOverLimit={onOverLimit}
                    className="cell" 
                    overrideWidth={cellWidths.rest} 
                    name="description" 
                    placeholder={this.tr("New header")}
                    value={description}
                    initInEditMode={editMode}
                    onEdited={(name, value) => editRow(name, value)}
                    listCellProps={{
                        inEditMode: editMode,
                        showErrorBorder: editMode && (overLimitRows.includes(data.id) || descriptionErrorRows.includes(data.id)),
                        showWarningBorder: editMode && !overLimitRows.includes(data.id) && itemDescriptionLimit && data.description.length > itemDescriptionLimit 
                    }} 
                    editable={editMode}/>
            ),
            accounting: <ListCell editable={false} onlyDisplay={true} width={columnWidthMap.accounting} />
        }

        return cells;
    }
}

class RowAddButtons extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/InvoiceRow");
        this.context = context;
    }

    render() {

        const { rowId, addRow, addProductRow, addCPQRow, addHeaderRow } = this.props;
        const { versionId } = this.context;

        return (
            <React.Fragment>
                <div data-testid="invoice_add_row_button" className="action-button" onClick={() => addRow(rowId)}>
                    <span className="add-icon"><AddIcon/></span>
                    {this.tr('Row')}
                </div>
                <div data-testid="invoice_add_product_row_button" className="action-button" onClick={() => addProductRow(rowId)}>
                    <span className="add-icon"><AddIcon/></span>
                    {this.tr('Product')}
                </div>
                {!VersionContentManager.isFeatureHidden(this.namespace, 'cpq') && <div data-testid="invoice_add_cpq_row_button" className="action-button" onClick={() => addCPQRow(rowId)}>
                    <span className="add-icon"><AddIcon/></span>
                    {this.tr('CPQ')}
                </div>}
                <div  data-testid="invoice_add_header_row_button" className="action-button" onClick={() => addHeaderRow()}>
                    <span className="add-icon"><AddIcon/></span>
                    {this.tr('Header')}
                </div>
            </React.Fragment>
        );
    }
}

class TotalsRow extends ListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "invoices/InvoiceRow");
        this.list = React.createRef();
    }

    // NOTE: overrides ListRow's shouldComponentUpdate, 
    // which used to return true. This broke invoicing 
    // rows, not letting them rerender.
    // The fact that it did suggests that either the state
    // or props of these components is edited directly
    // through a reference.
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    defineClassName = () => {
        return "invoice-row totals-row";
    }

    defineCells = () => {

        const { data, columnWidthMap, rowProps: {
                editMode, 
                addRow, 
                addHeaderRow, 
                addProductRow, 
                addCPQRow, 
                countSectionTotals, 
                currency, 
                symbol, 
                currency_rate,
                selectedCurrency
            } } = this.props;
        const { taimerAccount } = this.context;

        const commonProps = {
            editable: false
        };

        const formatSum = (value) => {
            return `${Number(value)?.toFixed(2).replace('.', ',').toLocaleString(undefined, localeStringObject)} ${symbol}`;
        };      

        const containerWidth = editMode ? 290 : 275;

        const buttonContainerWidth = {
            width: `${containerWidth}px`, 
            minWidth: `${containerWidth}px`, 
            flexGrow: containerWidth,
            flex: (containerWidth) + " 1 0",
            visibility: editMode ? "visible" : "hidden"
        };

        const noVatTotal = countSectionTotals(data.id, 'total_no_vat', true), total = countSectionTotals(data.id, 'total', true);
        const cells = {
            context: 
                <div className="action-cell" style={buttonContainerWidth} >
                    <RowAddButtons
                        rowId={data.id}
                        addRow={addRow}
                        addProductRow={addProductRow}
                        addCPQRow={addCPQRow}
                        addHeaderRow={addHeaderRow} />
                </div>,
            total_no_vat: 
                <TextInputCell
                    {...commonProps}
                    textAlign="right"
                    name="total_no_vat"
                    value={`${ formatSum(noVatTotal) }`} />,
            vat: 
                <ListCell 
                    {...commonProps}
                    name="vat"
                    value={false} />,
            total: 
                <TextInputCell 
                    {...commonProps}
                    textAlign="right"
                    name="total"
                    value={`${ formatSum(total) }`} />,
            netvisor_account: 
                <ListCell 
                    textAlign="right" 
                    value={false} />,
            accounting:
                <ListCell
                    className="totals-row-empty-space"
                    onlyDisplay={true}
                    textAlign="right"
                    value={false} />
        };

        return cells;
    }

    render = () => {

        const { data, columnWidthMap, columnOrder, columnConfig } = this.props;
        const { taimerAccount } = this.context;

        const cells = this.defineCells();

        const StyledHeaderRow = withStyles(styles)(HeaderRow);

        const className = [this.defineClassName(), "listElement row", this.props.hidden ? "hidden" : ""].join(" ");

        return (
            <div className={className}>
                <FocusGroup
                    focusOnInit={false} 
                    columnOrder={columnOrder} >
                    {columnOrder.filter(co => cells[co]).map(columnName => {
                        
                        const cell          = cells[columnName];
                        const listCellProps = cell.props.listCellProps;

                        return React.cloneElement(cell, {
                            width: this.props.columnConfig[columnName].width,
                            listCellProps: {
                                ...listCellProps, 
                                inEditMode: data.id < 0,
                                className: `${listCellProps ? listCellProps.className : ""}`
                            }
                        });
                    })}
                </FocusGroup>
            </div>
        )
    }
}

class ItemRow extends ListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "invoices/InvoiceRow");

    }

    // NOTE: overrides ListRow's shouldComponentUpdate, 
    // which used to return true. This broke invoicing 
    // rows, not letting them rerender.
    // The fact that it did suggests that either the state
    // or props of these components is edited directly
    // through a reference.
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    defineClassName() {
        return "invoice-row";
    }

    defineCells() {

        const { columnWidthMap, columnConfig, data, 
            rowProps: {editMode, currency, symbol, itemDescriptionLimit, onOverLimit, overLimitRows, renderRowAccountingCell, descriptionErrorRows, currency_rate}, editRow, addDescriptionRow, deleteRow, renderVatCell } = this.props;

        const hasOldCurrencyConvert = this.props.rowProps.hasOldCurrencyConvert > 0;

        const { taimerAccount } = this.context;

        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(value);
        };

        const commonProps = {
            editable: editMode,
            textAlign: "right",
            onEdited: editRow,
            onEnter: false,
            runOnEditedOnInput: false,
            listCellProps: {
                editable: editMode,
                inEditMode: editMode
            }         
        };

        const contextWidth = {width: columnWidthMap['context'] + 'px', flex: columnWidthMap['context'] + " 1 0px" };

        const cellEdit = (name, value, listCellProps) => {
            if (value) value = value.toString().replace(',', '.').replace(' ', '');
    
            if (name == 'value' || name == 'total') { // values should go to editRow in company's currency.
                value = hasOldCurrencyConvert ? parseFloat(value) * currency_rate : parseFloat(value) / currency_rate;
            } 

            editRow(name, value, data.id);
        }

        const priceFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        }).format;

        const cells = {
            context: 
            (editMode ? 
            <ListCell onlyDisplay={true} editable={false}>
                <ContextMenu className="invoice-row-menu row-menu" label={<ContextMenuIcon /*style={{ color: "#b0b3ba" }}*/ />} key={data.id*100} buttonProps={{ className: 'action-menu' }} /*style={contextWidth}*/ noExpandIcon>
                    <MenuItem onClick={() => addDescriptionRow()}><DescriptionIcon />{this.tr('Add description row')}</MenuItem>
                    <MenuItem  className="delete" onClick={() => deleteRow('rows')}> <RemoveIcon className="Delete"/> {this.tr('Delete')}</MenuItem>
                </ContextMenu>
            </ListCell> 
            : 
            <ListCell editable={false} onlyDisplay={true} width={columnWidthMap.context} />
            ),
            move: editMode ?
                <ListCell
                    onlyDisplay={true} editable={false} width={columnWidthMap.move}
                    >
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <UnfoldMore />
                        </div>
                </ListCell> : <ListCell editable={false} onlyDisplay={true} overrideWidth={0} />,
            product: 
                <div style={{width: '0px'}}></div>
            ,
            description:
                <TextAreaCell
                    {...commonProps}
                    overrideWidth={columnWidthMap.description + columnWidthMap.product}
                    textAlign="left"
                    name="description"
                    limit={itemDescriptionLimit}
                    onOverLimit={onOverLimit}
                    placeholder={this.tr("Item")}
                    value={data.description}
                    listCellProps={{
                        ...commonProps.listCellProps, 
                        showTooltipForOverflownText: true,
                        showErrorBorder: editMode && (overLimitRows.includes(data.id) || descriptionErrorRows.includes(data.id)),
                        showWarningBorder: editMode && !overLimitRows.includes(data.id) && itemDescriptionLimit && data.description?.length > itemDescriptionLimit
                    }} />,
            quantity:
                <TextInputCell
                    {...commonProps}
                    name="quantity"
                    onEdited={cellEdit}
                    value={formatInputNumber(data.quantity)}/>,
            value: 
                <TextInputCell
                    {...commonProps}
                    name="value"
                    onEdited={cellEdit}
                    value={priceFormatter(data.currency_value)?.replace('.', ',')?.replace('−', '-')}/>,
            total_no_vat: 
                <ListCell
                    {...commonProps}
                    data-testid={`total_no_vat_cell_${this.props.rowIndex}`}
                    name="total_no_vat"
                    editable={false}
                    value={`${(Number(data.currency_total_no_vat))?.toFixed(2).replace('.', ',').toLocaleString(undefined, localeStringObject)} ${symbol}`} />,
            vat: renderVatCell(cellEdit),
            total: 
                <TextInputCell 
                    {...commonProps}
                    name="total"
                    editable={true}
                    onEdited={cellEdit}
                    value={`${(Number(data.currency_total))?.toFixed(2).replace('.', ',').toLocaleString(undefined, localeStringObject)} ${symbol}`} />,
            netvisor_account: 
                <TextInputCell
                    {...commonProps}
                    textAlign="left" 
                    value={data.netvisor_account} />,
            accounting: renderRowAccountingCell 
                ? renderRowAccountingCell(data, {width: this.props.columnWidthMap.accounting, 'data-testid': "accounting_" + this.props.rowIndex}) 
                : <ListCell editable={false} onlyDisplay={true} width={this.props.columnWidthMap.accounting} />,
        };

        return cells;
    }
}

class ProductRow extends ListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "invoices/InvoiceRow");
        this.InfoSelecPlaceholder = this.tr("Product");
    }

    // NOTE: overrides ListRow's shouldComponentUpdate, 
    // which used to return true. This broke invoicing 
    // rows, not letting them rerender.
    // The fact that it did suggests that either the state
    // or props of these components is edited directly
    // through a reference.
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    defineClassName() {
        return "invoice-row";
    }

    defineCells() {

        const { columnWidthMap, columnConfig, data, 
            rowProps: {editMode, products,  currency, accountingProducts, productRowSettings, symbol, itemDescriptionLimit, onOverLimit, overLimitRows, renderRowAccountingCell, descriptionErrorRows}, editRow, addDescriptionRow, deleteRow, renderVatCell} = this.props;

        let {currency_rate} = this.props.rowProps;
        if (this.props.rowProps.hasOldCurrencyConvert > 0)
            currency_rate = 1;

        const { taimerAccount } = this.context;

        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(value);
        };

        const commonProps = {
            editable: editMode,
            textAlign: "right",
            onEdited: editRow,
            onEnter: false,
            runOnEditedOnInput: false,
            listCellProps: {
                editable: editMode,
                inEditMode: editMode
            }         
        };

        const contextWidth = {width: columnWidthMap['context'] + 'px', flex: columnWidthMap['context'] + " 1 0px" };

        const cellEdit = (name, value) => {
            if (value) value = value.replace(',', '.');

            if (name == 'value' || name == 'total')
                value = parseFloat(value) / currency_rate;  

            editRow(name, value, data.id);
        }

        const product = products && products.length > 0 && data.product_register_id && data.product_register_id > 0 ? products.find(p => p.id == data.product_register_id) : {}
        
        let productName = "";
        if(product) {
            product.label = product.name != "" ? product.name : "";
            productName = product.name;
        }
        else if (data.description) {
            productName = data.description.split(",")[1] || data.description;
        }

        const priceFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        }).format;

        const cells = {
            context: editMode ? (
                <ListCell editable={false} onlyDisplay={true}>
                <ContextMenu
                    className="invoice-row-menu row-menu"
                    label={<ContextMenuIcon /*style={{ color: "#b0b3ba" }}*/ />}
                    key={data.id * 100}
                    buttonProps={{ className: "action-menu" }}
                    // style={contextWidth}
                    noExpandIcon
                >
                    <MenuItem onClick={() => addDescriptionRow()}><DescriptionIcon />{this.tr("Add description row")}</MenuItem>
                    <MenuItem className="delete" onClick={() => deleteRow("rows")}><RemoveIcon className="Delete"/> {this.tr("Delete")}</MenuItem>
                </ContextMenu>
                </ListCell>
            ) : (
                <ListCell editable={false} onlyDisplay={true} />                
            ),
            move: editMode ?
            <ListCell
                onlyDisplay={true} editable={false} width={columnWidthMap.move}
                >
                    <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                        <UnfoldMore />
                    </div>
            </ListCell> : <ListCell editable={false} onlyDisplay={true} overrideWidth={0} />,
            description: (
                <TextInputCell
                    {...commonProps}
                    textAlign="left"
                    limit={itemDescriptionLimit}
                    onOverLimit={onOverLimit}
                    name="description"
                    placeholder={this.tr("Item")}
                    value={data.description}
                    listCellProps={{
                        showTooltipForOverflownText: true,
                        showErrorBorder: editMode && (overLimitRows.includes(data.id) || descriptionErrorRows.includes(data.id)),
                        showWarningBorder: editMode && !overLimitRows.includes(data.id) && itemDescriptionLimit && data.description.length > itemDescriptionLimit
                    }}
                />
            ),
            product: (
                editMode && data.from_quote_row == 1 ?
                    <InfoIconCell 
                        name={"product"}
                        tooltip={this.tr("This row includes information from quote row. Value cannot be edited.")}
                        textAlign={"left"}
                        value={productName}
                        width={columnWidthMap.product}
                        hideOverFlow={true}
                    />
                :
                <ListCell editable={false} onlyDisplay={true} hideOverflow={true} textAlign={"left"} innerStyle={{ textAlign: "left" }}>
                    <InfoSelect
                        height={230}
                        tableWidth={745}
                        headerHeight={35}
                        rowHeight={49}
                        placeholder={productName ? productName : this.InfoSelecPlaceholder}
                        className={"is_invoice_product"}
                        onChange={async (value) => {
                            const {
                                rowProps: { editRow },
                                data: { id },
                            } = this.props;
                            this.InfoSelecPlaceholder = value.name;
                            let description = value.name;

                            if(productRowSettings.show_product_code == "1" && productRowSettings.show_product_unit_in_bill_row == "1" && value.code.length > 0 && value.unit.length > 0) {
                                description = value.code + ", " + value.name + ", " + value.unit;
                            } else if(productRowSettings.show_product_code == "1" && value.code.length > 0) {
                                description = value.code + ", " + value.name;
                            } else if(productRowSettings.show_product_unit_in_bill_row == "1" && value.unit.length > 0) {
                                description = value.name + ", " + value.unit;
                            }

                            await editRow("description", description, id);
                            await editRow("value", (value.income_price * ((100 - value.discount_percent) / 100)).toFixed(2), id);
                            await editRow("product_register_id", value.id, id);
                            if (Number(value.accounting_vats_id)) {
                                await editRow("sales_vatcode", {id: value.accounting_vats_id, vatpercent: value.vat}, id);
                            }
                            else {
                                await editRow("vat", value.vat, id);
                            }

                            if (Number(value.accounting_accounts_id)) {
                                editRow("sales_account", value.accounting_accounts_id, id);
                            }
                            if (Number(value.accounting_dimensions_id)) {
                                editRow("dimension_item", value.accounting_dimensions_id, id);
                            }
                            if (
                                (value.integration_id > 0 &&
                                    accountingProducts.find((e) => e.value == value.integration_id)) ||
                                value.accounting_products_id > 0
                            ) {
                                editRow(
                                    "accounting_product",
                                    value.integration_id > 0
                                        ? accountingProducts.find((e) => e.value == value.integration_id).id
                                        : value.accounting_products_id > 0
                                            ? value.accounting_products_id
                                            : 0,
                                    id
                                );
                            }
                            // Edit dimension values to defaults (or empty) also when product has no dimension values.
                            editRow("product_dimension_values", value.dimension_values || [], id);
                        }}
                        options={products ? products.filter(e => Number(e.deleted) === 0) : undefined}
                        value={productName}
                        columns={[
                            { name: "code", header: this.tr("Code"), width: 75 },
                            { name: "name", header: this.tr("Name"), width: 350 },
                            { name: "path", header: this.tr("Category"), width: 245 },
                            { name: "unit", header: this.tr("Unit"), width: 75 },
                        ]}
                        editable={editMode}
                    />
                </ListCell>
            ),
            quantity: (
                editMode && data.from_quote_row == 1 ?
                    <InfoIconCell 
                        name={"quantity"}
                        tooltip={this.tr("This row includes information from quote row. Value cannot be edited.")}
                        textAlign={"right"}
                        value={formatInputNumber(data.quantity)}
                        width={columnWidthMap.quantity}
                        hideInfoIcon={true}
                    />
                : 
                    <TextInputCell
                        {...commonProps}
                        name="quantity"
                        onEdited={cellEdit}
                        value={formatInputNumber(data.quantity)}
                    />
            ),
            value: (
                <TextInputCell
                    {...commonProps}
                    name="value"
                    onEdited={cellEdit}
                    value={priceFormatter(data.currency_value)?.replace('.', ',')?.replace('−', '-')}
                />
            ),
            total_no_vat: (
                <ListCell 
                    {...commonProps} 
                    name="total_no_vat" 
                    editable={false} 
                    value={`${(Number(data.currency_total_no_vat))?.toFixed(2).replace('.', ',').toLocaleString(undefined, localeStringObject)} ${symbol}`} />
            ),
            vat: renderVatCell(cellEdit),
            total: <TextInputCell 
                {...commonProps} 
                name="total" 
                editable={true} 
                onEdited={cellEdit}
                value={`${(Number(data.currency_total))?.toFixed(2).replace('.', ',').toLocaleString(undefined, localeStringObject)} ${symbol}`} />,
            netvisor_account: <TextInputCell {...commonProps} textAlign="left" value={data.netvisor_account} />,
            accounting: renderRowAccountingCell 
                ? renderRowAccountingCell(data, {width: this.props.columnWidthMap.accounting, 'data-testid': "accounting_" + this.props.rowIndex}) 
                : <ListCell editable={false} onlyDisplay={true} width={this.props.columnWidthMap.accounting} />,        
        };

        return cells;
    }
}

class CPQRow extends ListRow {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, {}, {}, "invoices/InvoiceRow");

    }

    // NOTE: overrides ListRow's shouldComponentUpdate, 
    // which used to return true. This broke invoicing 
    // rows, not letting them rerender.
    // The fact that it did suggests that either the state
    // or props of these components is edited directly
    // through a reference.
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    defineClassName() {
        return "invoice-row";
    }

    defineCells() {

        const { columnWidthMap, columnConfig, data, 
            rowProps: { editMode, CPQParents, currency, symbol, itemDescriptionLimit, onOverLimit, overLimitRows, renderRowAccountingCell, descriptionErrorRows }, editCPQ, editRow, addDescriptionRow, deleteRow, renderVatCell } = this.props;

        let {currency_rate} = this.props.rowProps;
        if (this.props.rowProps.hasOldCurrencyConvert > 0)
            currency_rate = 1;

        const { taimerAccount } = this.context;

        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: currency}).format(value);
        };

        const commonProps = {
            editable: editMode,
            textAlign: "right",
            onEdited: editRow,
            onEnter: false,
            runOnEditedOnInput: false,
            listCellProps: {
                editable: editMode,
                inEditMode: editMode
            }         
        };

        const contextWidth = {width: columnWidthMap['context'] + 'px', flex: columnWidthMap['context'] + " 1 0px" };

        const cellEdit = (name, value) => {
            if (value) value = value.replace(',', '.');

            if (name == 'value' || name == 'total')
                value = parseFloat(value) / currency_rate;  
                
            editRow(name, value, data.id);
        }

        const priceFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            useGrouping: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        }).format;

        const cells = {
            context: 
                (editMode ? 
                <ListCell editable={false} onlyDisplay={true}>
                <ContextMenu className="invoice-row-menu row-menu" label={<ContextMenuIcon /*style={{ color: "#b0b3ba" }}*/ />} key={data.id*100} buttonProps={{ className: 'action-menu' }} /*style={contextWidth}*/ noExpandIcon>
                    <MenuItem onClick={() => addDescriptionRow()}><DescriptionIcon />{this.tr('Add description row')}</MenuItem>
                    <MenuItem className="delete" onClick={() => deleteRow('rows')}><RemoveIcon className="Delete"/> {this.tr('Delete')}</MenuItem>
                </ContextMenu> 
                </ListCell>
                : 
                <ListCell editable={false} />),
            move: editMode ?
                <ListCell
                    onlyDisplay={true} editable={false} width={columnWidthMap.move}
                    >
                        <div className={!editMode ? "transparent" : ""} style={{ cursor: "grab" }} onMouseDown={this.startDrag}>
                            <UnfoldMore />
                        </div>
                </ListCell> : <ListCell editable={false} onlyDisplay={true} overrideWidth={0} />,
            description:
                <TextInputCell
                    {...commonProps}
                    textAlign="left"
                    name="description"
                    placeholder={this.tr("Item")}
                    limit={itemDescriptionLimit}
                    onOverLimit={onOverLimit}
                    value={data.description} 
                    listCellProps={{
                        showTooltipForOverflownText: true,
                        showErrorBorder: editMode && (overLimitRows.includes(data.id) || descriptionErrorRows.includes(data.id)),
                        showWarningBorder: editMode && !overLimitRows.includes(data.id) && itemDescriptionLimit && data.description.length > itemDescriptionLimit                    
                    }}
                />,
            product: 
                editMode && data.from_quote_row == 1 ?
                    <InfoIconCell 
                        name={"product"}
                        tooltip={this.tr("This row includes information from quote row. Value cannot be edited.")}
                        textAlign={"left"}
                        value={data.cpq_id > 0 ? CPQParents.find(c => c.id == data.cpq_id)?.name || "" : ""}
                        width={columnWidthMap.product}
                        hideOverFlow={true}
                    />
                :
                <AutoCompleteCell
                    editable= {editMode}
                    textAlign= "right"
                    onEnter= {false}
                    runOnEditedOnInput= {true}
                    style={{height: "26px"}}
                    name="product"
                    allowCreate={false}
                    value={data.cpq_id > 0 ? data.cpq_id : data.product_register_id}
                    autoCompleteData={CPQParents}
                    searchable={true}
                    listCellProps={{inEditMode: editMode}}
                    onEdited={(value) => {
                        editCPQ(value);
                    }} />,
            quantity:
                editMode && data.from_quote_row == 1 ?
                    <InfoIconCell 
                        name={"quantity"}
                        tooltip={this.tr("This row includes information from quote row. Value cannot be edited.")}
                        textAlign={"right"}
                        value={formatInputNumber(data.quantity)}
                        width={columnWidthMap.quantity}
                        hideInfoIcon={true}
                    />
                :
                <TextInputCell
                    {...commonProps}
                    name="quantity"
                    onEdited={cellEdit}
                    value={formatInputNumber(data.quantity)}/>,
            value: 
                <TextInputCell
                    {...commonProps}
                    name="value"
                    onEdited={cellEdit}
                    value={priceFormatter(data.currency_value)?.replace('.', ',')?.replace('−', '-')}/>,
            total_no_vat: 
                <ListCell
                    {...commonProps}
                    name="total_no_vat"
                    editable={false}
                    value={`${(Number(data.currency_total_no_vat))?.toFixed(2).replace('.', ',').toLocaleString(undefined, localeStringObject)} ${symbol}`} />,
            vat: renderVatCell(cellEdit),
            total: 
                <TextInputCell 
                    {...commonProps}
                    name="total"
                    editable={true}
                    onEdited={cellEdit}
                    value={`${(Number(data.currency_total))?.toFixed(2).replace('.', ',').toLocaleString(undefined, localeStringObject)} ${symbol}`} />,
            netvisor_account: 
                <TextInputCell
                    {...commonProps}
                    textAlign="left" 
                    value={data.netvisor_account} />,
            accounting: renderRowAccountingCell 
                ? renderRowAccountingCell(data, {width: this.props.columnWidthMap.accounting, 'data-testid': "accounting_" + this.props.rowIndex}) 
                : <ListCell editable={false} onlyDisplay={true} width={this.props.columnWidthMap.accounting} />,        
        };

        return cells;
    }
}


class DescriptionRow extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/InvoiceRow");

        this.context = context;

    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    render() {

        const { data, rowData, classes, columnWidthMap, columnConfig, rowProps: {editMode, descriptionRowLimit, onOverLimit, invalidDescriptionRows, overLimitDescriptionRows}, editRow, deleteRow } = this.props;

        const cellWidths = {
            context: columnWidthMap.context,
            rest: Object.values(columnWidthMap).reduce((a, b) => a + b) - columnWidthMap.context - (columnWidthMap.accounting || 0)
        }

        const contextWidth = {width: `${cellWidths.context}px`, flex: `${cellWidths.context} 1 0px`};

        return (
            <div className={`${classNames(classes.headerRow)} invoice-row description-row`} >
                {editMode ? 
                <ListCell width={cellWidths.context} onlyDisplay={true} editable={false}>
                    <ContextMenu 
                        className="invoice-row-menu row-menu"
                        label={<ContextMenuIcon />} 
                        buttonProps={{ className: 'action-menu' }} 
                        // style={contextWidth} 
                        noExpandIcon >
                        <MenuItem className="delete" onClick={() => deleteRow('descriptionRows', rowData.id)}><RemoveIcon className="Delete"/>{this.tr('Delete')}</MenuItem>
                    </ContextMenu> 
                </ListCell>
                : 
                <ListCell editable={false} onlyDisplay={true} width={cellWidths.context} />
                }
                <TextInputCell 
                    limit={descriptionRowLimit}
                    onOverLimit={onOverLimit}
                    className="cell" 
                    width={cellWidths.rest} 
                    name="description" 
                    placeholder={this.tr("New description")}
                    value={rowData.description}
                    initInEditMode={editMode}
                    onEdited={(name, value) => editRow(name, value, rowData.id)}
                    listCellProps={{
                        inEditMode: editMode,
                        showErrorBorder: editMode && (overLimitDescriptionRows.includes(rowData.id) || invalidDescriptionRows.includes(rowData.id)),
                        showWarningBorder: editMode && !overLimitDescriptionRows.includes(rowData.id) && descriptionRowLimit && rowData.description.length > descriptionRowLimit 
                    }} 
                    editable={editMode}/>
                    <ListCell editable={false} onlyDisplay={true} width={columnWidthMap.accounting} />
            </div>
        );
    }
}


class InvoiceRow extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/InvoiceRow");

        this.list = React.createRef();

        this.defaultDescriptionRow = {
            id: -1,
            billentries_id: 0,
            description: '',
            selected: 1
        }
    }

    defineClassName = () => {
        return "invoice-row";
    };

    setDescriptionRows = () => {
        const { data: {id, material_id}, rowProps: {descriptionRows} } = this.props;
        return descriptionRows.filter(dr => dr.billentries_id == id && !dr.deleted);
    };

    editRow = (name, value) => {
        const { rowProps: {editRow}, data: { id } } = this.props;
        editRow(name, value, id);
    };

    editCPQ = (value) => {
        const { rowProps: {editCPQ}, data: { id } } = this.props;
        editCPQ(value, id);
    };    

    editDescriptionRow = (name, value, descRowId) => {
        const { editDescriptionRow } = this.props.rowProps;
        editDescriptionRow(name, value, descRowId);
    };

    addDescriptionRow = () => {
        const { rowProps: {addDescriptionRow, }, data: { id } } = this.props;
        const newDescRow = { ...this.defaultDescriptionRow, billentries_id: id}; 
        addDescriptionRow(newDescRow);
    };

    deleteRow = (target, rowId) => {
        const { rowProps: {deleteRow, descriptionRows}, data: { id } } = this.props;
        deleteRow(target, (rowId ? rowId : id));
    };    

    getData = () => {
        return this.props.data;
    }

    getRowVatCode = () => {
        const { data, rowProps: {reverseVat,salesVatCodes, selectedVatCode}} = this.props;
        const rowVat = formatInputNumber(data.vat, 'no-zero-decimals');
        const defaultVatcode = { id: 0, value: data.vat, percentage: data.vat,  topText: rowVat + " %", subText: this.tr("No vatcode") }

        let rowVatCode = cloneDeep(salesVatCodes.find(s => s.id == data.sales_vatcode));

        // Show selected invoice's vatcode in rows if row has no vatcode and invoice has single vatcode.
        // So it shows correctly in old invoices when row specific vatcode were not in use.
        // Sending to integration selects also invoice's vatcode to rows in this case.
        if (!rowVatCode && Number(selectedVatCode?.id) > 0) {
            rowVatCode = cloneDeep(salesVatCodes.find(s => s.id == selectedVatCode?.id));
        }

        rowVatCode = rowVatCode || defaultVatcode;

        if (reverseVat) {
            rowVatCode.topText = "0 %";
            rowVatCode.tooltip = this.tr("Reverse vat applied");
            rowVatCode.tooltipIcon = SyncAlt;
        }
        return rowVatCode;
    }

    renderVatCell = (cellEdit = () => {}) => {
        const { data, columnWidthMap, rowProps:{ editMode,  useVatCodes, talenomActive, reverseVat, useRowSpecificCatcodes, emptyVatcodeSelected, salesVatCodes } } = this.props;

        const commonProps = {
            editable: editMode,
            textAlign: "right",
            onEdited: this.editRow,
            onEnter: false,
            runOnEditedOnInput: false,
            listCellProps: {
                editable: editMode,
                inEditMode: editMode
            }         
        };

        const vatNotEditable = reverseVat || talenomActive || (useVatCodes && !useRowSpecificCatcodes && !emptyVatcodeSelected);

        if (useRowSpecificCatcodes) {
            return <MultilineAutocompleteCell 
                editMode={editMode}
                autoCompleteData={salesVatCodes}
                value={this.getRowVatCode()} 
                onEdit={value => {
                    if (Number(value.id) < 0) {
                        value.id = 0;
                    }
                    this.editRow("sales_vatcode", value, data.id);
                }}
                listCellProps={{ 
                    ...commonProps.listCellProps,
                    width: columnWidthMap.vat,
                    showErrorBorder: data['sales_vatcode_invalid']
                }}
            />
        }
        else if (vatNotEditable) {
            return <ListCell 
                {...commonProps}
                name="vat"
                editable={false}
                value={formatInputNumber(data.vat, 'no-zero-decimals')}/>
        }
        else {
            return <TextInputCell 
                {...commonProps}
                name="vat"
                avoidFocus={true}
                onEdited={cellEdit}
                value={formatInputNumber(data.vat, 'no-zero-decimals')}/>
        }
    }

    render = () => {

        const { data, columnWidthMap, columnOrder, columnConfig, rowProps:{descriptionRows} } = this.props;
        const { taimerAccount } = this.context;

        const StyledHeaderRow = withStyles(styles)(HeaderRow);
        const StyledDescriptionRow = withStyles(styles)(DescriptionRow);

        const className = [this.defineClassName(), "listElement row", this.props.hidden ? "hidden" : ""].join(" ");

        const descs = descriptionRows && descriptionRows.length > 0 ? this.setDescriptionRows() : [];
        
        return (
            <>
                {data.row_category == '5' && !data.deleted && <StyledHeaderRow 
                    {...this.props}
                    key={data.id}
                    disableInitialFocus
                    addDescriptionRow={this.addDescriptionRow}
                    editRow={this.editRow}
                    deleteRow={this.deleteRow}
                    />}
                {data.row_category == '4' && !data.deleted && <CPQRow 
                    {...this.props}
                    key={data.id+100}
                    disableInitialFocus
                    editCPQ={this.editCPQ}
                    editRow={this.editRow}
                    addDescriptionRow={this.addDescriptionRow}
                    deleteRow={this.deleteRow}
                    renderVatCell={this.renderVatCell}
                    />}            

                {data.row_category == '3' && !data.deleted && <ProductRow 
                    {...this.props}
                    key={data.id+100}
                    disableInitialFocus
                    editRow={this.editRow}
                    addDescriptionRow={this.addDescriptionRow}
                    deleteRow={this.deleteRow}
                    renderVatCell={this.renderVatCell}
                    />}
                {data.row_category == '2' && !data.deleted && <StyledHeaderRow 
                    {...this.props}
                    key={data.id}
                    disableInitialFocus
                    addDescriptionRow={this.addDescriptionRow}
                    editRow={this.editRow}
                    deleteRow={this.deleteRow}
                    />}
                {data.row_category == '1' && !data.deleted && <ItemRow 
                    {...this.props}
                    key={data.id+100}
                    disableInitialFocus
                    editRow={this.editRow}
                    addDescriptionRow={this.addDescriptionRow}
                    deleteRow={this.deleteRow}
                    renderVatCell={this.renderVatCell}
                    />}
                {descs.length > 0 && descs.map(dsc => <StyledDescriptionRow 
                    {...this.props}
                    key={data.id+200}
                    rowData={dsc}
                    editRow={this.editDescriptionRow}
                    deleteRow={this.deleteRow}
                    />)
                }
                {data.total_marker > 0 && !data.deleted && <TotalsRow 
                    {...this.props} />}
            </>
        )
    }
}

InvoiceRow.defaultProps = {

};

export default InvoiceRow;
