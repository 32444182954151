import React from 'react';

import { ArrowBack, Clear } from '@mui/icons-material';
import { Button } from '@mui/material';
import Drawer from '@mui/material/Drawer';

import DateCell from '../../list/cells/DateCell';
import TextInputCell from "../../list/cells/TextInputCell";
import CurrencyListCell from '../../list/CurrencyListCell';
import LinkListCell from "../../list/LinkListCell";
import List from "../../list/List";
import ListCell from '../../list/ListCell';
import PropsOnlyListRow from "../../list/PropsOnlyListRow";

import cn from 'classnames';
import _ from 'lodash';
import TaimerAvatar from '../../general/TaimerAvatar';
import NumberListCell from '../../list/cells/NumberListCell';
import TaimerComponent from '../../TaimerComponent';
import './InsightSlider.css';

import CloudDownload from '@mui/icons-material/CloudDownload';

import colors from '../../colors';
import Link from '../../general/Link';
import Utils from '../../general/Utils';
import Loading from './img/loading.svg';
import { createExport } from './InsightExport';

const types = {
    'default': {
    },
    'invoice': {
        linkID: 'module=invoices&action=view&id=$ID',
    },
    'bill': {
        linkID: 'module=receivedinvoice&action=view&id=$ID',
    },
    'expense': {
        linkID: 'module=worktrips&action=modify&id=$ID&expenseType=1',
    },
    'travelExpense': {
        linkID: 'module=worktrips&action=modify&id=$ID&expenseType=2',
    },
}

export class InsightSliderRow extends PropsOnlyListRow {
    constructor(props, context) {
        super(props, {}, {}, "dashboard/insights/InsightSlider");

        this.refProject = React.createRef();

        this.statuses = [
            {id: '-1', name: this.tr('Active'), label: this.tr('Active'), value: '-1', color: colors.greenish_cyan},
            {id: '1', name: this.tr('Closed'), label: this.tr('Closed'), value: '1', color: "#f52b2b"},
            {id: '2', name: this.tr('On hold'), label: this.tr('On hold'), value: '2', color: "#ffaf0f"}
        ];
    }

    // TODO: abstract
    defineClassName() {
        return "insightSliderRow";
    }

    defineCells() {
        const { data } = this.props;
        const { sharedData: { table, currency, idColumn }, rowProps: {showLockedUsersWithTag} } = this.props;
        const { userObject } = this.context;

        const type = types[data.$type || (table && types[table.defaultType] ? table.defaultType : 'default')] || types.default;
        const linkID = type?.linkID ?? table?.linkID;

        const formatHours = (h) => {
            if (!h) return '–';

            return `${Number(h).toFixed(2)} ${this.tr('h')}`;
        }

        const getSplit = (data) => {
            if (data.split !== undefined) return `${data.split}%`;
            if (!data.tracked || !data.total || data.total == 0) return '–';
            return `${Math.round(data.tracked / data.total * 100)}%`;
        }

        const getTotalSplit = (data) => {
            if (!data.value || !data.all_rows_total || data.all_rows_total == 0) return '–';
            return `${Math.round(data.value / data.all_rows_total * 100)}%`;
        }

        const getActivityStatus = (statusId) => {
            switch (statusId) {
                case '1':
                    return this.tr("Done");
                case '2':
                    return this.tr("Due");
                case '3':
                    return this.tr("Overdue");
            }
        }

        const getTaskStatus = (statusId) => {
            switch (statusId) {
                case 1:
                    return this.tr("In progress");
                case 2:
                    return this.tr("Overdue");
                case 3:
                    return this.tr("Done");
                case 4:
                    return this.tr("Milestone");
                default:
                    return "-";
            }
        }

        const userLocked = `${data.users_name}${data.user_locked > 0 ? ` (${this.tr('locked')})` : ''}`;
        const userNameWithAllTags = data.users_name ? `${data.users_name} ${data.users_company < 1 ? ` (${this.tr("freelancer")})` : ''}${data.user_locked > 0 && showLockedUsersWithTag ? ` (${this.tr("locked")})` : ''}` : undefined;

        let cells = {};
        Object.keys(data).forEach(key => {
            cells[key] = (
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data[key] || ""} />
            )
        });

        cells = {
            ...cells,
            activity_status:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={getActivityStatus(data.status)} />,
            task_status:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={getTaskStatus(data.status)} />,
            number:
                linkID ? <LinkListCell
                    urlHandler={value => `index.html?${linkID}`
                        .replace('$ID', data[idColumn])
                        .replace('$CID', data.account_id)
                        .replace('$PID', data.project_id)}
                    value={data.num ?? "-"}
                    noTab={true}
                    editable={false} /> : <TextInputCell
                        onlyDisplay
                        editable={false}
                        value={data.num} />,
            user:
                <ListCell name="user" onlyDisplay editable={false}>
                    <div className="UserAvatarCell">
                        <TaimerAvatar
                            id={data.users_id}
                            name={data.users_name}
                            color={data.users_color}
                        />

                        {userNameWithAllTags || '–'}
                    </div>
                </ListCell>,
            sales_agent:
                 <ListCell name="user" onlyDisplay editable={false}>
                     <div className="UserAvatarCell">
                         <TaimerAvatar
                             id={data.users_id}
                             name={data.users_name}
                             color={data.users_color}
                         />
 
                         {userNameWithAllTags || '–'}
                     </div>
                 </ListCell>,
            account:
                <LinkListCell
                    urlHandler={value => `index.html?module=customers&action=view&id=${data.account_id}`}
                    // onlyDisplay
                    editable={false}
                    noTab={true}
                    asText={data.account_id < 1}
                    value={data.account_name || "–"} />,
            task_customer:
                <LinkListCell
                    urlHandler={value => `index.html?module=customers&action=view&id=${data.customers_id}`}
                    // onlyDisplay
                    editable={false}
                    noTab={true}
                    asText={data.customers_id < 1}
                    value={data.customers_name || "–"} />,
            project:
                <LinkListCell
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.project_id}`}
                    // onlyDisplay
                    editable={false}
                    noTab={true}
                    asText={data.project_id < 1}
                    value={data.project_name || "–"} />,
            project_my_day:
                <LinkListCell
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.id}`}
                    // onlyDisplay
                    editable={false}
                    noTab={true}
                    asText={data.id < 1}
                    value={data.project_name || "–"} />,
            task_project:
                <LinkListCell
                    urlHandler={value => `index.html?module=projects&action=view&id=${data.projects_id}`}
                    // onlyDisplay
                    editable={false}
                    noTab={true}
                    asText={data.projects_id < 1}
                    value={data.projects_name || "–"} />,
            qty:
                <TextInputCell
                    listCellType={NumberListCell}
                    listCellProps={{ showZero: true, decimals: 2 }}
                    onlyDisplay
                    editable={false}
                    value={data.quantity} />,
            sum:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency }}
                    onlyDisplay
                    editable={false}
                    value={data.sum} />,
            value:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency, allowEmpty: true  }}
                    onlyDisplay
                    editable={false}
                    value={data.value} />,
            probability:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency }}
                    onlyDisplay
                    editable={false}
                    value={data.probability} />,
            margin:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency, allowEmpty: true }}
                    onlyDisplay
                    editable={false}
                    value={data.value} />,
            tracked:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.tracked)} />,
            role:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={Array.isArray(data.role) ? data.role.map(r => this.tr(r)).join(", ") : Object.values(data.role || {}).map(r => this.tr(r)).join(", ")} />,
            hours_done:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.hours_done)} />,
            resourced:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.resourced)} />,
            hours_resourced:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.hours_resourced)} />,
            billable:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.billable)} />,
            invoicing_type:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.invoicing_type} />,
            date:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.date} />,
            lost_date:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.status_date} />,
            cost_type:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.cost_type} />,
            type:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.type} />,
            type_local:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data[Utils.getNameFieldByUserLang(userObject.language, 'type')] || data.type} />,
            jobtype:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.jobtype} />,
            hours:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={formatHours(data.hours)} />,
            billable_amount:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency }}
                    onlyDisplay
                    editable={false}
                    value={data.billable}
                    textAlign="right" />,
            amount:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency }}
                    onlyDisplay
                    editable={false}
                    value={data.amount} />,
            total_billable:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency }}
                    onlyDisplay
                    editable={false}
                    value={data.total_billable} />,
            split:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={getSplit(data)} />,
            total_split:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={getTotalSplit(data)} />,
            hourly_cost:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency }}
                    onlyDisplay
                    editable={false}
                    value={data.hourly_cost}
                    textAlign="right" />,
            invoiced:
                <TextInputCell
                    listCellType={CurrencyListCell}
                    listCellProps={{ showZero: true, currency }}
                    onlyDisplay
                    editable={false}
                    value={data.invoiced_amount}
                    textAlign="right" />,
            sales_status:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={this.statuses.find(x => x.id == data.locked)?.label} />,
            closing_date:
                <DateCell
                    onlyDisplay
                    editable={false}
                    value={data.closing_date} />,
            won_date:
                <DateCell
                    onlyDisplay
                    editable={false}
                    value={data.status_date} />,
            created_date:
                <DateCell
                    onlyDisplay
                    editable={false}
                    value={data.created_date} />,
            pipeline_date:
                <DateCell
                    onlyDisplay
                    editable={false}
                    value={data.pipeline_date} />,
            funnel:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.funnel ? data.funnel : (data.status === 1 ? this.tr('Won') : (data.status === 5 ? this.tr('Internal') : ''))} />,
            stage:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.stage} />,
            branch_of_business:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.branch_of_business} />,
            project_type:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.project_type} />,            
            average_days:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.average_days} />,
            days_pipeline:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={Number(data.days_pipeline) + " " + this.tr('d')} />,
            reminder:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.reminder || "–"} />,
            received_invoice_sender:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.received_invoice_sender} />,
            description:
                <TextInputCell
                    onlyDisplay
                    editable={false}
                    value={data.description} />,
        };

        return cells;
    }
}

class InsightSlider extends TaimerComponent {
    static defaultProps = {
        columns: ['number', 'account', 'project', 'sum'],
        sortKey: "sum",
        sortDir: "desc",
        idColumn: "id",
        allowSort: false,
        sliderText: false,
        showLockedUsersWithTag: true
    };

    sorts = {
        number: 'num',
        sum: 'sum',
        account: 'account_name',
        project: 'project_name',
        days_pipeline: 'days_pipeline',
        value: 'value',
        created_date: 'created_date',
        won_date: 'won_date',
        user: 'users_name',
        closing_date: 'closing_date',
    }

    constructor(props, context) {
        super(props, context, "dashboard/insights/InsightSlider");

        this.state = {
            rows: [],
            pageSize: 100,
            page: 1,
            sortKey: props.sortKey,
            sortDirection: props.sortDir,
        };
    }

    componentDidMount() {
        this.generateData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && prevProps.data != this.props.data) {
            this.generateData();
        } else if (prevProps.allRows != this.props.allRows || prevProps.currentRows != this.props.currentRows) {
            this.generateData();
        } else if (prevProps.open != this.props.open) {
            this.generateData();
        }
    }

    getViewAllLink = () => {
        const { table, parent, } = this.props;

        const link = { ...table.linkView };

        for (const key in link) {
            if (link.hasOwnProperty(key)) {
                const element = String(link[key]);

                if (element.startsWith('$')) {
                    link[key] = parent[element.substring(1)];
                }
            }
        }

        return link;
    }

    viewAll = (e) => {
        const { functions: { updateView } } = this.context;

        !e.ctrlKey && !e.metaKey && e.preventDefault();
        updateView(this.getViewAllLink(), false);
    }

    compare = (a, b) => {
        const { sortKey, sortDir } = this.state;

        const sortCol = this.sorts[sortKey] || sortKey;

        const valueA = String(a[sortCol]);
        const valueB = String(b[sortCol]);

        const numA = Number(valueA);
        const numB = Number(valueB);

        if (isNaN(numA) || isNaN(numB)) {
            return sortDir == "desc" ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
        } else {
            return sortDir == "desc" ? numB - numA : numA - numB;
        }
    }
 
    generateData = () => {
        const { data, allRows, currentRows, sortKey, open } = this.props;
        if (!this.props.open) {
            this.setState({ rows: [], page: 1 });
            return;
        }

        if (data) {
            const sort = data.sort(this.compare);

            let count = 0;

            const rows = _.map(sort, row => {
                return {key: `insightRow${count++}`, ...row};
            })

            this.setState({ rows });
        } else if (currentRows) {
            const rows = [];
            let count = 0;

            if (allRows) {
                _.map(currentRows, (v, i) => {
                    if (v === true || v === 1) {
                        rows.push({key: `insightRow${count++}`, ...allRows[i]})
                    } else {
                        rows.push({key: `insightRow${count++}`, ...allRows[i], ...v });
                    }
                });
            }

            this.setState({ rows: rows.sort(this.compare) });
        }
    }

    export = (columns) => {
        const { label, monthLabel: subLabel, currency } = this.props;
        const { userObject: { dateFormat } } = this.context;

        const columnOpt = {
            project: {
                title: this.tr('Project'),
                column: 'project_name',
            },
            account: {
                title: this.tr('Customer'),
                column: 'account_name',
            },
            created_date: {
                title: this.tr('Created Date'),
                column: 'created_date',
                type: 'date',
                format: 'YYYY-MM-DD',
                displayFormat: dateFormat,
            },
            funnel: {
                title: this.tr('Funnel'),
                column: 'funnel',
                format: 'custom',
                formatter: (value, row) => value ? value : (row.status === 1 ? 'Won Deals' : (row.status === 5 ? 'Internal' :  '')),
            },
            pipeline_date: {
                title: this.tr('Added to Pipeline'),
                column: 'pipeline_date',
                type: 'date',
                format: 'YYYY-MM-DD',
                displayFormat: dateFormat,
            },
            stage: {
                title: this.tr('Stage'),
                column: 'stage',
            },
            branch_of_business: {
                title: this.tr('Branch of business'),
                column: 'branch_of_business',
            },
            project_type: {
                title: this.tr('Project Type'),
                column: 'project_type',
            },
            user: {
                title: this.tr('User'),
                column: 'users_name',
            },
            value: {
                title: this.tr('Value'),
                column: 'value',
                type: 'currency',
                currency,
            },
        };

        const exportOptions = {
            title: `${label}${subLabel ? ` - ${subLabel}` : ''}`,
            data: this.state.rows,
            columns: [],
        };

        for (const col of columns) {
            const co = columnOpt[col.name];

            if (!co) {
                console.error(col, "missing");
                continue;
            }

            exportOptions.columns.push(co);
        }

        createExport({
            name: `${label}${subLabel ? ` - ${subLabel}` : ''}`,
        }, [
            exportOptions,
        ])
    }

    render() {
        const { label, monthLabel: subLabel, sum, sliderText, open, table, onClose, columns, overrideColumns, currency, idColumn, loading, rowType, sharedData, allowSort, showExport } = this.props;
        const { functions: { presentCurrency, urlify } } = this.context;
        const { tr } = this;
        const { rows, page, pageSize } = this.state;

        const colsMap = _.mapKeys([
            { field: "user", name: "user", header: tr("User"), width: 250, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "sales_agent", name: "sales_agent", header: tr("Sales Agent"), width: 250, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "number", name: "number", header: tr("No."), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "account", name: "account", header: tr("Account"), width: 250, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "project", name: "project", header: tr("Project"), width: 250, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "project_my_day", name: "project_my_day", header: tr("Project"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "task_customer", name: "task_customer", header: tr("Account"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "task_project", name: "task_project", header: tr("Project"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "qty", name: "qty", header: tr("Qty"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "cost_type", name: "cost_type", header: tr("Cost Type"), width: 200, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "sum", name: "sum", header: tr("Sum"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "margin", name: "margin", header: tr("Margin"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "created_date", name: "created_date", header: tr("Created Date"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "pipeline_date", name: "pipeline_date", header: tr("Added to Pipeline"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "won_date", name: "won_date", header: tr("Won Date"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "closing_date", name: "closing_date", header: tr("Closing Date"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "days_pipeline", name: "days_pipeline", header: tr("Days in Pipeline"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "value", name: "value", header: tr("Value"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "probability", name: "probability", header: tr("Probability"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "tracked", name: "tracked", header: tr("Tracked"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "resourced", name: "resourced", header: tr("Resourced"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "hours_resourced", name: "hours_resourced", header: tr("Resourced"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "hours_done", name: "hours_done", header: tr("Tracked"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "billable", name: "billable", header: tr("Billable"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "billable_amount", name: "billable_amount", header: tr("Billable"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "amount", name: "amount", header: tr("Amount"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "stage", name: "stage", header: tr("Stage"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "role", name: "role", header: tr("Role"), width: 200, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "total_billable", name: "total_billable", header: tr("Billable Amount"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "split", name: "split", header: tr("Split"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "total_split", name: "total_split", header: tr("Split"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "hourly_cost", name: "hourly_cost", header: tr("Hourly Cost"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "invoiced", name: "invoiced", header: tr("Invoiced"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "sales_status", name: "sales_status", header: tr("Status"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "funnel", name: "funnel", header: tr("Funnel"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "average_days", name: "average_days", header: tr("Average Days"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "invoicing_type", name: "invoicing_type", header: tr("Invoicing Type"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "date", name: "date", header: tr("Date"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "deadline", name: "deadline", header: tr("Deadline"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "completed", name: "completed", header: tr("Completed"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "jobtype", name: "jobtype", header: tr("Jobtype"), width: 150, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "hours", name: "hours", header: tr("Hours"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "type", name: "type", header: tr("Type"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "activity_status", name: "activity_status", header: tr("Status"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "task_status", name: "task_status", header: tr("Status"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "created", name: "created", header: tr("Created"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "lost_date", name: "lost_date", header: tr("Lost Date"), width: 140, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "reminder", name: "reminder", header: tr("Reminder"), width: 100, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "description", name: "description", header: tr("Description"), width: 220, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "contact", name: "contact", header: tr("Contact"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "targeted_to", name: "targeted_to", header: tr("Targeted To"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "branch_of_business", name: "branch_of_business", header: tr("Branch of Business"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "project_type", name: "project_type", header: tr("Project Type"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "received_invoice_sender", name: "received_invoice_sender", header: tr("Sender"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
            { field: "description", name: "description", header: tr("Description"), width: 170, resizeable: false, moveable: false, hideable: true, showMenu: true },
        ], x => x.name);

        const cols = overrideColumns ?? _.map(columns, x => colsMap[x]).filter(x => x);

        _.map(cols, (v) => {
            v.sortable = this.sorts[v.name];
            // v.showMenu = allowSort && this.sorts[v.name];
        })

        return <Drawer classes={{paper: 'insight-paper'}} className={cn("insight-slider")} anchor="right" open={open} onClose={onClose}>
            {loading !== undefined && <div className={cn("insight-slider-loading-indicator", loading && "insight-slider-loading-indicator-active")}>
                <img src={Loading} />
            </div>}
            <div className="header">
                <div className="left">
                    {this.props.showBackButton && <button onClick={this.props.onBackPress}>
                        <ArrowBack />
                    </button>}
                    <div className="title">
                        <h3>{label}</h3>
                        {sliderText && <div>{sliderText}</div>}
                        <span>
                            {subLabel} {sum !== undefined && this.tr("Total")} {sum !== undefined && presentCurrency(sum, currency)}
                            {table && table.linkView && <Link url={urlify(this.getViewAllLink())} onClick={this.viewAll}>{this.tr("View All")}</Link>}
                        </span>
                    </div>
                </div>
                <div className="close"><Button onClick={onClose}><Clear /></Button></div>
            </div>
            {showExport && <div className="insightSliderExportRow">
                <a className="insightsExportButton" onClick={() => this.export(cols)}><CloudDownload /></a>
            </div>}
            <div className="table">
                <List
                    key={rows.length > pageSize ? "pagedList" : "singleList"}
                    sharedData={{ table, currency, idColumn, ...sharedData }}
                    rowProps={{showLockedUsersWithTag: this.props.showLockedUsersWithTag}}
                    fluid
                    noStateData={true}
                    height="fitRemaining"
                    rowHeight={44}
                    rowKey="key"
                    data={rows.slice((page - 1) * pageSize, page * pageSize)}
                    columns={cols}
                    listRowType={rowType || InsightSliderRow}
                    showPageSelector={rows.length > pageSize}
                    perpage={pageSize}
                    totalCount={rows.length}
                    onPageChange={page => this.setState({ page })}
                    onPerPageChange={(pageSize) => this.setState({ pageSize })}
                    pageCount={Math.ceil(rows.length / pageSize)}
                    onSortRows={(col, asc) => {
                        this.setState({
                            sortKey: col,
                            sortDir: asc ? "asc" : "desc",
                        }, this.generateData);
                    }}
                />
            </div>
        </Drawer>
    }
}

export default InsightSlider;
