import PropTypes from "prop-types";
import React from 'react';
import ReactDOM from 'react-dom';

/* material ui */
import { MenuItem, Tooltip, Switch, Popover, MenuList, Badge } from '@mui/material';

/* local components */
import { formatInputNumber } from '../helpers';
import AutoCompleteCell from '../list/cells/AutoCompleteCell';
import InfoSelect from '../list/cells/InfoSelect';
import ConfirmationDialog from "../list/dialogs/ConfirmationDialog";
import PlaceholderListCell from '../list/PlaceholderListCell';
import TaimerComponent from "../TaimerComponent";
import ContextMenu from './../general/ContextMenu';
import OutlinedField from "./../general/OutlinedField";
import ResourceRecurrenceDialog from './../dialogs/ResourceRecurrenceDialog';
import AccountingSlider from './../invoices/AccountingSlider';
import AccountingButton from './../invoices/AccountingButton';
import AccountingCell from './../invoices/AccountingCell';
import CheckboxCell from "./../list/cells/CheckboxCell";
import DateCell from "./../list/cells/DateCell";
import StatusCell from "./../list/cells/StatusCell";
import TextInputCell from './../list/cells/TextInputCell';
import CurrencyListCell from "./../list/CurrencyListCell";
import List from './../list/List';
import ListCell from './../list/ListCell';
import InfoIconCell from './../list/cells/InfoIconCell';
import PropsOnlyListRow from "./../list/PropsOnlyListRow";
import CostsAutomation from './CostsAutomation';
import { getMultiDimensionHeaders, getDefaultDimensionValuesForHeaders, getProductDimensionValuesForHeaders } from './../invoices/helpers';
import { CurrencyUtils } from "../general/CurrencyUtils";

/* data backend */
import { getWorktypesForProject } from '../Data';
import DataHandler from './../general/DataHandler';

/* css */
import './TabInvoicing.css';

/* context */
import { SettingsContext } from './../SettingsContext';

/* material icons */
import { ExpandLess, ExpandMore, MoreHoriz } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as AddCostRowIcon } from './../general/icons/AddCostRow.svg';
import { ReactComponent as AddCPQRowIcon } from './../general/icons/AddCPQRow.svg';
import { ReactComponent as CopyIcon } from './../general/icons/copy.svg';
import { ReactComponent as ProductRowIcon } from './../general/icons/ProductRow.svg';
import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';
//import { ReactComponent as AddIcon } from './../general/icons/add.svg';
import { getProcountorProjectDimension, updateDimensionValues } from '../invoices/AccountingUtils';

/* other */
import { addDays, addMonths, addWeeks, differenceInDays, differenceInMonths, differenceInWeeks, endOfDay, endOfMonth, format, isEqual, startOfDay, startOfMonth, subDays, subMonths, subWeeks } from "date-fns";
import { cloneDeep, isEqual as lodash_isEqual } from 'lodash';

const nUndef = v => v !== undefined;
const nUndefGtZero = v => v !== undefined && v > 0;
const nEmptyString = v => typeof v === "string" && v.trim().length > 0;


class InvoiceRow extends PropsOnlyListRow {
    static contextType = SettingsContext;

    static defaultProps = {
        maximumFractionDigits: 4
    }

    constructor(props) {
        super(props, {}, {}, "projects/TabInvoicing");
        ["cellEdited"].forEach(e => this[e] = this[e].bind(this));
        
        this.initialFocusDone = false;

        this.descriptionCell = React.createRef();
        this.productCell = React.createRef();

        this.newRowValidators = {
            quantity: { validator: nUndefGtZero, columnName: "quantity" },
            // value: { validator: nUndefGtZero, columnName: "value" },
        };
 
    }

    // Check that all needed fields have some value.
    saveNewRow(data) {
        const invalids = [];

        for(const i in this.newRowValidators) {
            const { validator, columnName } = this.newRowValidators[i];
            !validator(this.props.data[i]) && (invalids.push(columnName));
        }

        this.setInvalidFields(invalids);

        if(invalids.length > 0)
            return;

        this.create(data);
    }

    cellEdited(name, value) {
        const {data} = this.props;

        if (name == "quantity" || name == "value" || name == "vat") 
            value = value.replace(',', '.');

        if (data[name] == value)
            return;

        data[name] = value;

        if (data.id < 0)
            this.saveNewRow(data);
        else
            this.update(data);
    }

    cellEdited2 = async (product) => {

        let {data} = this.props;
        let newData = {};

        if (product.balance) {
            const discountAmount = CurrencyUtils.calculateDiscountAmount(product.income_price, product.discount_percent, 2);

            newData = {
                value: (parseFloat(product.income_price) - parseFloat(discountAmount)),
                vat: product.vat,
                description: product.name,
                product_register_id: product.id            
            }
        } else {
            const result = await DataHandler.get({ url: `cpq/parent`, parentId: product.id });
            newData = {
                cpq_id: product.id,
                description: result.CPQ.description,
                value: result.CPQ.income_price, 
                vat: product.vat > 0 ? product.vat : data.vat                
            }
        }
        data = {...data, ...newData};

        if (data.id < 0)
            this.saveNewRow(data);
        else
            this.update(data);
    }

    render() {
        const { taimerAccount, userObject } = this.context;
        const { data, columnOrder, columnWidthMap, sharedData, rowProps } = this.props;
        const { currency } = rowProps;

        const props = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": `cell ${prop}`, style: {width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0" }} }
        });
        const cellProps = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, listCellProps: {"className": prop}, width: columnWidthMap[prop], name: prop, value: data[prop]}}
        });

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: this.props.maximumFractionDigits
        }).format;
        const dateFormat = this.props.dateFormat ? this.props.dateFormat : userObject.dateFormat;
        const commonProps = {
            editable: rowProps.editable && data.bills_id > 0 ? false : true,     
        }

        const rowType = () => {
            
            if (data.isCPQRow || (data.cpq_id > 0 || data.cpq_id == -1))
                return 'cpq';
            if (data.isProductRow || (data.product_register_id > 0 || data.product_register_id == -1))
                return 'product_register';
            else
                return 'default';
        }

        let productName = "";
        let cpqName = "";
        if (data.isProductRow || (data.product_register_id > 0 || data.product_register_id == -1)) {
            const product = rowProps.products.find(p => p.id === data.product_register_id);
            productName = product && product.name ? product.name : "";
        }
        if (rowType() == "cpq") {
            const cpq = rowProps.cpqs.find(c => c.id === data.cpq_id);
            cpqName = cpq && cpq.name ? cpq.name : "";
        }
        const jobtype = data.jobtypes_id > 0 && this.props.sharedData.jobtypes ? this.props.sharedData.jobtypes.find(t => t.id == data.jobtypes_id) : {};

        const cells = {
            "context": data.id > 0 ? (
                <ListCell tabIndex="1" onlyDisplay={true} width="60">   
                    {data.bills_id > 0 ? null : <DeleteIcon
                        onClick={() => rowProps.onDialogOpen('confirmation', {
                            id: data.id,
                            saveFunc: (id) => this.delete(data),
                            text: this.tr('Do you want to delete scheduled invoice') + ": " + format(data.duedate, dateFormat.toUpperCase()) + " (" + currencyFormatter(data.total) + ")?" 
                        })} 
                    />}
                </ListCell> ) 
                :
                (
                    <ListCell tabIndex="1" onlyDisplay={true} width="60">
                        <Tooltip title={this.tr("Delete")} placement="bottom">
                            <DeleteIcon 
                                onClick={() => rowProps.listRef.current.setState({data: rowProps.listRef.current.state.data.filter(d => d.id != data.id)})}  
                            // }
                            />
                        </Tooltip>
                    </ListCell>
            ),
            "openChildren": (
                rowProps.selectedType == 1 ?
                null :
                <div className="disabled-div" style={{width: `${columnWidthMap.openChildren}px`, flex:`${columnWidthMap.openChildren} 1 0`}} />
            ),
            "is_automatic": (
                rowProps.selectedType == 1 ?
                null :
                <div className="disabled-div" style={{width: `${columnWidthMap.is_automatic}px`, flex:`${columnWidthMap.is_automatic} 1 0`}} />
            ),                
            "duedate": (
                <DateCell
                    {...commonProps}
                    noMonthChange={data.linked_to_revenue_recognition == "1"}
                    width={columnWidthMap.duedate}
                    value={data.duedate}
                    onEdited={(n, date) => this.cellEdited("duedate", date)} />
            ),
            "duedate_repeat_increment": (
                <TextInputCell
                    {...commonProps}
                    width={columnWidthMap.duedate_repeat_increment}
                    listCellProps={{className: 'recurring'}}
                    textAlign="center"
                    name="duedate_repeat_increment"
                    value={data.duedate_repeat_increment}
                    onEdited={this.cellEdited} />
            ),
            "duedate_repeat": (
                <AutoCompleteCell
                    {...commonProps}
                    width={columnWidthMap.duedate_repeat}
                    autoCompleteData={sharedData.duedate_repeat}
                    value={data.duedate_repeat}
                    onEdited={option => this.cellEdited("duedate_repeat", option.id)} />
            ),
            "period_start": (
                rowProps.selectedType == 1 ?
                null :                
                <div className="disabled-div" style={{width: `${columnWidthMap.period_start}px`, flex:`${columnWidthMap.period_start} 1 0`}} />
            ),
            "period_end": (
                rowProps.selectedType == 1 ?
                null :                
                <div className="disabled-div" style={{width: `${columnWidthMap.period_end}px`, flex:`${columnWidthMap.period_end} 1 0`}} />
            ),
            "until": (
                <DateCell
                    {...commonProps}
                    offsetCalendar={true}
                    width={columnWidthMap.until}
                    value={data.until}
                    onEdited={(n, date) => this.cellEdited("until", date)} />
            ),
            "billing_zone": (
                <AutoCompleteCell
                    {...commonProps}
                    width={columnWidthMap.billing_zone}
                    autoCompleteData={sharedData.billing_zone}
                    value={data.billing_zone}
                    onEdited={option => this.cellEdited("billing_zone", option.id)} />
            ),
            "product_register_id": commonProps.editable && data.is_quote_row_entry == 1 && rowType() != 'default' ?
                <InfoIconCell 
                    name={"product_register_id"}
                    tooltip={this.tr("This scheduled invoice includes information from multiple sales quote rows and cannot be edited.")}
                    textAlign={"left"}
                    value={rowType() == 'product_register' ? productName : cpqName}
                    width={columnWidthMap.product_register_id}
                    hideOverFlow={true}
                />
                : (
                rowType() == 'product_register' ?
                    <InfoSelect
                        {...commonProps}
                        ref={this.productCell}
                        value={productName}
                        height={230}
                        tableWidth={550}
                        headerHeight={114}
                        rowHeight={49}
                        placeholder={productName ? productName : this.tr('Select product')}
                        className="is_tabinvoicing"
                        options={rowType() == 'product_register' ? rowProps.products.filter(e => Number(e.deleted) === 0) : rowProps.cpqs}
                        width={columnWidthMap.product_register_id}
                        columns={[
                            { name: "code", header: this.tr("Code"), width: 75 },
                            { name: "name", header: this.tr("Name"), width: 200 },
                            { name: "path", header: this.tr("Category"), width: 200 },
                            { name: "unit", header: this.tr("Unit"), width: 75 },
                        ]}
                        zeroBasis={true}
                        onChange={data => this.cellEdited2(data)}
                        disableOnBlur={true}
                    />
                    :
                    <AutoCompleteCell
                        {...commonProps}
                        ref={this.productCell}
                        width={columnWidthMap.product_register_id}
                        name="product_register_id"
                        allowCreate={false}
                        value={rowType() == 'default' ? '' : data[`${rowType()}_id`]}
                        autoCompleteData={rowType() == 'product_register' ? rowProps.products : rowProps.cpqs}
                        searchable={true}
                        listCellProps={{
                            editable: rowType() != 'default' && !(rowProps.editable && data.bills_id > 0),
                        }}
                        onEdited={data => { this.cellEdited2(data) }}
                    />
            ),
            "description": (
                <TextInputCell
                    {...commonProps}
                    listCellType={PlaceholderListCell}
                    ref={this.descriptionCell}
                    listCellProps={{
                        placeholder: this.tr("Topic")
                    }}
                    width={columnWidthMap.description}
                    name="description"
                    value={data.description}
                    onEdited={this.cellEdited} />
            ),
            "invoiced": (
                data.bills_id > 0 ?
                <StatusCell 
                    value={null}
                    listCellProps={{className: 'statusCell'}} 
                    width={columnWidthMap.invoiced}
                    clickable
                    urlHandler={value => `index.html?module=invoices&action=view&id=${data.bills_id}`} 
                    displayData={{
                        name: data.bill_id, 
                        color: '#2d9ff7'}}/> 
                :
                <StatusCell 
                    value={null}
                    listCellProps={{className: 'statusCell'}} 
                    width={columnWidthMap.invoiced} 
                    displayData={{
                        name: this.tr("Uninvoiced"), 
                        color: '#ffb822'}}/> 
            ),
            "quantity": (commonProps.editable && data.is_quote_row_entry == 1 ?
                <InfoIconCell 
                    name={"quantity"}
                    tooltip={this.tr("This scheduled invoice includes information from multiple sales quote rows and cannot be edited.")}
                    textAlign={"left"}
                    value={formatInputNumber(data.quantity)}
                    width={columnWidthMap.quantity}
                />
                : <TextInputCell
                    {...commonProps}
                    editable={commonProps.editable && data.is_quote_row_entry == 0}
                    textAlign="left"
                    width={columnWidthMap.quantity}
                    name="quantity"
                    value={formatInputNumber(data.quantity)}
                    onEdited={this.cellEdited} />
            ),
            "value": (commonProps.editable && data.is_quote_row_entry == 1 ?
                <InfoIconCell 
                    name={"value"}
                    tooltip={this.tr("This scheduled invoice includes information from multiple sales quote rows and cannot be edited.")}
                    textAlign={"left"}
                    value={currencyFormatter(data.value)}
                    width={columnWidthMap.value}
                />
                : <TextInputCell
                    {...commonProps}
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        currency: currency,
                        maximumFractionDigits: this.props.maximumFractionDigits
                    }}
                    width={columnWidthMap.value}
                    name="value"
                    value={parseFloat(data.value).toFixed(this.props.maximumFractionDigits)}
                    onEdited={this.cellEdited} />
            ),
            "total_vat0": (
                <ListCell
                    {...commonProps}
                    editable={false}
                    textAlign="right"
                    width={columnWidthMap.total_vat0}
                    name="total_vat0"
                    value={currencyFormatter(data.total_no_vat)} />
            ),
            "vat": (
                <TextInputCell
                    {...commonProps}
                    width={columnWidthMap.vat}
                    name="vat"
                    textAlign="right"
                    value={formatInputNumber(data.vat)}
                    onEdited={this.cellEdited} />
            ),
            "total": (
                <ListCell
                    {...commonProps}
                    editable={false}
                    textAlign="right"
                    width={columnWidthMap.total}
                    value={currencyFormatter(data.total)} />
            ),
            "jobtypes_id": (
                <AutoCompleteCell
                    {...commonProps}
                    width={columnWidthMap.jobtypes_id}
                    name="jobtypes_id"
                    allowCreate={false}
                    value={jobtype}
                    autoCompleteData={sharedData.jobtypes}
                    searchable={true}  
                    onEdited={option => this.cellEdited("jobtypes_id", option.id)}
                />
            ),
        };

        return (
            <div className="row listElement scheduled-invoice-row" style={{
                height: "44px",
                lineHeight: "44px",
                display: "flex"
            }} >
                {columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }

}

class ContractParentRow extends PropsOnlyListRow {
    
    static contextType = SettingsContext;
    
    constructor(props) {
        super(props, {}, {}, "projects/TabInvoicing");
        
        this.initialFocusDone = false;
        this.descriptionCell = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        const { rowProps } = this.props;
        this.setState({
            showChildren: rowProps.expandedRows.some(r => r == this.props.data.id), 
            showRecurrenceDialog: rowProps.showRecurrenceDialog
        });
        if (!this.initialFocusDone && this.props.data['id'] < 0) {
            this.descriptionCell.current.listCell.current.openEdit();
            this.initialFocusDone = true;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return super.shouldComponentUpdate(nextProps, nextState) || !lodash_isEqual(nextProps.rows, this.props.rows) || nextProps.dimensionHeadersDiffer != this.props.dimensionHeadersDiffer || !lodash_isEqual(nextProps.defaultDimensionValues, this.props.defaultDimensionValues);
    }    

    cellEdited(name, value) {

        const {data} = this.props;

        if (data.bills_id > 0 && data.credited_id > 0) {
            data.bills_id = 0;
            data.bill_id = 0;
        }

        if (name == "quantity" || name == "value" || name == "vat") 
            value = value.replace(',', '.');
        if (name == "name")
            value = data.bills_id > 0 && data.credited_id > 0 ? value.substring(value.indexOf(":") + 2) : value;

        data[name] = value;

        const timeAddFunc = {
            1: (date, integer) => subDays(addMonths(date, integer), 1),
            2: (date, integer) => subDays(addWeeks(date, integer), 1),
            3: (date, integer) => subDays(addDays(date, integer), 1)
        };

        if (name == 'duedate_repeat_increment') {
            data.duedate_repeat = value > 0 ? (data.duedate_repeat == 0 ? '1' : data.duedate_repeat) : '0';
            if (value > 0 && data.duedate_repeat > 0)
                data.period_end = format(timeAddFunc[data.duedate_repeat](new Date(data.period_start), data.duedate_repeat_increment), "YYYY-MM-DD");
        }

        if (name == 'duedate_repeat' && value > 0 && data.duedate_repeat > 0)
            data.period_end = format(timeAddFunc[data.duedate_repeat](new Date(data.period_start), data.duedate_repeat_increment), "YYYY-MM-DD");

        if (name == 'period_start' || name == 'period_end') {

            const start = startOfDay(new Date(data.period_start));
            let end = addDays(endOfDay(new Date(data.period_end)), 1);

            while (differenceInMonths(end, start) > 0)
                end = subMonths(end, 1);

            if (differenceInDays(end, start) == 0) {
                data.duedate_repeat = 1;
                data.duedate_repeat_increment = differenceInMonths(addDays(endOfDay(new Date(data.period_end)), 1), startOfDay(new Date(data.period_start)));                
            } else {
                let end = addDays(endOfDay(new Date(data.period_end)), 1);
                while (differenceInWeeks(end, start) > 0)
                    end = subWeeks(end, 1);

                if (differenceInDays(end, start) == 0) {
                    data.duedate_repeat = 2;
                    data.duedate_repeat_increment = differenceInWeeks(addDays(endOfDay(new Date(data.period_end)), 1), startOfDay(new Date(data.period_start)));
                } else {
                    data.duedate_repeat = 3;
                    data.duedate_repeat_increment = differenceInDays(addDays(endOfDay(new Date(data.period_end)), 1), startOfDay(new Date(data.period_start)));                    
                }             
            }
        }

        this.props.rowProps.updateContract(data);
    }

    checkboxEdited(name, value) {
        const data = {...this.props.data};
        data[name] = value ? 1 : 0;

        this.props.rowProps.updateContract(data);
    }    

    toggleChildren = () => {
        this.setState({showChildren: !this.state.showChildren, showAccounting: this.state.showChildren !== false ? false : this.state.showAccounting}, () => {
            this.props.rowProps.onExpand(this.props.data.id, this.state.showChildren);
        });
    }

    deleteContract = () => {
        this.props.rowProps.onDelete(this.props.data.id);
    }

    copyContract = () => {
        this.props.rowProps.onCopy(this.props.data.id);
    }

    addContractRow = (isProductRow, isCPQRow) => {
        this.props.rowProps.onContractRowAdd(this.props.data.id, isProductRow, isCPQRow, this.props.defaultDimensionValues);
    }

    recurrenceSave = (rule) => {
        this.props.rowProps.recurrenceSave(rule);
    }

    createContractBill = () => {
        this.props.rowProps.createContractBill(this.props.data.id);
        this.setState({showAccounting: false});
    }

    accountingCellEdited = (name, value, rowId) => {
        const rows = cloneDeep(this.props.rows);
        const i = rows.findIndex(r => r.id == rowId);

        rows[i][name] = value;

        if(name === "talenom_account") {
            rows[i]["sales_account"] = value.id;
            rows[i]['vat'] = value.vat_value;
            rows[i]['total'] = Number(rows[i]['quantity']) * Number(rows[i]['value']) * ((100 + Number(value.vat_value))/100);
        }

        this.props.setContractRows(rows)
    }

    onAccountingRowsCopied = (copiedRows, callback = () => {}) => {
        const newRows = copiedRows.map(row => {
            if (row.sales_account) {
                const salesAccount = this.props.accountingData.salesAccounts.find(s => s.id == row.sales_account);
                if (salesAccount && salesAccount.integration == "talenom") {
                    row['vat'] = salesAccount.vat_value;
                    row['total'] = Number(row['quantity']) * Number(row['value']) * ((100 + Number(salesAccount.vat_value))/100);
                }
            }
            return row;
        });

        this.props.setContractRows(newRows, callback);
    }

    onAccountingSave = (data = {}) => {
        this.props.closeAccountingSlider();
        this.props.updateDimensionHeadersDiffer(data);
        this.props.rowProps.updateContractAccounting(this.props.rowProps.projectsId, this.props.rows);
    }

    onAccountingClose = (data) => {
        this.props.closeAccountingSlider();
        this.props.setContractRows(data.originalRows);
    }

    setInvoicableCosts = (name, val) => {
        const { data } = this.props;
        data[name] = val;
        if (name == 'charge_hours' && val > 0 && data.hours_grouping < 1)
            data.hours_grouping = '4';
        if (name == 'charge_expenses' && val > 0 && data.expenses_grouping < 1)
            data.expenses_grouping = '1';
        this.props.rowProps.updateContract(data, true);
    }

    renderAccountingButton = (editable) => {
        const { dimensionHeadersDiffer } = this.props;

        return <AccountingButton
            dimensionHeadersDiffer={dimensionHeadersDiffer}
            editable={editable}
            text={this.tr('Show accounting')}
            classname="accounting-option"
            openAccountingSlider={() => this.props.openAccountingSlider()}
        />
    }

    render() {
        const { taimerAccount, userObject } = this.context;
        const { data, columnOrder, columnWidthMap, sharedData, rowProps, accountingData } = this.props;
        const { currency } = rowProps;

        const props = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": `cell ${prop}`, style: {width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0" }} }
        });
        const cellProps = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": prop, listCellProps: {"className": prop}, width: columnWidthMap[prop], name: prop, value: data[prop]}}
        });

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency
        }).format;

        const dateFormat = this.props.dateFormat ? this.props.dateFormat : userObject.dateFormat;

        const billed = () => {
            if (data.bills_id > 0 && data.credited_id < 1)
                return true;
            else
                return false;
        }

        const commonProps = {
            editable: rowProps.editable && billed() ? false : true
        }

        const validContractForBilling = () => {
            if (isEqual(startOfDay(new Date(data.date)), startOfDay(new Date())) && data.is_automatic > 0)
                return true;
            else
                return false;
        }

        const rows = [...this.props.rows];
        for(let i = 0; i < rows.length; i++) {
            rows[i].row_category = 1;
        }

        const invoicableCosts = {
            hours: data.charge_hours,
            hours_grouping: data.hours_grouping,
            expenses: data.charge_expenses,
            expenses_grouping: data.expenses_grouping,
            received_invoices: data.charge_received_invoices,
            scheduled_invoices: data.charge_scheduled_invoices,
            visibility: ['charge_hours', 'charge_others', 'charge_traveling', 'charge_received_invoices'].map(a => {return {[a]: this.props.rowProps.project[a] > 0}})
        }

        const hasInvoiceableCosts = Object.entries(data).some(([p, d]) => p.startsWith('charge_') && d > 0);

        const cells = {
            "context": !billed() ? (data.id > 0 ? (
                <ContextMenu
                    className="invoice-row-menu row-menu" 
                    label={<MoreHoriz />} 
                    buttonProps={{ className: 'action-menu' }} 
                    style={{width: `${columnWidthMap.context}px`, flex:`${columnWidthMap.context} 1 0`}} 
                    noExpandIcon >
                    <MenuItem onClick={() => this.addContractRow(false)}><AddCostRowIcon />{this.tr('Add cost row')}</MenuItem>
                    {this.context.versionId > 3 && <MenuItem onClick={() => this.addContractRow(true)}><ProductRowIcon/>{this.tr('Add product row')}</MenuItem>}
                    {this.context.versionId > 3 && <MenuItem onClick={() => this.addContractRow(false, true)}><AddCPQRowIcon  />{this.tr('Add CPQ row')}</MenuItem>}
                    <MenuItem onClick={() => this.copyContract()}><CopyIcon />{this.tr('Copy')}</MenuItem>
                    <MenuItem className="delete" onClick={() => rowProps.onDialogOpen('confirmation', {
                            id: data.id,
                            saveFunc: (id) => this.delete(data),
                            text: this.tr('Do you want to delete automatic invoice') + ": " + format(data.duedate, dateFormat.toUpperCase()) + " (" + currencyFormatter(data.total) + ")?" 
                        })
                    }>
                    <RemoveIcon className="Delete"/>{this.tr('Delete')}    
                    </MenuItem>
                    {validContractForBilling() && rowProps.canCreateInvoice && <MenuItem onClick={() => this.createContractBill()}><AddIcon />{this.tr('Create invoice')}</MenuItem>}
                </ContextMenu> ) 
                :
                (<ListCell tabIndex="1" onlyDisplay={true} {...cellProps.context}>
                    <Tooltip title="Save" placement="bottom">
                        <DeleteIcon
                            onClick={() => rowProps.listRef.current.removeNewRow(data.id)} 
                        />
                    </Tooltip>
                </ListCell>
            )) : (<div className="disabled-div" style={{width: `${columnWidthMap.context}px`, flex:`${columnWidthMap.context} 1 0`}} />),
            "openChildren": this.state.showChildren ? (
                <ExpandLess 
                    onClick={() => this.toggleChildren()}
                    style={{width: `${columnWidthMap.openChildren}px`, flex:`${columnWidthMap.openChildren} 1 0`}} />) : (
                <ExpandMore 
                    onClick={() => this.toggleChildren()}
                    style={{width: `${columnWidthMap.openChildren}px`, flex:`${columnWidthMap.openChildren} 1 0`}} />
            ),
            "description": (
                <TextInputCell
                    {...commonProps}
                    listCellType={PlaceholderListCell}
                    ref={this.descriptionCell}
                    listCellProps={{
                        placeholder: this.tr("Topic")
                    }}
                    width={columnWidthMap.description}
                    name="name"
                    value={data.credited_id > 0 ? `(${data.credited_id} - ${this.tr('invoice is refunded')}) : ${data.name}` : data.name}
                    onEdited={this.cellEdited} />
            ),
            is_automatic: (
                <StatusCell 
                    value={null}
                    listCellProps={{className: `statusCell is-automatic ${data.is_automatic < 1 ? 'paused' : ''}`}}
                    clickable={!billed() ? true : false} 
                    width={columnWidthMap.is_automatic}
                    onClick={() => !billed() && rowProps.updateContract({...data, is_automatic: data.is_automatic > 0 ? 0 : 1})} 
                    displayData={{
                        name: data.is_automatic > 0 ? this.tr("Active") :this.tr("Paused"), 
                        color: data.is_automatic > 0 ? '#2d9ff7' : '#a1acb5'}}/>                               
            ),
            is_maventa_target: (
                <CheckboxCell
                    {...commonProps}
                    className={billed() ? 'disabled' : ''}
                    checked={data.is_maventa_target > 0} 
                    width={columnWidthMap.is_maventa_target}
                    name="is_maventa_target" 
                    onEdited={(x) => !billed() && this.checkboxEdited('is_maventa_target', x)} />                               
            ),
            "costs": (
                <ListCell
                    {...commonProps}
                    width={columnWidthMap.costs}
                    onlyDisplay >
                    <div className="costs-option">
                        <Switch
                            name="show_costs_automation"
                            color="primary"
                            disableRipple={true}
                            onChange={() => this.props.toggleCostsView()}
                            checked={this.props.showCosts > 0 || hasInvoiceableCosts}
                            disabled={invoicableCosts.visibility.every(i => !Object.values(i)[0])}
                        />
                    </div>
                    {this.props.showCosts && (
                            <CostsAutomation
                                {...commonProps}
                                costChargeData={invoicableCosts}
                                edit={this.setInvoicableCosts}
                                editable={!billed()}
                                onClose={() => this.props.toggleCostsView()}
                            />
                        )
                    }
                </ListCell>
            ),           
            date: (
                <DateCell
                    {...commonProps}
                    offsetCalendar={true}
                    width={columnWidthMap.date}
                    value={data.date}
                    onEdited={(n, date) => this.cellEdited("date", date)} />                                
            ),            
            "duedate": (
                <DateCell
                    {...commonProps}
                    width={columnWidthMap.duedate}
                    name="invoice_date"
                    value={data.invoice_date}
                    onEdited={(n, date) => this.cellEdited("invoice_date", date)} />
            ),
            "duedate_repeat_increment": (
                <TextInputCell
                    {...commonProps}
                    width={columnWidthMap.duedate_repeat_increment}
                    listCellProps={{className: 'recurring'}}
                    textAlign="center"
                    name="duedate_repeat_increment"
                    value={data.duedate_repeat_increment}
                    onEdited={this.cellEdited} />
            ),            
            "duedate_repeat": (
                <AutoCompleteCell
                    {...commonProps}
                    width={columnWidthMap.duedate_repeat}
                    autoCompleteData={sharedData.duedate_repeat}
                    value={data.duedate_repeat}
                    onEdited={option => this.cellEdited("duedate_repeat", option.id)} />
            ),
            "period_start": (
                <DateCell
                    {...commonProps}
                    offsetCalendar={true}
                    width={columnWidthMap.period_start}
                    value={data.period_start}
                    onEdited={(n, date) => this.cellEdited("period_start", date)} />
            ),
            "period_end": (
                <DateCell
                    {...commonProps}
                    offsetCalendar={true}
                    width={columnWidthMap.period_end}
                    value={data.period_end}
                    onEdited={(n, date) => this.cellEdited("period_end", date)} />
            ),            
            "until": (
                <DateCell
                    {...commonProps}
                    offsetCalendar={true}
                    width={columnWidthMap.until}
                    name="period_end"
                    value={data.until}
                    onEdited={(n, date) => this.cellEdited("until", date)} />
            ),            
            "billing_zone": (
                <AutoCompleteCell
                    {...commonProps}
                    width={columnWidthMap.billing_zone}
                    autoCompleteData={sharedData.billing_zone}
                    value={data.billing_zone}
                    onEdited={option => this.cellEdited("billing_zone", option.id)} />
            ),
            "product_register_id": (
                rowProps.selectedType == 2 ?
                null :                 
                <ListCell
                    {...commonProps}
                    width={columnWidthMap.product_register_id}
                    editable={false}
                    value={null} />
            ),            
            "invoiced": (
                billed() ?
                <StatusCell 
                    value={null}
                    listCellProps={{className: 'statusCell'}} 
                    width={columnWidthMap.invoiced}
                    clickable
                    urlHandler={value => `index.html?module=invoices&action=view&id=${data.bills_id}`} 
                    displayData={{
                            name: data.bill_id, 
                            color: '#2d9ff7'}}/> 
                :
                <StatusCell 
                    value={null}
                    listCellProps={{className: 'statusCell'}} 
                    width={columnWidthMap.invoiced} 
                    displayData={{
                        name: this.tr("Uninvoiced"), 
                        color: '#ffb822'}}/>              
            ),
            "quantity": (               
                <TextInputCell
                    {...commonProps}
                    editable={false}
                    textAlign="right"
                    width={columnWidthMap.quantity}
                    name="quantity"
                    value={data.quantity}
                    onEdited={this.cellEdited} />
            ),
            "value": (               
                <TextInputCell
                    {...commonProps}
                    editable={false}
                    width={columnWidthMap.value}
                    name="value"
                    value={data.value}
                    onEdited={this.cellEdited} />
            ),
            "total_vat0": (
                <ListCell
                    {...commonProps}
                    editable={false}
                    textAlign="right"
                    width={columnWidthMap.total_vat0}
                    name="total_vat0"
                    value={currencyFormatter(data.total_no_vat)} />
            ),
            "vat": (                
                <TextInputCell
                    {...commonProps}
                    editable={false}
                    width={columnWidthMap.vat}
                    name="vat"
                    value={formatInputNumber(data.vat)}
                    onEdited={this.cellEdited} />
            ),
            "total": (
                <ListCell
                    {...commonProps}
                    editable={false}
                    textAlign="right"
                    width={columnWidthMap.total}
                    value={currencyFormatter(data.total)} />
            ),
            "accounting": (
                <ListCell
                    {...commonProps}
                    width={columnWidthMap.accounting}
                    onlyDisplay
                    className="accounting-cell"
                >
                    {this.renderAccountingButton(commonProps.editable)}
                    {
                        this.props.showAccounting && <AccountingSlider
                            type="sales_invoices"
                            invoiceRows={rows}
                            dataRows={rows}
                            onSave={(data) => this.onAccountingSave(data)}
                            accountingData={accountingData}                            
                            company={rowProps.project?.companies_id}
                            open={true}
                            onClose={(data) => this.onAccountingClose(data) }
                            currency={currency}
                            editingDisabled={!commonProps.editable}
                            editRow={(field, value, rowId) => {!billed() && this.accountingCellEdited(field, value, rowId)}}
                            onRowsCopied={(rows, callback) => {!billed() && this.onAccountingRowsCopied(rows, callback)}}
                        />}        
                </ListCell>
            )
        };

        return (
            <React.Fragment>
                <div className={`row listElement contract-parent-row ${(rowProps.rowCount.findIndex(r => r == data.id) % 2 == 0) ? 'odd' : 'even'}`} style={{
                    height: "44px",
                    lineHeight: "44px",
                    display: "flex"
                }}>
                    {columnOrder.map(columnName => cells[columnName])}
                </div>
                {/*this.state.showRecurrenceDialog && (
                    <Portal>
                        <ResourceRecurrenceDialog 
                            onSave={(rule) => this.recurrenceSave(rule)}
                            rrule={''}
                            onClose={() => this.setState({showRecurrenceDialog: false})}
                        />
                    </Portal>  
                )*/}
            </React.Fragment>             
        );
    }
}


class ContractChildRow extends PropsOnlyListRow {
    
    static contextType = SettingsContext;
    
    constructor(props) {
        super(props, {}, {}, "projects/TabInvoicing");

        this.descriptionCell = React.createRef();
        this.productCell = React.createRef();
        this.cpqCell = React.createRef();

    }

    shouldComponentUpdate(nextProps, nextState) {
        return super.shouldComponentUpdate(nextProps, nextState) || !lodash_isEqual(nextProps.data, this.props.data) || !lodash_isEqual(nextProps.accountingData, this.props.accountingData);
    }    

    cellEdited(name, value) {

        if (!value || this.props.data[name] == value)
            return;

        const data = {...this.props.data};


        // if (data.id < 0 && this.props.data.id > 0)
        //     data.id = this.props.data.id;

        if (name == "quantity" || name == "value" || name == "vat") 
            value = value.replace(',', '.');

        data[name] = value;

        this.props.rowProps.updateContractRow(data, value);
    }

    productCellEdited = async (product) => {

        const {data, defaultDimensionValues, dimensionHeaders} = this.props;
        let newData = {};

        if (product.balance) {
            const discountAmount = CurrencyUtils.calculateDiscountAmount(product.income_price, product.discount_percent, 2);

            const realPrice = (parseFloat(product.income_price) - parseFloat(discountAmount));
            newData = {
                value: realPrice,
                vat: product.vat,
                description: product.name,
                product_register_id: product.id            
            }
        } else {
            const result = await DataHandler.get({ url: `cpq/parent`, parentId: product.id });
            const cpqVat = product.vat > 0 ? product.vat : data.vat;
            newData = {
                cpq_id: product.id,
                description: result.CPQ.description,
                value: result.CPQ.income_price, 
                vat: cpqVat
            }
        }

        let updateAccounting = false;
        if (Number(product.accounting_accounts_id)) {
            newData.sales_account = product.accounting_accounts_id;
            updateAccounting = true;
        }
        if (Number(product.accounting_dimensions_id)) {
            newData.dimension_item = product.accounting_dimensions_id;
            updateAccounting = true;
        }
        if ((product.dimension_values || []).length > 0) {
            newData.dimension_values = getProductDimensionValuesForHeaders(product.dimension_values, defaultDimensionValues, dimensionHeaders);
            updateAccounting = true;
        }

        this.props.rowProps.updateContractRow({...data, ...newData}, product.id, updateAccounting);
    }

    checkboxEdited(name, val) {
        const data = {...this.props.data};
        const value = val ? 1 : 0;
        data[name] = value;

        this.setState({[name]: val});
        this.props.rowProps.updateContractRow(data);
    }    

    componentDidMount() {
        super.componentDidMount();
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state.bill_once && this.state.bill_once !== nextProps.data.bill_once)
    //         this.setState({bill_once: nextProps.data.bill_once});
    //     return true;
    // }  

    render() {
        const { taimerAccount, versionId } = this.context;
        const { data, columnOrder, columnWidthMap, sharedData, rowProps, parentData, accountingData, openAccountingSlider } = this.props;
        const { currency } = rowProps;

        const props = new Proxy({}, {
            get: (obj, prop) => { return {key: prop, "className": `cell ${prop}`, style: {width: columnWidthMap[prop] + 'px', flex: columnWidthMap[prop] + " 1 0" }} }
        });

        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency
        }).format;

        const dateFormat = new Intl.DateTimeFormat(taimerAccount.numberFormat).format;

        const parentBilled = () => {
            if (parentData.bills_id > 0 && parentData.credited_id < 1)
                return true;
            else
                return false;
        }

        const commonProps = {
            editable: rowProps.editable && parentBilled() ? false : true,
        }

        const getWidthsSum = (array) => {
            const cw = {...columnWidthMap};
            let sum = 0;
            array.map(a => sum += cw[a] || 0);
            return sum;
        };

        const rowType = () => {
            if (data.isProductRow || (data.product_register_id > 0 || data.product_register_id == -1))
                return 'product';
            else if (data.isCPQRow || (data.cpq_id > 0 || data.cpq_id == -1))
                return 'cpq';
            else
                return 'default';
        }


        const isProductRow = data.isProductRow || (data.product_register_id > 0 || data.product_register_id == -1);
        let productName = "";
        if (isProductRow && rowProps.products && data.product_register_id) {
            const product = rowProps.products.find(p => p.id === data.product_register_id);
            productName = product && product.name ? product.name : "";
        }

        const contextStyle = /*!parentBilled() ? { flex: "0 0 0" } : */{width: `${columnWidthMap.context}px`, flex:`${columnWidthMap.context} 1 0`};

        let descriotionComp;
        let descriotionCompWidth = getWidthsSum(['duedate', 'duedate_repeat', 'duedate_repeat_increment', 'period_start', 'period_end', 'product_register_id','until']);

        if (rowProps.hasMaventa)
            descriotionCompWidth = descriotionCompWidth + columnWidthMap['is_maventa_target'];
        if (isProductRow)
            descriotionComp = 
            <InfoSelect
                key="description"
                ref={this.productCell}
                {...commonProps}
                value={this.state.productName || productName}
                height={230}
                tableWidth={550}
                headerHeight={35}
                rowHeight={49}
                placeholder={productName ? this.state.productName || productName : this.tr('Select product')}
                className="is_tabinvoicing"
                options={rowProps.products ? rowProps.products.filter(e => Number(e.deleted) === 0) : []}
                width={descriotionCompWidth}
                columns={[
                    { name: "code", header: this.tr("Code"), width: 75 },
                    { name: "name", header: this.tr("Name"), width: 200 },
                    { name: "path", header: this.tr("Category"), width: 200 },
                    { name: "unit", header: this.tr("Unit"), width: 75 },
                ]}
                zeroBasis={true}
                onChange={data => this.productCellEdited(data)}
                disableOnBlur={true}
            />
        else if (data.isCPQRow || (data.cpq_id > 0 || data.cpq_id == -1))
            descriotionComp = 
            <AutoCompleteCell
                key="description"
                {...commonProps}
                hideSelectedOptions
                ref={this.productCell}
                width={descriotionCompWidth}
                name="product_register_id"
                allowCreate={false}
                value={this.state.cpq || data.cpq_id}
                autoCompleteData={rowType() == 'product' ? rowProps.products : rowProps.cpqs}
                searchable={true}
                listCellProps={{
                    zeroBasis: true 
                }}
                //listCellProps={{inEditMode: editMode}}
                onEdited={data => this.productCellEdited(data)} /> 
        else
            descriotionComp = 
            <ListCell
                key="description"
                {...commonProps}
                placeholderOnEmpty
                ref={this.productCell}
                className="productcell-placeholder"
                placeholder={this.tr('cost row')}
                width={descriotionCompWidth}
                editable={false}
                zeroBasis={true}
                value={null} />;
        
        const getTotal = (noVat) => {
            const quantity = data.quantity;
            const value = data.value;
            const vat = data.vat



            if (parentBilled()) 
                return currencyFormatter(noVat ? data.total_no_vat : data.total); 
            else
                return currencyFormatter(noVat ? quantity*value : quantity * value * (1 + (vat / 100)));
        }

        const cells = {
            "context": (<div key="context" className="disabled-div" style={contextStyle} />),
            "openChildren": !parentBilled() ? (
                <ListCell key="openChildren" tabIndex="1" onlyDisplay={true} style={{width: `${columnWidthMap.openChildren}px`, flex:`${columnWidthMap.openChildren} 1 0`}} >   
                    {parentBilled() ? null : <DeleteIcon
                        onClick={() => parentBilled() ? false : this.delete(data)} 
                    />}
                </ListCell>
            ) : (<div key="openChildren" className="disabled-div" style={{width: `${columnWidthMap.openChildren}px`, flex:`${columnWidthMap.openChildren} 1 0`}} />),
            "duedate": (
                descriotionComp
            ),                           
            description: (
                <CheckboxCell
                    key="desc"
                    className={parentBilled() ? 'disabled' : ''}
                    checked={this.state.bill_once || data.bill_once > 0} 
                    width={columnWidthMap.description}
                    name="bill_once" 
                    listCellProps={{
                        zeroBasis: true,
                        useFlex: true 
                    }}
                    onEdited={(checked) => !parentBilled() && this.checkboxEdited('bill_once', checked)} />
            ),
            "product_register_id": (
                null
            ),            
            "is_automatic": (
                <TextInputCell
                    key="is_automatic"
                    {...commonProps}
                    ref={this.descriptionCell}
                    listCellType={PlaceholderListCell}
                    listCellProps={{
                        placeholder: (data.isProductRow || data.isCPQRow) || this.tr('billing row'),
                        style: {
                            fontStyle: "normal"
                        },
                        zeroBasis: true,
                        useFlex: true
                    }}
                    width={getWidthsSum(['is_automatic', 'costs', 'invoiced', 'date'])}
                    name="description"
                    value={data.description}
                    onEdited={this.cellEdited} />
            ),
            "quantity": (
                <TextInputCell
                    key="quantity"
                    {...commonProps}
                    textAlign="right"
                    width={columnWidthMap.quantity}
                    name="quantity"
                    value={data.quantity}
                    onEdited={this.cellEdited}
                    listCellProps={{
                        zeroBasis: true,
                        useFlex: true                   
                    }} />
            ),
            "value": (
                <TextInputCell
                    key="value"
                    {...commonProps}
                    textAlign="right"
                    listCellType={CurrencyListCell}
                    listCellProps={{
                        currency: currency,
                        zeroBasis: true,
                        useFlex: true
                    }}
                    width={columnWidthMap.value}
                    name="value"
                    value={parseFloat(data.value).toFixed(2)}
                    onEdited={this.cellEdited} />
            ),
            "total_vat0": (
                <ListCell
                    key="total_vat0"
                    {...commonProps}
                    editable={false}
                    textAlign="right"
                    zeroBasis
                    style={{flexGrow: columnWidthMap.total_vat0, flexShrink: 1}}
                    width={columnWidthMap.total_vat0}
                    name="total_vat0"
                    value={getTotal(true)} />
            ),
            "vat": (
                <TextInputCell
                    key="vat"
                    {...commonProps}
                    textAlign="right"
                    width={columnWidthMap.vat}
                    name="vat"
                    value={formatInputNumber(this.state.vat)|| formatInputNumber(data.vat)}
                    onEdited={this.cellEdited} 
                    listCellProps={{ zeroBasis: true, useFlex: true}} />
            ),
            "total": (
                <ListCell
                    key="total"
                    {...commonProps}
                    editable={false}
                    textAlign="right"
                    zeroBasis
                    style={{flexGrow: columnWidthMap.total, flexShrink: 1}}
                    width={columnWidthMap.total}
                    value={getTotal()} />
            ),
            "accounting": (
                <AccountingCell
                    listCellProps={{
                        width: columnWidthMap.accounting,
                        textAlign: "center",
                        innerStyle: { textAlign: "center" },
                        className: "accounting-cell"
                    }}
                    type="sales_invoices"
                    rowData={data}
                    accountingData={accountingData}
                    showAccounting={openAccountingSlider} />
            ),
        };

        return (
            <div className="row listElement contract-child-row" style={{
                height: "44px",
                lineHeight: "44px",
                display: "flex"
            }}>
                {columnOrder.map(columnName => cells[columnName])}
            </div>
        );
    }

}


class InvoicesRow extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/TabInvoices");

        this.invoiceRow = React.createRef();
        this.contractParentRow = React.createRef();

        this.state = {
            showAccounting: false,
            defaultDimensionValues: [],
            dimensionHeaders: [],
            dimensionHeadersDiffer: false,
            defaultDimensionsFetched: false
        }

        this.contractChildRowReferences = [];
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.defaultDimensionsFetched && this.props.rowProps?.accountingDataFetched && this.props.rowProps?.initialFetchDone && this.props.rowProps?.integrationSettingsFetched) {
            this.getDefaultMultiDimensions();
        }
    }

    openAccountingSlider = () => {
        this.setState({showAccounting: true});
    }

    closeAccountingSlider = () => {
        this.setState({showAccounting: false});
    }


    toggleCostsView = () => {
        const {showCosts} = this.state;
        this.setState({showCosts: !showCosts});
    }

    /**
    * Gets default dimensions for multidimension headers. For headers that are shown in slider.
    * Gets info if rows order number dimension headers differ from settings order number headers.
    */
    getDefaultMultiDimensions = () => {
        const { data, sharedData, rowProps: { accountingData } } = this.props;
        const contractRows = sharedData.contractrows.filter(cr => cr.parentid == data.id) || [];

        const props = {
            isNewInvoice: false,
            accountingData: accountingData || [],
            invoiceRows: cloneDeep(contractRows),
            type: "sales_invoices"
        }
        const dimensionHeaderData = getMultiDimensionHeaders(props);
        const { dimensionHeaders, dimensionHeadersDiffer } = dimensionHeaderData;
        let defaultDimensionValues = getDefaultDimensionValuesForHeaders(dimensionHeaders);
        defaultDimensionValues = this.mergeProjectDefaultDimensioValues(dimensionHeaders, defaultDimensionValues);

        this.setState({ defaultDimensionsFetched: true, defaultDimensionValues, dimensionHeadersDiffer, dimensionHeaders });
    }

    updateDimensionHeadersDiffer = (data) => {
        const { dimensionHeadersDiffer, dimensionHeaders } = data;
        let defaultDimensionValues = getDefaultDimensionValuesForHeaders(dimensionHeaders);
        defaultDimensionValues = this.mergeProjectDefaultDimensioValues(dimensionHeaders, defaultDimensionValues);

        this.setState({ dimensionHeadersDiffer, dimensionHeaders, defaultDimensionValues });
    }

    mergeProjectDefaultDimensioValues = (dimensionHeaders, defaultDimensionValues) => {
        const { addons } = this.context;
        const { rowProps: { project, integrationSettings } } = this.props;

        let projectDimension = null;
        if (addons['procountor'] && addons['procountor'].used_by_companies.indexOf(project.companies_id) > -1) {
            projectDimension = getProcountorProjectDimension(project?.id, integrationSettings, dimensionHeaders);
        }

        return !projectDimension 
            ? defaultDimensionValues
            : updateDimensionValues(defaultDimensionValues, projectDimension);
    }

    render = () => {

        const { data, sharedData, rowProps } = this.props;
        const contractRows = sharedData.contractrows.filter(cr => cr.parentid == data.id);

        const getWidthsSum = (array) => {
            const cw = {...this.props.columnWidthMap};
            let sum = 0;
            array.map(a => sum += cw[a] || 0);
            return sum;
        };

        const subHeaderProps = (columns) => {
            return {
                className: "column",
                style: {width: `${getWidthsSum(columns)}px`, flexGrow:`${getWidthsSum(columns)}`, flexShrink: 1 }
            }
        }

        const billed = () => {
            if (data.bills_id > 0 && (!data.credited_id || data.credited_id < 1))
                return true;
            else
                return false;
        }

        let visibleChildren = [];

        if (data.rowtype == 'contract') {
            visibleChildren = rowProps.expandedRows.includes(data.id) ? contractRows.filter(rc => rc.parentid == data.id) : [];
            if (visibleChildren.length > 0)
                visibleChildren.map((v, i) => this.contractChildRowReferences[i] = React.createRef());
        }

        return (
            <React.Fragment>
                {data.rowtype == 'invoicing' && <InvoiceRow 
                    key={rowProps.autoCompleteDataFetched} 
                    {...this.props} 
                    ref={this.invoiceRow}/>}
                {data.rowtype == 'contract' && <ContractParentRow 
                    {...this.props} 
                    key={data.id + this.state.showAccounting + this.state.showCosts} 
                    ref={this.contractParentRow}
                    usesState 
                    rows={contractRows} 
                    showAccounting={this.state.showAccounting}
                    showCosts={this.state.showCosts} 
                    accountingData={rowProps.accountingData}
                    openAccountingSlider={() => this.openAccountingSlider()}
                    closeAccountingSlider={() => this.closeAccountingSlider()}
                    setContractRows={rowProps.setContractRows}
                    defaultDimensionValues={this.state.defaultDimensionValues}
                    dimensionHeadersDiffer={this.state.dimensionHeadersDiffer}
                    updateDimensionHeadersDiffer={this.updateDimensionHeadersDiffer}
                    toggleCostsView={() => this.toggleCostsView()} /> }
                {data.rowtype == 'contract' && rowProps.expandedRows.includes(data.id) && contractRows.some(r => r.parentid == data.id) && 
                    <div className="subHeader">
                        <div {...subHeaderProps(['context'])} />
                        <div {...subHeaderProps(['openChildren'])} />
                        <div {...subHeaderProps(['description'])} >{this.tr('Bill once')}</div>
                        <div {...subHeaderProps(['is_automatic', 'costs', 'invoiced', 'date'])} >{this.tr('Description for row')}</div>
                        <div {...subHeaderProps([...['duedate', 'duedate_repeat', 'duedate_repeat_increment', 'period_start', 'period_end', 'product_register_id','until'], ...(rowProps.hasMaventa ? ['is_maventa_target'] : []) ])} >{this.tr('Row/Product')}</div>
                        <div {...subHeaderProps(['quantity'])} className="align-right" >{this.tr('Quantity')}</div>
                        <div {...subHeaderProps(['value'])} className="align-right" >{this.tr('Amount')}</div>
                        <div {...subHeaderProps(['total_vat0'])} className="align-right" >{this.tr('Total vat 0%')}</div>
                        <div {...subHeaderProps(['vat'])} className="align-right" >{this.tr('Vat')}</div>
                        <div {...subHeaderProps(['total'])} className="align-right" >{this.tr('Inc Vat %')}</div>
                        <div {...subHeaderProps(['accounting'])} className="align-right" >&nbsp;</div>
                    </div>
                }
                {data.rowtype == 'contract' && visibleChildren.map((cr, i, arr) =>  {
                        return <ContractChildRow 
                                    {...this.props} 
                                    key={cr.id}
                                    name='contractChildRow'
                                    usesState 
                                    accountingData={rowProps.accountingData}
                                    openAccountingSlider={this.openAccountingSlider}
                                    data={cr} 
                                    parentData={data} 
                                    defaultDimensionValues={this.state.defaultDimensionValues}
                                    dimensionHeaders={this.state.dimensionHeaders}
                                    ref={this.contractChildRowReferences[i]} />
                    }                  
                )}
                {rowProps.editable && data.rowtype == 'contract' && rowProps.expandedRows.includes(data.id) &&
                    <div className="action-buttons-row" style={{paddingLeft: "16px"}}>
                        <div {...subHeaderProps(['context', 'openChildren','rowtype', 'is_automatic', 'date', 'duedate'])} >
                            <div className={`action-button ${billed() ? 'disabled' : ''}`} onMouseUp={() => !billed() && rowProps.onContractRowAdd(data.id, false, false, this.state.defaultDimensionValues)}>
                                <span className="add-icon"><AddIcon/></span>
                                {this.tr('Cost')}
                            </div> 
                            <div className={`action-button ${billed() ? 'disabled' : ''}`} onMouseUp={() => !billed() && rowProps.onContractRowAdd(data.id, true, false, this.state.defaultDimensionValues)}>
                                <span className="add-icon"><AddIcon/></span>
                                {this.tr('Product')}
                            </div>
                            <div className={`action-button ${billed() ? 'disabled' : ''}`} onMouseUp={() => !billed() && rowProps.onContractRowAdd(data.id, false, true, this.state.defaultDimensionValues)}>
                                <span className="add-icon"><AddIcon/></span>
                                {this.tr('CPQ')}
                            </div>                                                                          
                        </div>
                        <div {...subHeaderProps(['product_register_id', 'description', 'duedate_repeat_increment', 
                            'duedate_repeat', 'period_start', 'period_end', 'until', 'invoiced', 'quantity',
                            'value', 'total_vat0', 'vat', 'total'])} ></div>
                    </div>                 
                }
                {rowProps.editable && data.rowtype == 'invoicing' && (data.id == rowProps.lastInvoiceRowId || !rowProps.lastInvoiceRowId) &&
                    <div className="action-buttons-row" style={{paddingLeft: "22px"}} >
                        <div {...subHeaderProps(['context', 'duedate', 'duedate_repeat_increment', 'duedate_repeat'])} >
                            <div className={`action-button`} onMouseUp={() => rowProps.onInvoicingRowAdd()}>
                                <span className="add-icon"><AddIcon/></span>
                                {this.tr('Cost')}
                            </div> 
                            <div className={`action-button`} onMouseUp={() => rowProps.onInvoicingRowAdd(true)}>
                                <span className="add-icon"><AddIcon/></span>
                                {this.tr('Product')}
                            </div>
                            <div className={`action-button`} onMouseUp={() => rowProps.onInvoicingRowAdd(false, true)}>
                                <span className="add-icon"><AddIcon/></span>
                                {this.tr('CPQ')}
                            </div>                                                                          
                        </div>
                        <div {...subHeaderProps(['product_register_id', 'description',
                            'period_start', 'period_end', 'until', 'invoiced', 'quantity',
                            'value', 'total_vat0', 'vat', 'total'])} ></div>
                    </div>
                }                

            </React.Fragment>
        );
    }
}

class TabInvoicing extends TaimerComponent {
    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "projects/TabInvoicing");
        this.list = React.createRef();

        this.state = {
            products: [],
            cpqs: [],
            statistics: {},
            invoicing: [],
            contractrows: [],
            expandedRows: [],
            selectedStatus: undefined,           
            newRowType: 'newInvoicingRow',
            currency: "EUR",
            addingContract: false,
            addMenuAnchor: undefined,
            initialFetchDone: false,
            accountingDataFetched: false,
            integrationSettings: {},
            integrationSettingsFetched: false
        }

        this.options = {
            duedate_repeat: [
                {id: "0", name: this.tr("No repeat")},
                {id: "1", name: this.tr("Every month")},
                {id: "2", name: this.tr("Every week")},
                {id: "3", name: this.tr("Every day")},
            ],
            billing_zone: [
                {id: "FI", name: "FI"},
                {id: "EU", name: "EU"},
                {id: "NON EU", name: "NON EU"},
            ]
        }

        this.filterOptions = {
            statuses: [
                {id: 0, label: this.tr('all')}, 
                {id: 1, label: this.tr('Invoiced'), name: this.tr('Invoiced')}, 
                {id: 2, label: this.tr('Uninvoiced'), name: this.tr('Uninvoiced')}
            ],
            types: [
                {id: 0, label: this.tr('all')}, 
                {id: 1, label: this.tr('scheduled'), name: this.tr('scheduled')}, 
                {id: 2, label: this.tr('automatic'), name: this.tr('automatic')}
            ],
        }        

        this.newInvoicingRow = {
            duedate: format(new Date(), "YYYY-MM-DD"),
            duedate_repeat_increment: 0,
            duedate_repeat: "0",
            until: "",
            billing_zone: "",
            product_register_id: 0,
            cpq_id: "0",
            description: "",
            invoiced: "",
            quantity: 1,
            value: 0,
            total_no_vat: 0.00,
            vat: this.context.taimerAccount.defaultVat || 0,
            total: "",
            rowtype: 'invoicing',
        };

        this.newContractParentRow = {
            id: -1,
            bill_id: 0,
            billing_zone: "",
            bills_id: "0",
            date: format(new Date(), "YYYY-MM-DD"),
            duedate: "",
            duedate_repeat: 0,
            duedate_repeat_increment: 0,
            invoice_date: format(new Date(), "YYYY-MM-DD"),
            is_automatic: 0,
            is_maventa_target: "0",
            name: "",
            period_end: format(endOfMonth(new Date()), "YYYY-MM-DD"),
            period_start: format(startOfMonth(new Date()), "YYYY-MM-DD"),
            projects_id: props.id,
            rowtype: "contract",
            until: "",
        };

        this.newContractChildRow = {
            bill_once: "0",
            description: "",
            parentid: 0,
            product_register_id: "0",
            cpq_id: "0",
            quantity: 1,
            roworder: 0,
            value: 0.00,
            vat: this.context.taimerAccount.defaultVat || 0,
            venda_id: '0',
            rowtype: 'contractrow',
        };

        this.invoiceColumnsMap = {
            description: 'name',
            total_vat0: "total_no_vat",
            invoiced: "bill_id",
        }


        this.dialogs = {
            confirmation: ConfirmationDialog
        };

    }
    updateComponentData = async (extra = {}) => {
        const { id, selectedType } = this.props;
        const { selectedStatus } = this.state;

        DataHandler.get({url: `invoices/accounting/${this.props.project.companies_id}`, projectId: this.props.id}).done(
            data => {
                this.setState({
                    accountingData: {
                        salesAccounts: data.salesAccounts,
                        dimensions: data.dimensions,
                        accountingProducts: data.accountingProducts,
                        efinaProducts: data.efinaProducts,
                        efinaCostPools: data.efinaCostPools,
                        productRegisterProducts: data.productRegisterProducts,
                        dimension_headers: data.dimension_headers
                    },
                    accountingDataFetched: true
                });
            }
        );

        return await DataHandler.get({url: `projects/${id}/invoicing`, type: selectedType, status: selectedStatus, ...extra}).done(async (data) => {
            this.setState({...data, initialFetchDone: true})     
            return data;
        });       
    }

    updateAutocompleteData = async () => {
        const data = {...(await DataHandler.get({ url: `/cpq/parents/${this.props.project.companies_id}`})), ...(await DataHandler.get({ url: `/products/array/${this.props.project.companies_id}`, include_deleted: 1, account: this.props.project.customers_id}))};
        this.setState({products: data.products, cpqs: data.CPQParents, autoCompleteDataFetched: true});        
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateComponentData();
        this.updateAutocompleteData();
        this.getJobtypes(this.props.id);   
        this.getIntegrationSettings();

        DataHandler.get({ url: `/products/array/${this.props.project.companies_id}`, include_deleted: 1, account: this.props.project.customers_id}).done(data => {
            this.setState(data);
        });
        this.tabRef = ReactDOM.findDOMNode(this);
    }

    getIntegrationSettings = () => {
        const { addons } = this.context;
        const company = this.props.project?.companies_id;
        const integrationSettings = {};

        if (addons['procountor'] && addons['procountor'].used_by_companies.indexOf(company) > -1) {
            const settings = [
                'add_project_dimension_automatically',
                'use_parent_project_dimension_for_subprojects',
                'project_dimension',
                'project_parent_data'
            ];
            DataHandler.post({ url: `/integrations/get_settings`}, { settings, company, integration: "procountor" }).done(integrationSettings => {
                this.setState({ integrationSettings, integrationSettingsFetched: true });
            });
        }
        else {
            this.setState({ integrationSettings, integrationSettingsFetched: true });    
        }    
    }

    getJobtypes = async (projectId) => {
        const jobtypes = await getWorktypesForProject(projectId);
        this.setState({
            jobtypes: jobtypes,
        });
    }

    addInvoicing = async (isProductRow, isCPQRow) => {

        const {newInvoicingRow} = this;
        const newRow = {...newInvoicingRow, isProductRow: isProductRow, isCPQRow: isCPQRow};

        const newIds = await this.list.current.addNewRow(newRow);

        const referenceRow = this.list.current.listElementReferences.length == 1 ? this.list.current.listElementReferences[0] : this.list.current.listElementReferences.reduce((acc, o) => (Number(o.current?.props?.data?.id) || 0) < (Number(acc.current?.props?.data?.id) || 0) ? o : acc);
        const productCellComponent = referenceRow.current.invoiceRow.current.productCell.current;
        try {
            if (isProductRow)
                productCellComponent.setState({anchorEl: ReactDOM.findDOMNode(productCellComponent), open: true});
            else if (isCPQRow)  {
                productCellComponent.listCell.current.openEdit();
                productCellComponent.autoComplete.current.setState({menuIsOpen: true});
            }
            else 
                this.list.current.listElementReferences[0].current.invoiceRow.current.descriptionCell.current.listCell.current.openEdit();
        } catch(e) {
            console.error(e);
        }

    }   

    onDelete = (data) => {
        if (data.id < 0)
            data.rowtype == 'contractrow' ? 
                this.setState(state => {
                    state.contractrows = state.contractrows.filter(ct => ct.id != data.id);
                    return state;
                }) : 
                this.list.current.removeNewRow(data.id);
        else
            DataHandler.delete({url: `projects/${this.props.id}/${data.rowtype}/${data.id}`}).done(() => this.updateComponentData({ refresh: 1 }));       
    }

    onExpand = (id, show) => {
        let {expandedRows} = this.state;
        expandedRows = show ? [...expandedRows, id] : expandedRows.filter(r => r != id);
        this.setState({expandedRows: expandedRows});
    }

    addContract = async () => {
        this.setState({addingContract: true});
        const updateContractAccounting = await this.updateContract(this.newContractParentRow);
        
        try {
            this.list.current.listElementReferences[0].current.contractChildRowReferences[0].current.descriptionCell.current.listCell.current.openEdit();
        } catch(e) {
            console.error(e);
        }
        this.setState({addingContract: false});
    }

    updateContract = async (data, noExpand) => {
        return await DataHandler.post({url: `projects/${data.id}/${data.rowtype}`}, {data: data}).then(async (resp) => {
            !noExpand && this.setState(state => {
                state.expandedRows = [...state.expandedRows, resp];
                return state;
            });            
            return await this.updateComponentData({ refresh: 1 });
        }).done((x) => x);
   
    }

    updateContractAccounting = (projectsId, data) => {
        DataHandler.put({url: `projects/${projectsId}/contractrow_accounting`}, {data: data}).done((resp) => {
            this.setState(state => {
                state.expandedRows = [...state.expandedRows, resp];
                return state;
            });            
            this.updateComponentData({ refresh: 1 });
        })        
    }

    copyContract = (id) => {
        DataHandler.get({url: `projects/${id}/copy_contract`}).done((resp) => {
            this.setState(state => {
                state.expandedRows = [...state.expandedRows, resp];
                return state;
            });            
            this.updateComponentData({ refresh: 1 });
        })        
    }            

    onContractRowAdd = (id, isProductRow, isCPQRow, defaultDimensionValues) => {

        const {newContractChildRow} = this;

        const nextRowOrder = Math.max(...this.state.contractrows.filter(cr => cr.parentid == id).map(crr => crr.roworder), 1) + 1;
        const newRow = {...newContractChildRow, 
            id: (nextRowOrder*-1), 
            parentid: id, 
            roworder: nextRowOrder, 
            vat: this.context.taimerAccount.defaultVat, 
            isProductRow: isProductRow, 
            isCPQRow: isCPQRow,
            dimension_values: defaultDimensionValues
        };

        const currentRows = cloneDeep(this.state.contractrows);

        this.setState(state => {
            state.contractrows = [...state.contractrows, newRow];
            return state;
        }, async () => {
            const newRowId = await this.updateContractRow(newRow);

            const accountingUpdateRow = {...newRow, id: newRowId}
            this.updateContractAccounting(this.props.id, [...currentRows, accountingUpdateRow]);

            let newRowElement;

            const findDeep = (data, id) => {
                return data.map(function(e) {
                    if(e.current && e.current.props.name == 'contractChildRow' && e.current.props.data.id == id) 
                        newRowElement = e;
                    else if(e.current && e.current.contractChildRowReferences) 
                        return findDeep(e.current.contractChildRowReferences, id);    
                })
            }

            findDeep(this.list.current.listElementReferences, newRowId);

            try {
                if (isCPQRow) {
                    newRowElement.current.productCell.current.listCell.current.openEdit();
                    newRowElement.current.productCell.current.autoComplete.current.setState({menuIsOpen: true});
                } else if (isProductRow) {
                    newRowElement.current.productCell.current.setState({ anchorEl: ReactDOM.findDOMNode(newRowElement.current.productCell.current), open: true });
                } else {
                    newRowElement.current.descriptionCell.current.listCell.current.openEdit();
                }
            } catch(e) {
                console.error(e);
            }

        });
    }

    setContractRows = (updatedRows, callback = null) => {
        const contractrows = cloneDeep(this.state.contractrows);
        updatedRows.forEach(u => {
            const i = contractrows.findIndex(r => r.id == u.id);
            contractrows[i] = u;
        });
        this.setState({ contractrows }, () => callback && callback());
    }

    updateContractRow = async (data, changedValue, updateAccounting = false) => {
        const contractrows = cloneDeep(this.state.contractrows);
        const i = contractrows.findIndex(r => r.id == data.id);
        contractrows[i] = data;
        this.setState({ contractrows });

        if (updateAccounting) { 
            this.updateContractAccounting(this.props.id, contractrows);
        }

        return await DataHandler.post({url: `projects/${data.id}/${data.rowtype}`}, {data: data}).then(async (resp) => {
            const update = await this.updateComponentData({refresh: 1});
            return resp.id;
        });
    }

    handleFilterChange = (targetFilter, value) => {
        this.setState({ [`selected${targetFilter}`]: value }, () => {
            this.updateComponentData();
        });        
    }

    recurrenceSave = (rule) => {
    }

    sortRows = (colName, asc) => {

        const sortedData = [...this.state.invoicing];
        sortedData.sort((a, b) => {
            const c = typeof sortedData[0][colName] != 'undefined' ? colName : this.invoiceColumnsMap[colName];
            const A = a[c] || "";
            const B = b[c] || "";
            const isNumber = (!isNaN(parseFloat(A)) && isFinite(A)) || (A === 0 || A === "0");
            if(asc){
                return isNumber ? parseInt(A)-parseInt(B) : A.localeCompare(B);
            } else {
                return isNumber ? parseInt(B)-parseInt(A) : B.localeCompare(A);
            }
        });
        this.setState({invoicing: sortedData});
    }

    createContractBill = (id) => {
        const translations = {
            all: this.tr("all"),
            workhours: this.tr("Workhours"),
            expense: this.tr("Expense"),
            travel_expense: this.tr("Travel expense"),
            daily_allowance_and_mileage: this.tr("daily allowance and mileage"),
            invoice_period: this.tr("Invoice_period")
        };
        DataHandler.get({url: `projects/create_contract_invoice/${id}`, translations: translations}).done(() => {
            this.updateComponentData()            
        });
    }

    openDialog = (name, data) => {
        this.setState({ currentDialog: name, dialogData: data });
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }

    closeDialog = () => {
        this.setState({currentDialog: false, dialogData: undefined});
    }


    openAddMenu = (e) => this.setState({ addMenuAnchor: e.target });
    closeAddMenu = () => this.setState({ addMenuAnchor: undefined });

    isCompanyUsingAccounting = () => {
        const { addons } = this.context;
        const company = this.props.project?.companies_id;
        let usesAccounting = false;
        const accountingAddons = [ "efina", "fivaldi", "netvisor", "procountor", "emce", "talenom", "fennoa", "wintime", "meritaktiva", "heeros"];

        accountingAddons.forEach(a => {
            if (addons[a] && addons[a].used_by_companies.indexOf(company) > -1) {
                usesAccounting = true;
            }
        });

        return usesAccounting;
    }

    render () {
        const { id, checkPrivilege, editable, selectedType } = this.props;
        const { invoicing, contractrows, statistics, products, cpqs, newRowType, expandedRows, selectedStatus, jobtypes, initialFetchDone, accountingDataFetched, integrationSettingsFetched } = this.state;
        const { taimerAccount, versionId, addons } = this.context;
        const hasInvoicing = () =>  {
            if (versionId == 4)
                return true;
            else 
                return addons.invoicing.limit === 0 ? true : false;
        };

        const currency = this.props.currency || taimerAccount.currency || 'EUR';

        const usesAccounting = this.isCompanyUsingAccounting();

        const columns = selectedType === 1 ? 
            [{ name: "context", header: "", columnHeaderType: editable ? "roundButton" : "", width: 60, showMenu: false, resizeable: false, moveable: false, hideable: false, showResizeMarker: false },
            { name: "duedate", header: this.tr("Invoice date"), width: 120 },
            { name: "duedate_repeat_increment", header: this.tr("Recurring"), width: 110 },
            { name: "duedate_repeat", header: this.tr("Interval"), width: 120 },
            { name: "until", header: this.tr("Until"), width: 110 },
            { name: "product_register_id", header: this.tr("Product/CPQ"), width: 170 },
            { name: "description", header: this.tr("Scheduled invoice description"), width: 300 },
            { name: "invoiced", header: this.tr("Invoiced"), width: 120},
            //... this.context.addons.nav ? [{ name: "jobtypes_id", header: this.tr("Jobtype"), width: 170 }] : [],
            { name: "quantity", header: this.tr("Quantity"), showMenu: true, showResizeMarker: true, width: 80, hideable: true },
            { name: "value", header: this.tr("Amount"), showMenu: true, showResizeMarker: true, width: 80, hideable: true },
            { name: "total_vat0", header: this.tr("Total vat 0%"), width: 160 },
            { name: "vat", header: this.tr("Vat %"), showMenu: true, showResizeMarker: true, width: 100, hideable: true },
            { name: "total", header: this.tr("Inc Vat %"), width: (selectedType == 1 ? 160 : 100) }
            ]
                : 
            [{ name: "context", header: "", width: 60, columnHeaderType: editable ? "roundButton" : "", showMenu: false, resizeable: false, moveable: false, hideable: false, showResizeMarker: false },
            { name: "openChildren", header: "", width: 40, showMenu: false, resizeable: false, moveable: false, hideable: false },
            { name: "description", header: this.tr("Automatic invoice description"), width: 300 },
            { name: "is_automatic", header: this.tr("Autom."), width: 180, moveable: false, hideable: false },
            { name: "costs", header: this.tr("Show costs automation"), width: 140 },
            { name: "invoiced", header: this.tr("Invoiced"), width: 160 },
            ...((this.context.addons.maventa || this.context.addons.fennoa || this.context.addons.netvisor || this.context.addons.procountor) && this.context.taimerAccount.use_maventa_targeting == 1 ? [{ name: "is_maventa_target", header: this.tr("Maventa target"), width: 160, moveable: false, hideable: false }] : []),
            { name: "date", header: this.tr("Autom. creation date"), width: 160, moveable: false, hideable: false },
            { name: "duedate", header: this.tr("Invoice date"), width: 160 },
            { name: "period_start", header: this.tr("Period from"), width: 160 },
            { name: "period_end", header: this.tr("Period to"), width: 160 },            
            { name: "duedate_repeat_increment", header: this.tr("Recurring"), width: 110 },
            { name: "duedate_repeat", header: this.tr("Interval"), width: 120 },
            { name: "until", header: this.tr("Until"), width: 160 },
            { name: "quantity", header: false, showMenu: false, showResizeMarker: false, width: 80, hideable: false },
            { name: "value", header:false, showMenu: false, showResizeMarker: false, width: 80, hideable: false },
            { name: "total_vat0", header: this.tr("Total vat 0%"), width: 110 },
            { name: "vat", header: false, showMenu: false, showResizeMarker: false, width: 100, hideable: false },
            { name: "total", header: this.tr("Inc Vat %"), width: 100 },
            ...(usesAccounting ? [{ name: "accounting", header: this.tr("Accounting"), width: 150 }] : []),
            ];

        const getWidthsSum = (array) => {
            const cw = columns.reduce((result, item) => {return {...result, [item.name]: item.width}}, {});
            let sum = 0;
            array.map(a => sum += cw[a] || 0);
            return sum;
        };

        const rowProps = {
            project: this.props.project,
            editable: editable,
            currency: currency,
            checkPrivilege,
            tabRef: this.tabRef,
            listRef: this.list,
            products: products,
            cpqs: cpqs,
            projectsId: this.props.id,
            onDelete: data => this.onDelete(data),
            onInvoicingRowAdd: this.addInvoicing,
            lastInvoiceRowId: invoicing.length > 0 ? invoicing[invoicing.length-1].id : this.list.current?.state.data?.reduce((acc, o) => Number(o.id) < Number(acc.id) ? o : acc, 0)?.id,
            onCreate: data => DataHandler.post({url: `projects/${this.props.id}/invoicing`}, data).done(this.updateComponentData),
            onUpdate: data =>
                data.id > 0 && DataHandler.put({url: `projects/${id}/invoicing/${data.id}`}, data).done(this.updateComponentData),
            onCopy: this.copyContract,
            expandedRows: expandedRows,
            onExpand: this.onExpand,
            rowDefinitions: {invoicing: this.tr('scheduled'), contract: this.tr('automatic'), contractrow: ''},
            updateContract: (data, noExpand) => this.updateContract(data, noExpand),
            onContractRowAdd: (id, isProductRow, isCPQRow, defaultDimensionValues) => this.onContractRowAdd(id, isProductRow, isCPQRow, defaultDimensionValues),
            updateContractRow: (data, changedValue, updateAccounting) => this.updateContractRow(data, changedValue, updateAccounting),
            selectedType: selectedType,
            showRecurrenceDialog: false,
            recurrenceSave: this.recurrenceSave,
            createContractBill : this.createContractBill,
            rowCount: invoicing.map(a => a.id),
            accountingData: this.state.accountingData,
            updateContractAccounting: this.updateContractAccounting,
            onDialogOpen: this.openDialog,
            setContractRows: this.setContractRows,
            autoCompleteDataFetched: this.state.autoCompleteDataFetched,
            canCreateInvoice: this.props.canCreateInvoice,
            accountingDataFetched,
            integrationSettings: this.state.integrationSettings,
            integrationSettingsFetched,
            initialFetchDone,
            hasMaventa: (this.context.addons.maventa || this.context.addons.fennoa || this.context.addons.netvisor) && this.context.taimerAccount.use_maventa_targeting == 1
        }
        const currencyFormatter = new Intl.NumberFormat(taimerAccount.numberFormat, {
            style: 'currency',
            currency: currency
        }).format;

        const newRow = this[newRowType];

        const button = {
            className: 'green',
            stickyIcon: true,
            size: "large"
        }

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;        

        return (
            <div id="projects-invoicing">
                <div className="column">
                    <div className="header-container">
                        <div className="button-container">                       
                            {hasInvoicing() && <OutlinedField
                                className="invoices-filter"
                                select
                                name="type"
                                label={this.tr("By status")}
                                value={selectedStatus}
                                onChange={e => this.handleFilterChange('Status', e.target.value)} >
                                {this.filterOptions.statuses.map(opt => {
                                    return <MenuItem key={opt.id} value={opt.id}>{opt.label}</MenuItem>
                                })}
                            </OutlinedField>}    
                            {this.props.renderNoteToInvoiceCreator && this.props.renderNoteToInvoiceCreator()}                    
                        </div>
                        <div className="header-right">
                            <div className="container">
                                <div className="amount">
                                    <h2>{this.tr('Invoiced')}</h2>
                                    {currencyFormatter(statistics.invoiced)} <span className="subtitle">{this.tr('excl. vat')}</span>
                                </div>
                                <div className="amount">
                                    <h2>{this.tr('To Be Invoiced')}</h2>
                                    {currencyFormatter(statistics.invoiceable)} <span className="subtitle">{this.tr('excl. vat')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <List
                        key={selectedType}
                        ref={this.list}
                        noStateData={true}
                        height="fitRemaining"
                        roundbutton={selectedType > 1 ? this.addContract : this.openAddMenu}
                        roundButtonLoading={this.state.addingContract}
                        trimHeight={-20} 
                        data={invoicing} 
                        columns={columns} 
                        sharedData={{...this.options, contractrows, ...{jobtypes: jobtypes}}} 
                        listRowType={InvoicesRow} 
                        rowProps={rowProps} 
                        newRow={newRow} 
                        userListSettingsKey="tabInvoicing"
                        onSortRows={this.sortRows}  />
                </div>
                {Dialog && <Dialog
                    open
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}
                />}
                <Popover open={!!this.state.addMenuAnchor} anchorEl={this.state.addMenuAnchor} onClose={this.closeAddMenu} 
                    anchorOrigin={{
                        vertical: 35,
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}>
                    <MenuList>
                        <MenuItem onClick={() => {
                            this.closeAddMenu();
                            this.addInvoicing()
                        }}>{this.tr('Cost')}</MenuItem>
                        <MenuItem onClick={() => {
                            this.closeAddMenu();
                            this.addInvoicing(true)
                        }}>{this.tr('Product')}</MenuItem>
                        <MenuItem onClick={() => {
                            this.closeAddMenu();
                            this.addInvoicing(false, true)
                        }}>{this.tr('CPQ')}</MenuItem>
                    </MenuList>
                </Popover>
            </div>
        );
    }
}
TabInvoicing.propTypes = {
    id: PropTypes.string.isRequired,
};

export default TabInvoicing;
