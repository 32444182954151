import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

/* css */
import './InvoiceMaterialPdf.css';

/* material-ui */
import Menu from '@mui/material/Menu';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import ThreeDotsIcon from '@mui/icons-material/MoreHoriz';
import MoveIcon from '@mui/icons-material/UnfoldMore';
import Popover from '@mui/material/Popover';
import MUIList from '@mui/material/List';
import MUIListItem from '@mui/material/ListItem';
import MUIListItemIcon from '@mui/material/ListItemIcon';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { Tooltip } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

/* other */

import TaimerComponent from "../TaimerComponent";
import { SettingsContext } from '../SettingsContext';
import OutlinedField from "../general/OutlinedField";
import { DatePicker, DateRangePicker } from '../general/react-date-range/src';
import DataList from '../general/DataList';
import _, { cloneDeep } from 'lodash';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '../list/List';
import ListRow from "../list/ListRow";
import PropsOnlyListRow from "../list/PropsOnlyListRow";
import ListCell from "../list/ListCell";
import CurrencyListCell from "../list/CurrencyListCell";
import TextInputCell from "../list/cells/TextInputCell";
import CheckboxCell from "../list/cells/CheckboxCell";
import withStyles from '@mui/styles/withStyles';
import ContextMenu from '../general/ContextMenu';

import { format, isValid } from "date-fns";
import { withSnackbar } from 'notistack';

import './../general/react-date-range/src/styles.css';
import './../general/react-date-range/src/theme/default.css';

import './InvoiceMaterial.css';

const COLUMNS_KEY = "invoice_material_pdf_columns";

const styles = theme => ({
    topBar: {
        backgroundColor: '#2D9FF7',
        height: '8px',
        marginBottom: '10px'
    },
    dialogRoot: {
        height: "95%",
    },
    controlsContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    buttonsContainer: {
        textAlign: "right",
    },
    datalistInputRoot: {
        minWidth: "250px"
    },
    optionsDatalistInputRoot: {
        minWidth: "250px"
    },   
    footerDatalistMenu: {
        bottom: "58px"
    },
    tabRoot: {
        border: "1px solid #dde3e8",
        borderRadius: "4px",
        minHeight: "46px", 
        maxHeight: "46px"
    },
    tabsRoot: {
        marginTop: "8px",
        marginBottom: "4px",
        marginRight: "16px"
    },
    tabIndicator: {
        display: "none"
    },
    button: {
        height: "46px",
        alignSelf: "flex-end",
        marginTop: "8px",
        marginBottom: "4px",
        marginLeft: 15
    },
});

class InvoiceMaterialPdfRow extends PropsOnlyListRow {

    static contextType = SettingsContext;
 
    constructor(props, context) {
        super(props, {}, {}, "invoices/InvoiceMaterialPdf");
    }

    componentDidUpdate(prevProps) {
        /*if(this.props.data.quantity_invoiced !== prevProps.data.quantity_invoiced && Number(this.props.data.quantity_invoiced) < Number(this.props.data.original_quantity_invoiced)) {
            this.props.rowProps.enqueueSnackbar(this.tr("You can't adjust the invoiced quantity to less than it currently is from here. Create a refund invoice instead."), {
                variant: "warning" 
            });
        }*/
    }

    defineClassName() {
        return "invoice-material-row";
    }

    setQuantity = (name, val) => {
        const { quantity, partial_quantity } = this.props.data;
        if (val <= 0 || val > (quantity - partial_quantity))
            return false;
        else 
            this.setData("quantity_to_invoice", val);
    }
   
    defineCells() {
        const { columnWidthMap, columnConfig, data, rowProps } = this.props;

        const { taimerAccount, userObject } = this.context;

        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: taimerAccount.currency}).format(value);
        };

        const commonProps = {
            editable: false,
            textAlign: "left"
        };

        const partialInvoicing = this.context.addons.quoterow_partial_invoicing_for_products;
 
        const toInvoice = () => {
            if (data.quantity_to_invoice)
                return Number(data.quantity_to_invoice) + Number(data.partial_quantity);            
            if (data.initialQuantity)
                return Number(data.initialQuantity);
            if (data.quantity_invoiced)
                return Number(data.quantity_invoiced);
            return Number(data.partial_quantity);
        }
        
        const billedPriceClasses = [];
        
        data.row_type != 1              && billedPriceClasses.push("editable");
        data.selected_price !== null    && billedPriceClasses.push("edited");
        
        let cells = {
            checked:
                <CheckboxCell key={data.id} checked={this.props.checked} onEdited={() => this.props.rowProps.onCheck(data.id)} />,
            /*material_id:
                <CheckboxCell key={data.material_id} checked={this.props.checkedPdf} onEdited={() => console.log(data.material_id)} />,*/
            projects_name:
                <ListCell
                    {...commonProps}
                    name="projects_name"
                    value={this.props.rowProps.selectedProjects.find(sp => sp.id == data.projects_id).label} />,
            creation_date: 
                <ListCell
                    {...commonProps}
                    name="creation_date"
                    value={format(data.creation_date, userObject.dateFormat)} />,
            /*type_name: 
                <ListCell
                    {...commonProps}
                    name="type_name"
                    value={this.props.rowProps.invoiceDataTypes.find(idt => idt.id == data.row_type).name} />,*/
            header: 
                <ListCell
                    {...commonProps}
                    name="header"
                    value={data.material_header || ''} />,
            worktype: 
                <ListCell
                    {...commonProps}
                    name="worktype"
                    value={data.worktasks_name}
                    className="worktypeCell"
                     />,
            resource_name: 
                <ListCell
                    {...commonProps}
                    name="resource_name"
                    value={data.resource_name}
                    className="taskCell"
                     />,
            description: 
                <ListCell
                    {...commonProps}
                    name="description"
                    value={data.entry_description || data.description}
                    className="descriptionCell"
                     />,
            /*quantity_delivered:
                <ListCell 
                    value={data.is_product_row == 1 ? data.quantity_delivered : "–"}
                    editable={false}
                    textAlign="right" />,*/
            /*quantity_invoiced:
                data.is_product_row == 1 ? <TextInputCell
                    editable={true}
                    listCellProps={{ className: "invoiced_quantity" }}
                    textAlign="right"
                    name="quantity_invoiced"
                    onEdited={(name, value) => this.setQuantity(name, value)}
                    value={data.quantity_to_invoice == undefined ? Number(data.initialQuantity) - Number(data.partial_quantity) : data.quantity_to_invoice} /> : <ListCell value={"–"} editable={false} />,*/
            quantity: 
                <ListCell
                    {...commonProps}
                    textAlign="right"
                    name="quantity"
                    className="hoursCell"
                    value={partialInvoicing && data.is_product_row == 1 ? `${data.quantity} / ${toInvoice().toFixed(2)}` : Number(data.quantity).toFixed(2)} />,                    
            value: 
                <ListCell
                    {...commonProps}
                    name="value"
                    textAlign="right"
                    className="costCell"
                    value={formatSum(data.value)} />,
            total_no_vat: 
                <ListCell
                    {...commonProps}
                    name="total_no_vat"
                    textAlign="right"
                    className="totalCell"
                    value={formatSum(data.quantity * data.value)} />,
            /*billed_price:
                <TextInputCell
                    editable={data.row_type != 1}
                    textAlign="right"
                    name="billed_price"
                    listCellProps={{
                        className: billedPriceClasses.join(" ")
                    }}
                    listCellType={CurrencyListCell}
                    onEdited={(name, value) => { 
                        value = value.replace(",", "."); 
                        (Number(data.billed_price) !== Number(value) || value === '') && rowProps.setBilledPrice(data.id, value === "" ? null : value)} }
                    value={Number(data.billed_price).toFixed(2)} />,*/
        };

        return cells;
    }
};


class InvoiceMaterialPdf extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context) {
        super(props, context, "invoices/InvoiceMaterialPdf");

        this.handleRowCheck = this.handleRowCheck.bind(this);
        this.setBilledPrice = this.setBilledPrice.bind(this);
        this.initialProjects = [{id:0, label: this.tr("All projects")}, ...this.props.data.projects];

        this.workhoursGrouping = [
            ...((this.context.addons.procountor_accounting || this.context.addons.heeros) ? [] : [{id: "5", label: this.tr("All in one")}]),
            {id: "1", label: this.tr("Hour entries specified")},
            {id: "2", label: this.tr("Per employee and task")},
            ...((this.context.addons.procountor_accounting || this.context.addons.heeros) ? [] : [{id: "3", label: this.tr("Per employee")}]),
            {id: "4", label: this.tr("Per task")}
        ];

        this.worktripsGrouping = [
            {id: "3", label: this.tr("Travel expenses all in one")},
            {id: "1", label: this.tr("Daily allowance, mileage and other expenses specified")},
            {id: "2", label: this.tr("Daily allowance and mileage in one, other expenses specified")}
        ];

        this.costestGrouping = [
            {id: "2", label: this.tr("Do not import description rows from quote")},
            {id: "1", label: this.tr("Import description rows from quote")}
        ];

        let defaultHideableColumns = [
                {
                    key: 'summary_section',
                    field: this.tr('Summary section'), 
                    selected: true,
                },
                {
                    key: 'protitle_name', 
                    field: this.tr('Professional title'), 
                    selected: true, 
                    parent: "summary_section"
                },
                {
                    key: 'hours', 
                    field: this.tr('Quantity'), 
                    selected: true, 
                    parent: "summary_section"
                },
                {
                    key: 'average_price', 
                    field: this.tr('Average price'), 
                    selected: true, 
                    parent: "summary_section"
                },
                {
                    key: 'total', 
                    field: this.tr('Total'), 
                    selected: true, 
                    parent: "summary_section"
                },
                {
                    key: 'entries_section', 
                    field: this.tr('Entries specified section'), 
                    selected: true,
                },
                {
                    key: 'description', 
                    field: this.tr('Description'), 
                    selected: false, 
                    parent: "entries_section"
                }, 
                {
                    key: 'worktype', 
                    field: this.tr('Worktype'), 
                    selected: true, 
                    parent: "entries_section"
                },
                {
                    key: 'resource_name', 
                    field: this.tr('Task'), 
                    selected: false, 
                    parent: "entries_section"
                },
                {
                    key: 'hours', 
                    field: this.tr('Quantity'), 
                    selected: true, 
                    parent: "entries_section"
                },
                {
                    key: 'average_price', 
                    field: this.tr('Average price'), 
                    selected: false, 
                    parent: "entries_section"
                },
                {
                    key: 'total', 
                    field: this.tr('Total'), 
                    selected: false, 
                    parent: "entries_section"
                }
            ];

        try {
            let storedColumns = localStorage.getItem(COLUMNS_KEY);
            storedColumns = storedColumns?.split(",");
            defaultHideableColumns = defaultHideableColumns.map(hc => ({ ...hc, 
                selected: storedColumns.includes(`${hc.parent}-${hc.key}`)
            }))
        } catch (e) {
            console.error(e);
        }   

        this.state = {
            rows: props.data.invoiceData.invoice_data_rows,
            startDate: props.data.startDate,
            endDate: props.data.endDate,
            selectedProjects: [...this.initialProjects],
            row_type: props.data.invoiceDataTypes[1],
            grouping: {
                workhours: this.workhoursGrouping.find(e => e.id == this.props.data.groupingOptions.workhours),
                worktrips: this.worktripsGrouping.find(e => e.id == this.props.data.groupingOptions.worktrips),
                costest: this.costestGrouping.find(e => e.id == this.props.data.groupingOptions.costest)
            },
            allChecked: props.data.invoiceData.invoice_data_rows.filter(s => s.selected > 0).length === props.data.invoiceData.invoice_data_rows.length,
            allowPlainUpdate: true,
            hideableColumns: defaultHideableColumns
        }

        this.datePicker = React.createRef();
        this.list = React.createRef();
        this.container = React.createRef();

        const partialProductInvoicing = this.context.addons.quoterow_partial_invoicing_for_products;
        
        const commonProps = {
            showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false
        };
        
        this.fields = [
            { field: "checked", name: "checked", header: "", columnHeaderType: "checkbox", width: 50, ...commonProps },
            //{ field: "material_id", name: "material_id", header: "", columnHeaderType: "checkbox", width: 50, showMenu: false, resizeable: false, showResizeMarker: false, moveable: false, hideable: false },
            { field: "projects_name", name: "projects_name", header: this.tr("Project"), width: 250, ...commonProps},
            { field: "creation_date", name: "creation_date", header: this.tr("Creation date"), width: 100, ...commonProps },
            //{ field: "type_name", name: "type_name", header: this.tr("Type"), width: 100, ...commonProps },
            { field: "header", name: "header", header: this.tr("Header"), width: 100, ...commonProps },
            { field: "worktype", name: "worktype", header: this.tr("Worktype"), className: "worktypeCell", width: 200, ...commonProps },
            { field: "resource_name", name: "resource_name", header: this.tr("Task"), className: "taskCell", width: 200, ...commonProps },
            { field: "description", name: "description", header: this.tr("Description"), className: "descriptionCell", width: 200, ...commonProps },
            { field: "quantity", name: "quantity", header: this.tr(partialProductInvoicing ? "Qty / sold" : "Qty"), className: "hoursCell", width: 140, ...commonProps },

            // This field is used to select the quantity to be invoiced now.
            //partialProductInvoicing ? { field: "quantity_delivered", name: "quantity_delivered", header: this.tr("Delivered"), width: 60,...commonProps } : undefined,
            //partialProductInvoicing ? { field: "quantity_invoiced", name: "quantity_invoiced", header: this.tr("Invoiced"), width: 60, ...commonProps } : undefined,
            
            { field: "value", name: "value", header: this.tr("Unit price"), className: "costCell", width: 100, ...commonProps },
            { field: "total_no_vat", name: "total_no_vat", header: this.tr("Tot. 0%"), className: "totalCell", width: 100, ...commonProps },
            /*context.addons.complex_invoicing  && { field: "billed_price", name: "billed_price", header: this.tr("Selling price"), width: 100, ...commonProps },*/
        ].filter(field => field !== undefined && field !== false);
    }
    
    componentDidUpdate(prevProps, prevState) {
        
        if (prevProps.data.invoiceData.invoice_data_rows !== this.props.data.invoiceData.invoice_data_rows) {
            this.calculateBilledPrices(this.props.data.invoiceData.invoice_data_rows);
        }
    }
    
    componentDidMount () {
        this.calculateBilledPrices();
    }
    
    calculateBilledPrices = (rows) => {
        let hasWorkhours = false, workhourTotal = 0, hoursTotal = 0, hasOther = false, otherTotal = 0, freeformOtherTotal = 0, freeformRowsTotal = 0, allowPlainUpdate = true;
        
        rows = rows || _.cloneDeep(this.state.rows);
        const netTotal = Number(this.props.data.netTotal);
        
        rows.forEach(e => {
            if (e.selected != 1)
                return false;
            
            e.quantity -= 0; e.value -= 0;
            
            if (e.row_type == 1) {
                hasWorkhours = true;
                hoursTotal += e.quantity;
                
                if (e.value != 0)
                    workhourTotal += e.quantity * e.value;
            }
            else {
                hasOther = true;
                
                otherTotal += e.selected_price !== null ? Number(e.selected_price) : e.quantity * e.value;
                
                //
                if (e.selected_price === null) {
                    freeformOtherTotal += e.quantity * e.value;
                    freeformRowsTotal++;
                }
            }
            
            return true;
        });
        
        let workhourMultiplier = hasWorkhours && (workhourTotal ? (netTotal - otherTotal) / workhourTotal : (netTotal - otherTotal) / hoursTotal);
        
        let otherMultiplier = hasOther && freeformOtherTotal &&  (netTotal - (otherTotal - freeformOtherTotal)) / freeformOtherTotal;
        
        if (otherMultiplier < 0 && this.props.data.canEditContent) {
            otherMultiplier = 0;
            workhourMultiplier = 0;
            allowPlainUpdate = false;
        }
        else if (otherMultiplier < 0) {
            this.props.enqueueSnackbar(this.tr("You can't set the selling prices over invoice net total"), {
                variant: "error" 
            });
            return;
        }
        
        if (workhourMultiplier > 0)
            rows = rows.map(e => {
                if (e.selected != 1) {
                   e.billed_price = 0;
                   return e;
                }

                if (e.row_type == 1) {
                    e.quantity -= 0; e.value -= 0;
                    e.billed_price = workhourTotal ? e.quantity * e.value * workhourMultiplier : e.quantity * workhourMultiplier;
                }
                else {
                    e.billed_price = e.selected_price !== null ? e.selected_price : e.quantity * e.value;
                }

                return e;
            });
        else
            rows = rows.map(e => {
                if (e.selected != 1) {
                   e.billed_price = 0;
                   return e;
                }
                if (e.row_type == 1)  
                    e.billed_price = 0;
                else if (e.selected_price !== null)
                    e.billed_price = e.selected_price;
                else if (otherMultiplier !== false)
                    e.billed_price = e.quantity * e.value * otherMultiplier;
                else
                    e.billed_price = (netTotal - otherTotal) / freeformRowsTotal;
                
                return e;
            });
        
        this.setState({rows, allowPlainUpdate});
    }
    
    projectOptionRenderer = (props) => {
 
        if (!props)
            return null;

        const checkedStatus = this.state.selectedProjects.find(sp => sp.id === 0) ? true : (this.state.selectedProjects.find(sp => sp.id == props.id) ? true : false);
        return (
            <MenuItem noClose onClick={(e) => this.handleCheckboxSelect(props, checkedStatus)}>
                <Checkbox checked={checkedStatus}  />
                {props.label}
            </MenuItem>
        );
    };

    tabsRenderer = (type) => {
        const { classes } = this.props
        return this.props.data.groupingOptions[type].map( o => 
            <Tab key={o.id} 
                value={Number(o.id)} 
                label={o.name} 
                classes={{root: classNames(classes.tabRoot)}}/>
        );

    };

    onInputChange = (name, date) => {
        this.setState({date: format(new Date(date), 'YYYY-MM-DD')});
    };

    onDateChange = (date) => {
        this.setState({date: format(new Date(date), 'YYYY-MM-DD')});
    };

    handleCancel = () => {
        this.props.onDialogClose();
    };

    handleCheckboxSelect = (project, checkedStatus) => {
 
        const { id } = project;
        const { selectedProjects } = this.state;
        
        if (checkedStatus)
            this.setState({selectedProjects: id === 0 ? [] : selectedProjects.filter(sp => sp.id !== id && sp.id !== 0)});
        else 
            this.setState({selectedProjects: id === 0 || selectedProjects.length + 2 === this.initialProjects.length ? this.initialProjects : [...selectedProjects, project]});

        
    };

    handleTypeChange = (type, item) => {
        this.setState({ [item.name]: type});
    };    

    handleGroupingChange = (value, item) => {

        const { handleMaterialGroupingChange } = this.props.data;

        this.setState(state => {
            state.grouping[item.name] = value;
            return state;
        });

        handleMaterialGroupingChange(item.name, value.id);
    };

    handleRangeSelect = (selection) => {
        const { startDate, endDate } = selection.selection 
            ? selection.selection 
            : selection.range1;

        const start = format(startDate, 'YYYY-MM-DD');
        const end = format(endDate, 'YYYY-MM-DD');

        this.setState({startDate: start, endDate: end});

        if (start == end)
            return;

        this.props.data.handleRangeSelect(selection);
    };

    handleRangeInputChange = (name, value) => {

        const val = format(value, 'YYYY-MM-DD');
        if (!isValid(val)) 
            return;

        const prop = name == 'start' ? 'startDate' : 'endDate';
        this.setState({[prop]: val});

        this.props.data.handleRangeInputChange(name, value);
    };

    handleRowCheck = (id) => {

        this.list.current.check(id);
        let rows = _.cloneDeep(this.state.rows);
        
        const i = rows.findIndex(e => e.id === id)
        rows[i].selected = rows[i].selected > 0 ? 0 : 1;
 
        this.calculateBilledPrices(rows);
    };
    handleCheckAll = (checked, ids) => {
        const rows = _.cloneDeep(this.state.rows);
        
        rows.forEach(e => {
            if (ids.find(id => id == e.id))
                e.selected = checked ? 1 : 0;
        });

        this.calculateBilledPrices(rows);
    }
    setBilledPrice = (id, val) => {
        let rows = _.cloneDeep(this.state.rows);
        const i = rows.findIndex(e => e.id === id)
        rows[i].selected_price = val;
 
        this.calculateBilledPrices(rows);
    };

    update = (update = true) => {
        const { userObject } = this.context;

        let { rows } = this.state;
        let material = _.cloneDeep(rows);
        
        material = material.map(m => {
            m.quantity = m.quantity_to_invoice || m.initialQuantity || m.quantity;
            
            return m;
        });
        
        let printData = {rows: material, hideableColumns: this.state.hideableColumns, dateRange: {startDate: format(this.state.startDate, userObject.dateFormat), endDate: format(this.state.endDate, userObject.dateFormat)}};

        printData.translatableStrings = {};
        printData.translatableStrings.hoursreport = this.tr("Hours report");
        printData.translatableStrings.grandtotal = this.tr("Grand Total");
        printData.translatableStrings.accounttotal = this.tr("Account Total");
        printData.translatableStrings.projecttotal = this.tr("Project Total");
        printData.translatableStrings.description = this.tr("Description");
        printData.translatableStrings.worktype = this.tr("Worktype");
        printData.translatableStrings.hours = this.tr("Hours");
        printData.translatableStrings.date = this.tr("Date");
        printData.translatableStrings.user = this.tr("User");

        this.props.onDialogSave(this.props.data.saveFunc, printData, update);
    };

    toggleHideableColumns = (column) => {
        let hideableColumns = cloneDeep(this.state.hideableColumns);
        const columnIndex = hideableColumns.findIndex(c => c.key == column.key && c.parent == column.parent);
        if (columnIndex == -1) return;
        const selectedValue = !hideableColumns[columnIndex].selected;
        hideableColumns[columnIndex].selected = selectedValue;
        hideableColumns.forEach(c => {
            if (c.parent == column.key) {
                c.selected = selectedValue;
            };
        });
        if (column.parent) {
            const selectedChildValues = hideableColumns.filter(c => c.parent == column.parent && c.selected == true);
            const parentIndex = hideableColumns.findIndex(c => c.key == column.parent);
            if (parentIndex != -1) {
                hideableColumns[parentIndex].selected = selectedChildValues.length != 0;
            }
        }
        this.setState({ hideableColumns });
        try {
          localStorage.setItem(COLUMNS_KEY, hideableColumns.filter(sc => sc.selected).map(cs => `${cs.parent}-${cs.key}`).join(","));
        } catch (e) {}        
    }

    render() {

        const { taimerAccount, userObject } = this.context;

        const { classes, data: { projects, settings, groupingOptions, canEditContent, invoiceDataTypes} } = this.props;

        const { rows, selectedProjects, row_type, grouping, allChecked, startDate, endDate, allowPlainUpdate } = this.state;

        //if all projects are selected, display "all projects"
        const projectDisplayValue = selectedProjects.find(e => e.id === 0) || {id: -1, label: selectedProjects.map(sp => sp.label).join(', ')};

        const dateFormat = this.props.dateFormat ? this.props.dateFormat : userObject.dateFormat;

        const formatSum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat, {style: 'currency', currency: taimerAccount.currency}).format(value);
        };
        const formatNum = (value) => {
            return Intl.NumberFormat(taimerAccount.numberFormat).format(value);
        };
        
        let availableTypes = {"0": 'all'};
        rows.forEach(e => availableTypes[ e.row_type ] = true);
        availableTypes = Object.keys(availableTypes);
        
        //if availableTypes contain at least two travel invoice types, add the main type to the availableTypes
        if (_.intersection(availableTypes , ["8", "9", "10"]).length > 1)
            availableTypes.push("100");

        const currentTypes = invoiceDataTypes.filter(ct => availableTypes.indexOf(ct.id) !== -1);

        const rowProps = {
            onCheck: this.handleRowCheck,
            allChecked: this.state.allChecked,
            enqueueSnackbar: this.props.enqueueSnackbar,
            invoiceDataTypes: this.props.data.invoiceDataTypes,
            selectedProjects: this.props.data.selectedProjects,
            setBilledPrice: this.setBilledPrice
        };
        
        let displayedRows;
        if (!rows && !rows.length)
            displayedRows = [];
        else if (row_type.id == 0 && projectDisplayValue.id === 0)
            //all material types and all projects are selected
            displayedRows = rows;
        else
            displayedRows = rows.filter(r => {
                if (projectDisplayValue.id !== 0 && selectedProjects.find(sel => sel.id == r.projects_id) === undefined)
                    //material doesn't match selected project
                    return false;
                
                if (row_type.id == 0)
                    //all selected
                    return true;
                if (r.row_type === row_type.id)
                    //row type matches selected
                    return true;
                if (row_type.id == 100 && ["8", "9", "10"].indexOf(r.row_type) !== -1)
                    //all travel invoice types selected
                    return true;
            });
        
        displayedRows = _.cloneDeep(displayedRows);
        
        let quantityTotal = 0, netTotal = 0, sellingTotal = 0;
        displayedRows.forEach(e => {
            quantityTotal += Number(e.quantity);
            netTotal += e.quantity * e.value;
            sellingTotal += Number(e.billed_price);
        });

        let grayedColumns = this.state.hideableColumns.find(hc => (hc.key === 'description' && hc.parent == "entries_section" && !hc.selected)) ? ' descriptionGreyed ' : '';
        grayedColumns += this.state.hideableColumns.find(hc => (hc.key === 'worktype' && hc.parent == "entries_section" && !hc.selected)) ? ' worktypeGreyed ' : '';
        grayedColumns += this.state.hideableColumns.find(hc => (hc.key === 'resource_name' && hc.parent == "entries_section" && !hc.selected)) ? ' taskGreyed ' : '';
        grayedColumns += this.state.hideableColumns.find(hc => (hc.key === 'hours' && hc.parent == "entries_section" && !hc.selected)) ? ' hoursGreyed ' : '';
        grayedColumns += this.state.hideableColumns.find(hc => (hc.key === 'total' && hc.parent == "entries_section" && !hc.selected)) ? ' totalGreyed ' : '';

        return (
            <Dialog
                id="invoice-material-dialog"
                open
                maxWidth="xl"
                maxWidth={false}
                fullWidth
                PaperProps={{classes:{root: classNames(classes.dialogRoot)}}}
                className="invoice-material-dialog"
                aria-labelledby="invoiceMaterialPdfDialog"
                TransitionProps={{
                    onEntering: this.handleEnter
                }}>
                <div className={classNames(classes.topBar)}/>
                <DialogTitle className="invoicematerial-dialog-title">
                    <h6>
                        {this.tr('Invoice material Pdf')}
                    </h6>
                    <div className="actionControls">
                        <Tooltip classes={{tooltip: 'darkblue-tooltip'}} title={this.tr("Show/hide columns")}>
                            <span className="hide-columns-options">
                                <span onClick={e => {
                                    this.setState({ 
                                        viewMenuAnchor: this.state.viewMenuOpen ? null : e.currentTarget, 
                                        viewMenuOpen: !this.state.viewMenuOpen
                                    })
                                }}>	
                                    <EyeIcon className="icon" />
                                    <ArrowDropDown className="small" />
                                </span>
                                <Popover
                                    open={this.state.viewMenuOpen}
                                    onClose={() => {
                                        this.setState({
                                            viewMenuAnchor: null,
                                            viewMenuOpen: false
                                        });
                                    }}
                                    anchorEl={this.state.viewMenuAnchor} 
                                    anchorOrigin={{ vertical: 45, horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    className="hide-columns-popover"
                                    style={{}}>
                                    <MUIList>
                                        {this.state.hideableColumns.map(column => {
                                            return (
                                                <MUIListItem className="material-pdf-hide-columns-option">
                                                    <Checkbox
                                                        color="primary"
                                                        style={{ paddingLeft: !!column.parent ? 26 : undefined }}
                                                        onChange={(e) => {
                                                            this.toggleHideableColumns(column);
                                                        }}
                                                        checked={column.selected}
                                                    />
                                                    <ListItemText primary={column.field} className="hide-columns-checkbox-text" />
                                                </MUIListItem>
                                            )})}
                                    </MUIList>
                                </Popover>
                            </span>
                        </Tooltip>
                    </div>
                    <div className={classNames(classes.controlsContainer)}>
                        <div className="fields-container" >
                           
                            <ContextMenu isDisabled={true} description={this.tr("Projects")} label={projectDisplayValue.label} size="medium" dropdownMenu>
                                {this.initialProjects.map(this.projectOptionRenderer)}
                            </ContextMenu>
                            
                            <DataList 
                                label={this.tr("Current types")} 
                                name="row_type"
                                inputBaseProps={{classes: {root: classNames(classes.datalistInputRoot)}}}
                                options={currentTypes}
                                value={row_type}
                                onChange={this.handleTypeChange}
                                isDisabled={true} />
                        </div>
                        <DateRangePicker
                            ranges={[{startDate: startDate, endDate: endDate}]}
                            onChange={this.handleRangeSelect}
                            onInputChange={this.handleRangeInputChange}
                            label={this.tr("Invoicing material period")}
                            dateFormat={userObject.dateFormat}
                            disabled={true}
                        />
                        
                    </div>                    
                </DialogTitle> 
                <DialogContent className="invoicematerial-dialog-content">
                    <div ref={this.container}
                    className={grayedColumns}
                    >
                        <List
                            ref={this.list}
                            fluid
                            height="fitRemaining"
                            trimHeight={-209}
                            rowHeight={26 + 6}            
                            data={displayedRows.filter(r => r.main_type == 1).map(row => {
                                row.original_quantity_invoiced = row.quantity_invoiced;
                                return row;
                            })}
                            noStateData={true}
                            idType="string"
                            columns={this.fields}
                            className="invoice-material-list"
                            listRowType={InvoiceMaterialPdfRow}
                            onDataChange={({ data, newData, origin }) => {
                                if(origin === "initial") {
                                    this.list.current.check(displayedRows.filter(r => (r.selected == "1" && r.main_type == 1)).map(r => r.id));
                                }
                            }}

                            rowProps={rowProps}
                            useGlobalAllChecked={false}
                            onCheckAll={ids => this.handleCheckAll(true, ids)}
                            onUncheckAll={ids => this.handleCheckAll(false, ids)}/>
                    </div>
                </DialogContent>
                <DialogActions  className="invoicematerial-dialog-footer">
                    {/*<div className="totals">
                        <div>{this.tr("Total quantity")}: {formatNum(quantityTotal)}</div>
                        <div>{this.tr("Total 0%")}: {formatSum(netTotal)}</div>
                        <div>{this.tr("Total selling price")}: {formatSum(sellingTotal)}</div>
                    
                        </div>*/}
                    <div className={classNames(classes.controlsContainer)}>
                        <div className="fields-container" >
                            {/*<DataList 
                                label={this.tr("Workhours grouping")} 
                                name="workhours"
                                className="footer-datalist"
                                classes={{paper: classNames(classes.footerDatalistMenu)}}
                                inputBaseProps={{classes: {root: classNames(classes.optionsDatalistInputRoot)}}}
                                options={this.workhoursGrouping}
                                value={grouping.workhours}
                                menuPlacement="top"
                                onChange={this.handleGroupingChange}
                                isDisabled={true} />
                            <DataList 
                                label={this.tr("Worktrips grouping")}
                                name="worktrips"
                                className="footer-datalist"
                                classes={{paper: classNames(classes.footerDatalistMenu)}}
                                inputBaseProps={{classes: {root: classNames(classes.optionsDatalistInputRoot)}}}
                                options={this.worktripsGrouping}
                                value={grouping.worktrips}
                                menuPlacement="top"
                                onChange={this.handleGroupingChange}
                                isDisabled={true} />
                            <DataList 
                                label={this.tr("Costestimate grouping")} 
                                name="costest"
                                className="footer-datalist"
                                classes={{paper: classNames(classes.footerDatalistMenu)}}
                                inputBaseProps={{classes: {root: classNames(classes.optionsDatalistInputRoot)}}}
                                options={this.costestGrouping}
                                value={grouping.costest}
                                menuPlacement="top"
                                onChange={this.handleGroupingChange}
                                isDisabled={true} />     */}                         
                        </div>
                        <div className={classNames(classes.buttonsContainer)}>                    
                            <Button className={classNames(classes.button)} color="info" variant="outlined" onClick={this.handleCancel} >
                                {this.tr('Cancel')}
                            </Button>
                            <Button className={classNames(classes.button)} variant="contained" disabled={!allowPlainUpdate} onClick={() => this.update(true)} color="primary">
                                {this.tr('Add PDF to invoice')}
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}
InvoiceMaterialPdf.defaultProps = {
    onDialogClose: () => {},
    onDialogSave: () => {},
    enqueueSnackbar: PropTypes.func.isRequired
}

export default withSnackbar(withStyles(styles)(InvoiceMaterialPdf));
