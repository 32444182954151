import React from 'react';
import { isEqual, uniqBy } from 'lodash';
import { MenuItem, Tooltip } from '@mui/material';
import { Add, AddCircleOutlined, CheckCircle, Delete, MoreVert, StyleTwoTone, UnfoldMore, Visibility, VisibilityOff } from '@mui/icons-material';

import Link from '../general/Link';
import ListCell, { ListCellWithRef } from '../list/ListCell';
import { quoteRowTypes } from './TabQuotes';
import TextInput from '../general/TextInput';
import { formatInputNumber } from '../helpers';
import DataHandler from '../general/DataHandler';
import InfoSelect from '../list/cells/InfoSelect';
import TextInputCell from '../list/cells/TextInputCell';
import CheckboxCell from '../list/cells/CheckboxCell';
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import ClickAwayWrapper from '../general/ClickAwayWrapper';
import AutoCompleteCell from '../list/cells/AutoCompleteCell';
import ContextMenu, { ContextSubMenu } from '../general/ContextMenu';
import StyledTooltip from '../general/StyledTooltip';

import styles from './TabQuotes.module.scss';
import VersionContentManager from '../general/VersionContentManager';

// Cell that needs a popup for edits (two editable fields in one)
const PopUpEditCell = (props) => {
    const { data, columnWidthMap, cellKey: key, column, editMode, editable, listCellProps } = props;
    return (
        <ClickAwayWrapper active={props.rowProps.activeCell == `${data.id}_${key}`} onClickAway={() => props.rowProps.setActiveCell(undefined)}>
            {/*@ts-ignore*/}
            <ListCellWithRef onlyDisplay editable={true} width={columnWidthMap[key]} hiddenFromPrint={listCellProps?.hiddenFromPrint}>
                <TextInput className={styles.hidden} onFocus={editable && editMode ? (e) => props.rowProps.setActiveCell(`${data.id}_${key}`) : undefined} />
                <div className={`${styles.cellTextContainer} ${column.alignRight ? styles.right : styles.left}`}>
                    <div
                        onClick={editable && editMode ? (e) => props.rowProps.setActiveCell(data.type != 99 ? `${data.id}_${key}` : undefined) : undefined}
                        className={`${styles.editContainer} ${
                            editable && editMode ? `${styles.editMode} ${styles.editable} ${props.rowProps.activeCell == `${data.id}_${key}` ? styles.active : ''}` : ''
                        }`}
                    >
                        {props.children}
                    </div>
                </div>
                {props.rowProps.activeCell == `${data.id}_${key}` && (
                    <div className={styles.popUp}>
                        {props.rowProps
                            .getEditableFields()
                            .filter((f) => (f.showInEditPopup || []).includes(key))
                            .map((field) => props.rowProps.renderField(data, field))}
                    </div>
                )}
            </ListCellWithRef>
        </ClickAwayWrapper>
    );
};
class QuoteRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, 'list/rows/QuoteRow');
    }

    getQuantityType = () => {
        const { data, rowProps } = this.props;
        if (isNaN(data.quantityType)) {
            return data.quantityType || '';
        } else {
            return rowProps.quantityTypes.find((qt) => qt.id == data.quantityType)?.name || rowProps.quantityTypes[0]?.name || '';
        }
    };

    defineClassName = () => {
        return this.props.data.type == quoteRowTypes.summaryRow
            ? `${styles.summaryRow} ${this.props.data.id == 'totalRow' ? styles.total : ''} ${this.props.data.totalRow ? styles.totalRow : ''}`
            : ``;
    };

    shouldComponentUpdate = (newProps, newState) => {
        if (
            !isEqual(newProps.data, this.props.data) ||
            newProps.rowProps.editMode != this.props.rowProps.editMode ||
            newProps.rowProps.currencyFormatter != this.props.rowProps.currencyFormatter ||
            (!isEqual(newProps.columnConfig, this.props.columnConfig) && this.props.data.type == 99)
        ) {
            return true;
        }
        return super.shouldComponentUpdate(newProps, newState);
    };

    handleCheck = () => {
        const {
            data,
            listRef
        } = this.props;
        const { 
            id, 
            type,
            parentId
        } = data;

        const ids = [id];
        let newCheckedState: boolean | undefined = undefined;

        // If the checked row is a header,
        // check all the children too.
        if(Number(type) === 0) {
            const children = this.props.listRef
                .getData()
                .filter(d => Number(d.type) < 99)
                .filter(d => Number(d.parentId) === Number(id));

                if(children.find(c => !c._checked)) {
                    newCheckedState = true;
                }

                children.map(d => d.id).forEach(id => ids.push(id));
        } 

        listRef.check(ids, newCheckedState, () => {
            // Update the parent row, so that it can react
            // to the changes of its child rows.
            listRef.flipRows(parentId);
        });
    };

    defineCells = () => {
        const { 
            data, 
            columnWidthMap, 
            columnConfig, 
            rowProps,
            listRef
        } = this.props;
        const textInputCellProps = {
            useClickAwayListener: true,
            runOnEditedOnInput: false,
        };
        const { addons } = this.context;

        const printMode = rowProps.printMode;

        const editMode        = rowProps.editMode;
        const rowDescription  = data.product_name ? data.product_name : data.name;

        const isInvoiced = Number(data.bills_id) > 0 || data.scheduled_invoices?.length > 0;
        const partialInvoicing = addons.quoterow_partial_invoicing_for_products;
        // Product need to be editable if partialInvoicing is active even if row is invoiced. 
        const productNotInvoiceable = data.scheduled_invoices?.length > 0 || (Number(data.bills_id) > 0 && !partialInvoicing);

        // Leaving this here. I have a feeling we're gonna need it again son.
        // const overrideChecked = Number(data.type) === 0 && listRef
            // .getCheckedData()
            // .filter(d => Number(d.parentId) === Number(data.id))
            // .length > 0;

        const moreMenu = (
            <div className={styles.leftRowButtons} onMouseDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()} data-testid={'contextmenu-button-' + rowDescription}>
                {/*@ts-ignore*/}
                <ContextMenu
                    label={<MoreVert />}
                    className={styles.more}
                    customGrowId={styles.rowMenu}
                    noExpandIcon
                    popperProps={{ disablePortal: false }}
                    closeWithSubmenuSelection
                    clickAwayListenerProps={{ mouseEvent: 'onMouseUp' }}
                >
                    {!editMode && false && rowProps.canCreatePurchaseOrder && (
                        <MenuItem onClick={() => rowProps.createPurchaseOrder([data.id])} data-testid={'context-menuitem-add-purchaseorder-' + rowDescription}>
                            <Add className="menuIcon" />
                            {this.tr('Add purchase order')}
                        </MenuItem>
                    )}
                    {!editMode && (rowProps.canCreateTask || data.has_task > 0) && (
                        <MenuItem
                            disabled={data.has_task > 0 || !rowProps.canCreateTask}
                            onClick={() => {
                                data.has_task < 1 && rowProps.canCreateTask && rowProps.createTask(data.id, data.quantity, data.product_name || data.name);
                            }}
                        >
                            <Add />
                            {data.has_task < 1 ? this.tr('Create a task for this row') : this.tr('Task has been linked to this row')}
                        </MenuItem>
                    )}
                    {editMode && (
                        <ContextSubMenu icon={<AddCircleOutlined />} title={this.tr('Add row below')}>
                            <MenuItem onClick={() => rowProps.onAddRow({ parentId: data.type == 0 ? data.id : data.parentId, type: 2 }, data.id)}>{this.tr('Description')}</MenuItem>
                            <MenuItem onClick={() => rowProps.onAddRow({ parentId: data.type == 0 ? data.id : data.parentId, type: 1, workType: 1 }, data.id)}>{this.tr('Row')}</MenuItem>
                            <MenuItem onClick={() => rowProps.onAddRow({ parentId: data.type == 0 ? data.id : data.parentId, type: 3, workType: 4 }, data.id)}>{this.tr('Product')}</MenuItem>
                            {!VersionContentManager.isFeatureHidden(this.namespace, 'cpq') && (
                                <MenuItem onClick={() => rowProps.onAddRow({ parentId: data.type == 0 ? data.id : data.parentId, type: 4, workType: 3 }, data.id)}>
                                    {this.tr('CPQ row (grouped)')}
                                </MenuItem>
                            )}
                        </ContextSubMenu>
                    )}
                    {/*@ts-ignore*/}
                    <MenuItem onClick={() => this.setData('hidden_for_print', data.hidden_for_print == 1 ? 0 : 1)}>
                        {data.hidden_for_print == 1 ? <Visibility /> : <VisibilityOff />}
                        {data.hidden_for_print == 1 ? this.tr('Show in print') : this.tr('Hide from print')}
                    </MenuItem>
                    {editMode && (
                        <MenuItem disabled={isInvoiced} className={styles.delete} onClick={() => rowProps.onDeleteRow(data)}>
                            <Delete />
                            {this.tr('Delete')}
                        </MenuItem>
                    )}
                </ContextMenu>
            </div>
        );

        const cells = {};
        Object.keys(columnConfig).forEach((key) => {
            const column = columnConfig[key];

            // Product need to be editable if product is invoiceable. 
            const editable = data.type == quoteRowTypes.productRow
                ? !column.disabled && !productNotInvoiceable
                : data.type != 99 && !column.disabled && !isInvoiced;

            const listCellEditModeProps = {
                editable: editable && editMode,
                inEditMode: editable && editMode,
                noInitFocus: true,
                disableInitialFocus: true,
            };

            const valueHidden = rowProps.columnValuesPrintHidden && data.type != quoteRowTypes.summaryRow && key != "quantity" && key != "name";

            // Addon to show only quantity in print in rows. Total rows show still everything.
            if (printMode && valueHidden) {
                cells[key] = (
                    <ListCell onlyDisplay width={columnWidthMap[key]} >
                        <div></div>
                    </ListCell>)

                return;
            }
            // If values are hidden from print, show them inactive in non-print mode.
            if (!printMode && valueHidden) {
                column.hiddenFromPrint = true;
            }
            else {
                column.hiddenFromPrint = rowProps.isColumnHiddenFromPrint && rowProps.isColumnHiddenFromPrint(column);
            }

            switch (key) {
                case 'check':
                    cells[key] = [
                        quoteRowTypes.quoteRow, 
                        quoteRowTypes.productRow, 
                        quoteRowTypes.cpqGroupedRow,
                        quoteRowTypes.headerRow,
                        quoteRowTypes.descriptionRow,
                    ].indexOf(parseInt(this.props.data.type)) > -1 
                        ? (<CheckboxCell 
                            listCellProps={{
                                className: styles.checkboxCell
                            }}
                            disabled={editMode}
                            checked={this.props.checked} 
                            // checked={this.props.checked || overrideChecked} 
                            onClick={this.handleCheck}/>)
                        : (<ListCell editable={false} className={styles.checkboxCell} />);
                    break;
                case 'drag':
                    cells[key] = editMode ? (
                        <ListCell onlyDisplay width={columnWidthMap[key]} style={{ flex: 'none' }}>
                            {this.props.data.type != 99 ? (
                                <div
                                    className={styles.drag}
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                        this.startDrag();
                                    }}
                                >
                                    <UnfoldMore />
                                </div>
                            ) : undefined}
                        </ListCell>
                    ) : undefined;
                    break;
                case 'menu':
                    cells[key] = (
                        <ListCell onlyDisplay width={columnWidthMap[key]} style={{ flex: 'none' }}>
                            {this.props.data.type != quoteRowTypes.summaryRow && moreMenu}
                        </ListCell>
                    );
                    break;
                case 'quantity':
                    cells[key] = (
                        <PopUpEditCell {...this.props} cellKey={key} column={column} editable={editable} editMode={editMode}>
                            <p>{data[key] && data.type != quoteRowTypes.summaryRow ? `${Number(data[key]).toFixed(2)} ${this.getQuantityType()}` : ''}</p>
                        </PopUpEditCell>
                    );
                    break;
                case 'vat': {
                    cells[key] = (
                        <PopUpEditCell {...this.props} cellKey={key} column={column} editable={editable} editMode={editMode}>
                            {data.vat != undefined && <p>{`${formatInputNumber(data.vat, 'no-zero-decimals')} %`}</p>}
                            <p>{rowProps.currencyFormatter.format(data.currency_vatTotal)}</p>
                        </PopUpEditCell>
                    );
                    break;
                }
                case 'margin': {
                    const sum  = Number(data.currency_total_no_vat);
                    const margin = Number(data.margin) || 0;
                    const mp = !sum ? 0 : (margin / sum) * 100;
                    const marginPercentage = Math.round(mp * 100) / 100;
                    cells[key] = (
                        <PopUpEditCell {...this.props} cellKey={key} column={column} editable={editable} editMode={editMode}>
                            <p>{rowProps.currencyFormatter.format(margin)}</p>
                            <p>{`${marginPercentage.toFixed(2)} %`}</p>
                        </PopUpEditCell>
                    );
                    break;
                }
                case 'cost_total': {
                    cells[key] = (
                        <TextInputCell
                            width={columnWidthMap[key]}
                            name={key}
                            textAlign={column.alignRight ? 'right' : 'left'}
                            validation={['numeric']}
                            editable={false}
                            value={rowProps.currencyFormatter.format(data.currency_cost_total)}
                            {...textInputCellProps}
                            listCellProps={{ ...listCellEditModeProps }}
                        />
                    );
                    break;
                }
                case 'targeted': {
                    if (data.type == quoteRowTypes.quoteRow || data.type == quoteRowTypes.cpqGroupedRow || data.type == quoteRowTypes.productRow || data.type == quoteRowTypes.summaryRow) {
                        const targeted = Number(data.currency_targeted || 0);
                        const cost = Number(data.currency_cost_total || 0);
                        const percentage = !cost ? 0 : targeted / cost;
                        const targetPercentage = percentage * 100;
                        cells[key] =
                            editMode && data.type != quoteRowTypes.summaryRow && !Number(data.has_automatic_targeting) ? (
                                <TextInputCell
                                    width={columnWidthMap[key]}
                                    textAlign={column.alignRight ? 'right' : 'left'}
                                    name={key}
                                    listCellProps={{ ...listCellEditModeProps, textAlign: column.alignRight ? 'right' : 'left' }}
                                    value={Number(data.currency_targeted || 0).toFixed(2)}
                                    validation={['numeric']}
                                    onEdited={(value) => {
                                        const val = rowProps.formatNumberInput(value);
                                        if (val == undefined) return;
                                        const update = {
                                            [key]: val,
                                            updatedKey: key
                                        }
                                        this.setData(update);
                                    }}
                                />
                            ) : (
                                <ListCell onlyDisplay editable={false} width={columnWidthMap[key]}>
                                    <div className={`${styles.cellTextContainer} ${column.alignRight ? styles.right : styles.left}`}>
                                        <p>{rowProps.currencyFormatter.format(data.currency_targeted)}</p>
                                        {!!targetPercentage && <p className={targetPercentage > 100 ? styles.red : styles.green}>{`${targetPercentage.toFixed(2)} %`}</p>}
                                    </div>
                                </ListCell>
                            );
                    } else if (data.type != quoteRowTypes.descriptionRow) {
                        cells[key] = (
                            <TextInputCell
                                width={columnWidthMap[key]}
                                textAlign={column.alignRight ? 'right' : 'left'}
                                name={key}
                                editable={false}
                                value={column.currency && data.currency_targeted ? rowProps.currencyFormatter.format(data.currency_targeted) : data.currency_targeted}
                            />
                        );
                    }
                    break;
                }
                case 'quantity_delivered': {
                    cells[key] = (
                        <TextInputCell
                            width={columnWidthMap[key]}
                            textAlign={column.alignRight ? 'right' : 'left'}
                            name={key}
                            listCellProps={{ ...listCellEditModeProps, textAlign: column.alignRight ? 'right' : 'left' }}
                            textInputProps={{ selectOnFocus: true }}
                            editable={data.type == quoteRowTypes.quoteRow || data.type == quoteRowTypes.cpqGroupedRow || data.type == quoteRowTypes.productRow}
                            validation={['numeric']}
                            value={Number(data[key] || 0).toFixed(2)}
                            onEdited={(value) => {
                                const val = rowProps.formatNumberInput(value);
                                if (val == undefined) return;
                                this.setData('quantity_delivered', val);
                            }}
                        />
                    );
                    break;
                }
                case 'quantity_sold':
                    cells[key] = (
                        <TextInputCell width={columnWidthMap[key]} textAlign={column.alignRight ? 'right' : 'left'} name={key} editable={false} value={Number(data.quantity || 0).toFixed(2)} />
                    );
                    break;
                case 'quantity_invoiced':
                    cells[key] = <TextInputCell width={columnWidthMap[key]} textAlign={column.alignRight ? 'right' : 'left'} name={key} editable={false} value={Number(data[key] || 0).toFixed(2)} />;
                    break;
                case 'sum': {
                    const total = Number(data.currency_total_no_vat);
                    cells[key] = (
                        <TextInputCell width={columnWidthMap[key]} textAlign={column.alignRight ? 'right' : 'left'} name={key} editable={false} value={rowProps.currencyFormatter.format(total)} />
                    );
                    break;
                }
                case 'total': {
                    const total = Number(data.currency_total);
                    cells[key] = (
                        <TextInputCell width={columnWidthMap[key]} textAlign={column.alignRight ? 'right' : 'left'} name={key} editable={false} value={rowProps.currencyFormatter.format(total)} />
                    );
                    break;
                }
                case 'value':
                    cells[key] = (
                        <PopUpEditCell {...this.props} cellKey={key} column={column} editable={editable} editMode={editMode}>
                            <p>{rowProps.currencyFormatter.format(Number(data.currency_value))}</p>
                            {!!data.discountPercentage && Number(data.discountPercentage) != 0 && <p>{`-${formatInputNumber(Number(data.discountPercentage), 'no-zero-decimals')} %`}</p>}
                            {!!data.discount && Number(data.discount) != 0 && <p>{`-${rowProps.currencyFormatter.format(data.discount)}`}</p>}
                        </PopUpEditCell>
                    );
                    break;
                case 'workType':
                    cells[key] = (
                        <AutoCompleteCell
                            width={columnWidthMap[key]}
                            name={key}
                            listCellProps={{ ...listCellEditModeProps, textAlign: column.alignRight ? 'right' : 'left' }}
                            value={data.workType == 0 ? '-' : data.workType}
                            showStringValue={data.workType == 0}
                            autoCompleteData={this.props.rowProps.workTypes.map((wt) => ({ ...wt, value: wt.id }))}
                            searchable={false}
                            editable={true}
                            placeholder={this.tr('Worktype')}
                            selectProps={{ onMenuOpen: () => rowProps.setActiveCell(undefined) }}
                            menuPortalTarget={document.body}
                            tabSelectsValue={false}
                            onEdited={(value) => {
                                this.setData('workType', value.id);
                            }}
                        />
                    );
                    break;
                case 'jobtype':
                    cells[key] = (
                        <AutoCompleteCell
                            width={columnWidthMap[key]}
                            name={key}
                            addNoneOption
                            noneOptionLabel={this.tr('No jobtype')}
                            listCellProps={{ ...listCellEditModeProps, textAlign: column.alignRight ? 'right' : 'left' }}
                            value={editMode ? data.jobtypes_id : data.jobtypes_id == 0 ? '-' : data.jobtypes_name}
                            showStringValue={!editMode}
                            autoCompleteData={(rowProps.jobtypes || []).map((jt) => ({ ...jt, value: jt.id }))}
                            selectProps={{ onMenuOpen: () => rowProps.setActiveCell(undefined) }}
                            searchable={false}
                            editable={true}
                            placeholder={this.tr('Jobtype')}
                            menuPortalTarget={document.body}
                            tabSelectsValue={false}
                            onEdited={(value) => {
                                this.setData({ jobtypes_id: value.id, jobtypes_name: value.name });
                            }}
                        />
                    );
                    break;
                case 'invoiced': {
                    if (data.type != quoteRowTypes.summaryRow) {
                        const tooltip: any = [];
                        const fullyInvoiced = Number(data.bills_id || 0) != 0;
                        const scheduledInvoiced = data.scheduled_invoices?.length > 0;
                        const translatedTooltip = fullyInvoiced ? this.tr('Invoiced on invoice ${invoice}') : this.tr('Uninvoiced');
                        let styledTooltipContent: any = null;

                        if (!fullyInvoiced && scheduledInvoiced) {
                            styledTooltipContent = [this.tr("A scheduled invoice has been created from this row.")];
                            const bills = uniqBy((data.scheduled_invoices || []).filter(s => s.bills_id), 'invoice_nr');
                            if (bills.length > 0) {
                                styledTooltipContent.push(
                                    this.htmlTr('${linebreak} Invoiced in invoices: ${linebreak} ${invoices}', {
                                        invoices: (bills || []).map(si => {
                                            if (rowProps.canCreateInvoice) {
                                                return <li><Link openInNewTab url={{ module: 'invoices', action: 'view', id: si['bills_id'] }}>
                                                    {si['invoice_nr']}
                                                </Link></li>
                                            }
                                            return <li>{si['invoice_nr']}</li>;
                                        }),
                                        linebreak: [<br />, <br />]
                                    })
                                )
                            }
                        }
                        else {
                            translatedTooltip.split(' ').forEach((word) => {
                                if (word == '${invoice}') {
                                    tooltip.push(
                                        rowProps.canCreateInvoice ? (
                                            <Link openInNewTab url={{ module: 'invoices', action: 'view', id: data.bills_id }}>
                                                {data.invoice_number}
                                            </Link>
                                        ) : (
                                            data.invoice_number
                                        )
                                    );
                                } else {
                                    tooltip.push(`${word} `);
                                }
                            });
                        }

                        cells[key] = (
                            <ListCell onlyDisplay editable={false} width={columnWidthMap[key]}>
                                <div className={`${styles.cellTextContainer} ${column.alignRight ? styles.right : styles.left}`}>
                                    {styledTooltipContent ? (
                                        <StyledTooltip placement="right" content={styledTooltipContent}>
                                            <div className={`${styles.invoiceStatus} ${fullyInvoiced || scheduledInvoiced ? styles.invoiced : styles.notInvoiced}`}>{fullyInvoiced || scheduledInvoiced ? <CheckCircle /> : <p>-</p>}</div>
                                        </StyledTooltip>
                                    )
                                    : (
                                        <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} placement="right" title={tooltip}>
                                            <div className={`${styles.invoiceStatus} ${fullyInvoiced || scheduledInvoiced ? styles.invoiced : styles.notInvoiced}`}>{fullyInvoiced || scheduledInvoiced ? <CheckCircle /> : <p>-</p>}</div>
                                        </Tooltip>
                                    )}
                                </div>
                            </ListCell>
                        );
                    } else {
                        cells[key] = (
                            <TextInputCell
                                width={columnWidthMap[key]}
                                textAlign={column.alignRight ? 'right' : 'left'}
                                name={key}
                                editable={false}
                                value={column.currency && data[key] ? rowProps.currencyFormatter.format(data[key]) : data[key]}
                            />
                        );
                    }
                    break;
                }
                case 'added_to_po': {
                    const onPO         = Number(data.added_to_po) === 1;
                    const addedTransl  = this.tr("Added to Purchase Order")
                    const link         = <Link openInNewTab url={{ module: 'purchaseorder', action: 'view', id: data.po_id }}>{`#${data.po_id}`}</Link>;
                    const tooltipTitle = onPO 
                        ? <>{addedTransl} {link}</>
                        : this.tr("Not on any purchase order");

                    cells[key] = [
                        quoteRowTypes.quoteRow, 
                        quoteRowTypes.productRow, 
                        quoteRowTypes.cpqGroupedRow,
                        quoteRowTypes.headerRow
                    ].indexOf(parseInt(this.props.data.type)) > -1 ? (
                        <ListCell onlyDisplay editable={false} width={columnWidthMap[key]}>
                            <div className={`${styles.cellTextContainer} ${column.alignRight ? styles.right : styles.left}`}>
                                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} placement="right" title={tooltipTitle}>
                                    <div className={`${styles.invoiceStatus} ${styles.invoiced}`}>{Number(data.added_to_po) === 1 ? <CheckCircle /> : "-"}</div>
                                </Tooltip>
                            </div>
                        </ListCell>
                    ) : (<ListCell editable={false} />);
                    break;
                }
                case 'name': {
                    if ((data.type == quoteRowTypes.headerRow && !data.cpqHeader) || data.type == quoteRowTypes.descriptionRow) {
                        const className = data.type == quoteRowTypes.headerRow ? styles.headerCell : data.type == quoteRowTypes.descriptionRow ? styles.descriptionCell : '';
                        cells[key] = (
                            <TextInputCell
                                width={columnWidthMap[key]}
                                name="name"
                                cellClassName={className}
                                placeholder={data.type == quoteRowTypes.headerRow ? this.tr('Write a header...') : this.tr('Write a description... (description row)')}
                                value={(data.description == 'Row' ? this.tr(data.description) : data.description) || (data.name == 'Row' ? this.tr(data.name) : data.name)}
                                {...textInputCellProps}
                                focusOnMount={data.isNew && !data.noAutoFocus}
                                textInputProps={{ selectOnFocus: true }}
                                listCellProps={{ ...listCellEditModeProps, className, noInitFocus: !data.isNew }}
                                onEdited={(value) => {
                                    this.setData('name', value);
                                }}
                            />
                        );
                    } else if (data.type == quoteRowTypes.productRow) {
                        const autoCompleteData = rowProps.products;
                        cells[key] = (
                            <ListCell editable={false} onlyDisplay={true}>
                                <InfoSelect
                                    name={'product_' + data.id}
                                    height={230}
                                    tableWidth={1000}
                                    headerHeight={35}
                                    rowHeight={49}
                                    placeholder={this.tr('Select a product...')}
                                    editable={editable && editMode}
                                    noOptionsMessage={this.tr('No options')}
                                    inEditMode={editMode}
                                    options={autoCompleteData}
                                    columns={[
                                        { name: 'code', header: this.tr('Code'), width: 75 },
                                        { name: 'name', header: this.tr('Name'), width: 500 },
                                        { name: 'path', header: this.tr('Category'), width: 350 },
                                        { name: 'unit', header: this.tr('Unit'), width: 75 },
                                    ]}
                                    value={data.product_name}
                                    onChange={(value) => {
                                        this.setData({
                                            product_id: value.id,
                                            product_name: value.name,
                                            cost: value.cost_price,
                                            value: value.income_price,
                                            vat: value.vat,
                                            quantityType: value.unit,
                                            quantity: data.quantity == 0.0 ? 1 : data.quantity,
                                            discountPercentage: value.discount_percent || 0,
                                            updatedKey: key
                                        });
                                    }}
                                />
                            </ListCell>
                        );
                    } else if (data.type == quoteRowTypes.cpqGroupedRow || data.cpqHeader) {
                        const autoCompleteData = rowProps.CPQParents;
                        cells[key] = (
                            <AutoCompleteCell
                                width={columnWidthMap[key]}
                                name={key}
                                selectProps={{ autoFocus: data.isNew && !data.noAutoFocus }}
                                listCellProps={{ ...listCellEditModeProps, noInitFocus: !data.isNew, textAlign: column.alignRight ? 'right' : 'left' }}
                                value={!editMode ? data.product_name : { id: data.product_id, label: data.product_name }}
                                showStringValue
                                autoCompleteData={(autoCompleteData || []).map((wt) => ({ ...wt, value: wt.id }))}
                                searchable={true}
                                editable={true}
                                menuPortalTarget={document.body}
                                tabSelectsValue={false}
                                placeholder={this.tr('Select a CPQ...')}
                                onEdited={async (value) => {
                                    if (data.cpqHeader) {
                                        const { cpqs } = await DataHandler.get({ url: `cpq/childrens`, parentId: value.id, company: this.props.rowProps.company });
                                        const typeMap = {
                                            1: 1,
                                            2: 3,
                                            3: 2,
                                        };
                                        const rows = cpqs.map((row) => {
                                            const rowType = Number(row.type);
                                            const rRow = {
                                                parentId: this.props.data.id,
                                                type: typeMap[rowType],
                                                cost: row.unit_cost,
                                                name: row.description,
                                                description: row.description,
                                                quantity: row.quantity,
                                                quantityType: row.unit,
                                                type_id: row.type_id,
                                                product_name: rowType === 2 ? row.description : undefined,
                                                product_id: rowType === 2 ? row.type_id : undefined,
                                                value: row.selling_price,
                                                vat: row.vat,
                                                workType: row.worktype,
                                                noAutoFocus: true,
                                                hidden_for_print: row.hidden_for_print,
                                            };
                                            return rRow;
                                        });
                                        this.setData({ cpqHeader: false, name: value.label, hidden_for_print: value.hidden_for_print });
                                        rows.forEach((child) => this.props.rowProps.onAddRow(child));
                                    } else if (data.type == quoteRowTypes.cpqGroupedRow) {
                                        this.setData({ name: value.name, product_name: value.label, product_id: value.id });
                                        const sums = { cost: 0, value: 0 };
                                        try {
                                            const { cpqs } = await DataHandler.get({ url: `cpq/childrens`, parentId: value.id, company: this.props.rowProps.company });
                                            cpqs.forEach((row) => {
                                                sums.cost += Number(row.quantity) * Number(row.unit_cost);
                                                sums.value += Number(row.quantity) * Number(row.selling_price);
                                            });
                                            this.setData({ name: value.name, product_name: value.name, product_id: value.id, ...sums });
                                        } catch (e) {
                                            console.error(e);
                                        }
                                    } else {
                                        this.setData({
                                            product_id: value.id,
                                            product_name: value.name,
                                            vat: value.vat,
                                            cost: value.cost_price,
                                            value: value.income_price,
                                            discountPercentage: value.discount_percent,
                                            updatedKey: key
                                        });
                                    }
                                }}
                            />
                        );
                    } else if (data.type == quoteRowTypes.quoteRow) {
                        cells[key] = (
                            <TextInputCell
                                width={columnWidthMap[key]}
                                name="name"
                                placeholder={this.tr('Write a description... (regular row)')}
                                value={editMode && column.currency && data[key] ? rowProps.currencyFormatter.format(data[key]) : data[key]}
                                {...textInputCellProps}
                                focusOnMount={data.isNew && !data.noAutoFocus}
                                textInputProps={{ selectOnFocus: true }}
                                listCellProps={{ ...listCellEditModeProps, noInitFocus: !data.isNew }}
                                onEdited={(value) => {
                                    this.setData('name', value);
                                }}
                            />
                        );
                    } else {
                        cells[key] = (
                            <TextInputCell
                                width={columnWidthMap[key]}
                                textAlign={column.alignRight ? 'right' : 'left'}
                                name={key}
                                editable={false}
                                value={column.currency && data[key] ? rowProps.currencyFormatter.format(data[key]) : data[key]}
                            />
                        );
                    }
                    break;
                }
                case 'code': {
                    if (data.type == quoteRowTypes.productRow) {
                        const autoCompleteData = rowProps.products;
                        const selectedProduct = autoCompleteData.find((f) => f.id == data.product_id);

                        cells[key] = (
                            <ListCell editable={false} onlyDisplay={true}>
                                <InfoSelect
                                    name={'product_' + data.id}
                                    height={230}
                                    tableWidth={1000}
                                    headerHeight={35}
                                    rowHeight={49}
                                    placeholder={this.tr('Product code...')}
                                    editable={editable && editMode}
                                    noOptionsMessage={this.tr('No options')}
                                    inEditMode={editMode}
                                    options={autoCompleteData}
                                    columns={[
                                        { name: 'code', header: this.tr('Code'), width: 75 },
                                        { name: 'name', header: this.tr('Name'), width: 500 },
                                        { name: 'path', header: this.tr('Category'), width: 350 },
                                        { name: 'unit', header: this.tr('Unit'), width: 75 },
                                    ]}
                                    value={selectedProduct?.code || data?.code}
                                    onChange={(value) => {
                                        this.setData({
                                            product_id: value.id,
                                            product_name: value.name,
                                            cost: value.cost_price,
                                            value: value.income_price,
                                            vat: value.vat,
                                            quantityType: value.unit,
                                            quantity: data.quantity == 0.0 ? 1 : data.quantity,
                                            discountPercentage: value.discount_percent || 0,
                                            updatedKey: key
                                        });
                                    }}
                                />
                            </ListCell>
                        );
                        break;
                    } else {
                        cells[key] = <div className="cell" style={{ width: `${columnWidthMap[key]}px`, flex: `${columnWidthMap[key]} 1 0` }} />;
                        break;
                    }
                }
                default:
                    cells[key] = (
                        <TextInputCell
                            width={columnWidthMap[key]}
                            name={key}
                            textAlign={column.alignRight ? 'right' : 'left'}
                            validation={['numeric']}
                            value={(!editable || !editMode) && column.currency && data[column.dataKey || key] ? rowProps.currencyFormatter.format(data[column.dataKey || key]) : data[column.dataKey || key]}
                            textInputProps={{ onFocus: () => rowProps.setActiveCell(undefined), selectOnFocus: true }}
                            {...textInputCellProps}
                            listCellProps={{ ...listCellEditModeProps }}
                            onEdited={(value) => {
                                const val = rowProps.formatNumberInput(value);
                                if (val == undefined) return;
                                const update = {
                                    [key]: val,
                                    updatedKey: key
                                }
                                this.setData(update);
                            }}
                        />
                    );
                    break;
            }
        });
        switch (Number(data.type)) {
            case 0:
            case 2:
                return {
                    menu: cells['menu'],
                    drag: cells['drag'],
                    check: cells['check'],
                    name: cells['name'],
                    targeted: cells['targeted'],
                };
            case 99:
                cells['name'] = editMode && this.props.data.id != 'totalRow' ? (
                    <ListCell editable={false} onlyDisplay={true}>
                        <ContextMenu
                            /*@ts-ignore*/
                            className={styles.addButton}
                            label={
                                <button data-testid={'contextmenu-button-' + data.id} className={styles.addButton}>
                                    <AddCircleOutlined />
                                    {this.tr('Add new')}
                                </button>
                            }
                            /*@ts-ignore*/
                            noExpandIcon
                            popperProps={{ disablePortal: false }}
                        >
                            <MenuItem onClick={() => rowProps.onAddRow({ type: 0 }, this.props.data.parentId)} data-testid={'context-menuitem-header-' + data.id}>
                                {this.tr('Header')}
                            </MenuItem>
                            <MenuItem onClick={() => rowProps.onAddRow({ parentId: this.props.data.parentId, type: 2 })} data-testid={'context-menuitem-description-' + data.id}>
                                {this.tr('Description')}
                            </MenuItem>
                            <MenuItem onClick={() => rowProps.onAddRow({ parentId: this.props.data.parentId, type: 1, workType: 1 })} data-testid={'context-menuitem-row-' + data.id}>
                                {this.tr('Row')}
                            </MenuItem>
                            <MenuItem onClick={() => rowProps.onAddRow({ parentId: this.props.data.parentId, type: 3, workType: 4 })} data-testid={'context-menuitem-product-' + data.id}>
                                {this.tr('Product')}
                            </MenuItem>
                            {!VersionContentManager.isFeatureHidden(this.namespace, 'cpq') && (
                                <MenuItem onClick={() => rowProps.onAddRow({ parentId: this.props.data.parentId, type: 4, workType: 3 })} data-testid={'context-menuitem-cpq-grouped-' + data.id}>
                                    {this.tr('CPQ row (grouped)')}
                                </MenuItem>
                            )}
                            {!VersionContentManager.isFeatureHidden(this.namespace, 'cpq') && (
                                <MenuItem onClick={() => rowProps.onAddRow({ type: 0, cpqHeader: true }, this.props.data.parentId)} data-testid={'context-menuitem-cpq-itemized-' + data.id}>
                                    {this.tr('CPQ row (itemized)')}
                                </MenuItem>
                            )}
                        </ContextMenu>
                    </ListCell>
                ) : (
                    <ListCell editable={false} onlyDisplay={true} />
                );
                break;
        }
        return cells;
    };
}

export default QuoteRow;
