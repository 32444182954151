import React from 'react';
import TaimerComponent from '../TaimerComponent';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import FieldEditSlider, { EditableField, FieldEditSliderProps } from './FieldEditSlider';
import DataHandler from './DataHandler';
import Utils from './Utils';
import styles from './AddActivitySlider.module.scss';
import _, { cloneDeep, uniqBy } from 'lodash';
import ProjectCustomerTreeDropdown from '../projects/ProjectCustomerTreeDropdown';
import { AddContact } from './no-options/AddItemComponents';
import EntityCreatedSnackbar from './EntityCreatedSnackbar';

interface Props extends WithSnackbarProps, FieldEditSliderProps {
    initialSelectionProps?: any;
    afterSave?: (action: string) => void;
}

interface State {
    activity: any;
    open: boolean;
    activityTypes: any[];
    companies: any[];
    users: any[];
    contacts: any[];
}

class AddActivitySlider extends TaimerComponent<Props, State> {
    languageOptions: any;
    contactTypes: any;
    typeIcons: any;
    fieldEditSlider: any = React.createRef();
    constructor(props, context) {
        super(props, context, 'general/AddActivitySlider');

        this.typeIcons = Utils.importAllIcons(require.context('../settings/img/Activity_icons/', false, /\.(png|jpe?g|svg)$/));

        this.state = {
            activity: {
                assigned_to_id: this.context.userObject.usersId,
                ...this.props.initialSelectionProps,
                companies_id: this.props.initialSelectionProps?.companies_id || this.context.userObject.companies_id,
            },
            open: false,
            activityTypes: [],
            companies: [],
            users: [],
            contacts: [],
        };
    }

    componentDidMount = () => {
        if (this.props.open) {
            this.setState({ open: true });
        }
    };

    componentDidUpdate = (_, oldState) => {
        if (oldState.open != this.state.open && this.state.open) {
            this.getActivityTypes();
            this.getCompanies();
            this.getUsers();
            this.getAutoCompleteData();
        }
        if (oldState.activity.companies_id != this.state.activity.companies_id) {
            this.getUsers();
            this.getAutoCompleteData();
        }
    };

    getAutoCompleteData = async () => {
        const { companies_id, projects_id: project_id, customers_id: customer_id } = this.state.activity;
        const data = await DataHandler.post({ url: `activities/dialog/company/${companies_id}/autocompletedata` }, { customer_id, project_id });
        const contacts = data.contacts || [];
        const contact = contacts.find((c) => c.value == this.state.activity.contact)?.value;
        this.setState({ activity: { ...this.state.activity, contact }, contacts: contacts.map((c) => ({ ...c, id: c.value })) });
    };

    getUsers = async () => {
        const { companies_id, projects_id: project } = this.state.activity;
        const users = await DataHandler.get({ url: `dialogs/activityusers`, companies_id, project });
        const assigned_to_id = users.find((u) => u.id == this.state.activity.assigned_to_id)?.id;
        this.setState({ users, activity: { ...this.state.activity, assigned_to_id } });
    };

    getCompanies = async () => {
        const responses = await Promise.all([
            DataHandler.get({ url: 'subjects/companies_with_account_right/customer_crm_write' }),
            DataHandler.get({ url: 'subjects/companies_with_project_right/project_crm_write' }),
        ]);
        const accountCompanies = responses[0] || [];
        const projectCompanies = responses[1] || [];
        const companies = uniqBy([...accountCompanies, ...projectCompanies], 'id').map((c) => ({ ...c, label: c.name, value: c.id }));
        const companies_id = (companies.find((c) => c.id == this.state.activity.companies_id) || companies[0])?.id;
        this.setState({
            companies,
            activity: {
                ...this.state.activity,
                companies_id,
            },
        });
    };

    getActivityTypes = async () => {
        const { activity } = this.state;
        const activityTypes = await DataHandler.get({ url: `settings/activity_types`, companies_id: activity.companies_id });
        const type_id = activity.type_id || (activityTypes.find((a) => a.is_default == 1) || activityTypes[0])?.id;
        const nameKey = Utils.getNameFieldByUserLang(this.context.userObject.language, 'name') || 'name';
        this.setState({
            activity: {
                ...this.state.activity,
                type_id,
            },
            activityTypes: activityTypes.map((a) => ({
                ...a,
                iconComponent: <img alt="" className={styles.activityTypeIcon} src={a.old_icon_set > 0 ? a.icon : this.typeIcons[a.icon]} />,
                label: a[nameKey] || a.name,
                value: a.id,
            })),
        });
    };

    onClose = () => {
        this.setState({ open: false }, () => {
            setTimeout(() => {
                this.props.onClose && this.props.onClose();
            }, 500);
        });
    };

    onSave = async (activity) => {
        const { enqueueSnackbar, closeSnackbar, afterSave } = this.props;
        const creatingSnackbar: any = enqueueSnackbar(this.tr('Creating activity...'), {
            variant: 'info',
            persist: true,
        });
        const data = {
            activity_id: activity.id || -1,
            ...activity,
        };

        this.onClose();
        const hasProject = Number(activity.projects_id || 0) > 0;
        try {
            const response = await DataHandler.post({ url: `activities/save` }, { activity: data });
            closeSnackbar(creatingSnackbar);
            if (!activity.id || activity.id == -1) {
                this.context.functions.sendMixpanelEvent('create_activity', {
                    'origin_point': this.props.initialSelectionProps?.origin_point,
                });
                this.context.functions.sendMixpanelPeople('set_once', {
                    'first_create_activity_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('set', {
                    'last_create_activity_start': new Date().toISOString(),
                });
                this.context.functions.sendMixpanelPeople('increment', {
                    'lifetime_create_activity': 1,
                });
            }
            const createdSnackbar = enqueueSnackbar(
                <EntityCreatedSnackbar
                    type="activity"
                    onClose={() => closeSnackbar(createdSnackbar)}
                    actions={[
                        {
                            key: 'view_entity',
                            onClick: () => {
                                this.context.functions.updateView({
                                    module: hasProject ? 'projects' : 'customers',
                                    action: 'view',
                                    id: hasProject ? activity.projects_id : activity.customers_id,
                                });
                            },
                            isHidden: () => !this.context.functions.hasPrivilege(hasProject ? 'projects' : 'customers', 'read'),
                            label: hasProject ? 'View project' : 'View account',
                        },
                        ...(hasProject
                            ? [
                                  {
                                      key: 'hours',
                                      onClick: () => {
                                          this.context.functions.addHours({
                                              customer: {
                                                  id: activity.customers_id,
                                              },
                                              unit: {
                                                  id: 0,
                                              },
                                              project: {
                                                  name: '',
                                                  label: '',
                                                  id: activity.projects_id,
                                                  value: activity.projects_id,
                                              },
                                          });
                                      },
                                      isHidden: () => !this.context.functions.hasPrivilege('workhours', 'write'),
                                      label: 'Add hour entry',
                                  },
                                  {
                                      key: 'task',
                                      onClick: () => {
                                          this.context.functions.addResource({
                                              projects_id: activity.projects_id,
                                              origin_point: "add_activity_slider",
                                          });
                                      },
                                      isHidden: () =>
                                          !(this.context.functions.hasPrivilege('projects', 'project_resourcing_write') || this.context.functions.hasPrivilege('projects', 'own_resourcing_write')),
                                      label: 'Add task',
                                  },
                              ]
                            : []),
                        {
                            key: 'activity',
                            onClick: () => {
                                this.context.functions.openActivitySlider({
                                    customers_id: activity.customers_id,
                                    projects_id: activity.projects_id,
                                });
                            },
                            isHidden: () => !this.context.userObject.hasCrmWritePermission,
                            label: 'Add activity',
                        },
                        {
                            key: 'contact',
                            onClick: () => {
                                this.context.functions.addContact({
                                    customers_id: activity.customers_id,
                                });
                            },
                            isHidden: () => !this.context.functions.hasPrivilege('persons', 'read'),
                            label: 'Add contact',
                        },
                    ]}
                />,
                {
                    variant: 'default',
                    autoHideDuration: 5000,
                    className: 'entityCreatedSnackbar',
                }
            );
            setTimeout(() => {
                window.dispatchEvent(new Event('activitySaved'));
                afterSave && afterSave(activity.id && activity.id != -1 ? 'update' : 'new');
            }, 1000);
        } catch (e) {
            console.error(e);
            closeSnackbar(creatingSnackbar);
            enqueueSnackbar(this.tr('Creating activity failed!'), {
                variant: 'error',
            });
        }
    };

    onItemChanged = (activity) => this.setState({ activity });

    onSelectEntity = (e) => {
        const { data } = e;
        const activity = {
            ...this.state.activity,
            customers_id: data.customer,
            projects_id: data.project,
            contact: undefined,
        };
        const fields = this.getFields();
        this.setState({ activity }, () => {
            const field = fields.find((f) => f.key == 'project_customer');
            this.fieldEditSlider.current && this.fieldEditSlider.current.checkFieldValidity(field);
            this.getUsers();
            this.getAutoCompleteData();
        });
    };

    renderTreeDrop = (item) => {
        return (
            <ProjectCustomerTreeDropdown
                label={this.tr('Account / Project') + '*'}
                activityDialog={true}
                name="project-customer-treeselect"
                optionLabelKey="label"
                customer={item.customer}
                project={item.project}
                queryParameters={{ company: item.companies_id, type: 'crm_write_both' }}
                value={Number(item.projects_id) > 0 ? item.projects_id : Number(item.customers_id) > 0 ? -Number(item.customers_id) : undefined}
                onSelect={this.onSelectEntity}
            />
        );
    };

    getFields = () => {
        const { activity, activityTypes, companies, users, contacts } = this.state;
        const {
            taimerAccount: { isMulticompany },
        } = this.context;
        const fields: EditableField[] = [
            {
                key: 'companies_id',
                title: this.tr('Company'),
                type: 'data_select',
                options: companies,
                required: true,
                isHidden: () => !isMulticompany,
            },
            {
                key: 'type_id',
                title: this.tr('Activity type'),
                type: 'data_select',
                options: activityTypes,
                required: true,
            },
            {
                key: 'description',
                title: this.tr('Description'),
                type: 'textarea',
                required: true,
            },
            {
                key: 'date',
                title: this.tr('Remind date'),
                type: 'date',
            },
            {
                key: 'project_customer',
                title: this.tr('Account / Project'),
                required: true,
                checkValidity: () => {
                    return !!this.state.activity.customers_id || !!this.state.activity.projects_id;
                },
                customComponent: this.renderTreeDrop,
            },
            {
                key: 'assigned_to_id',
                title: this.tr('User'),
                type: 'data_select',
                options: users,
            },
            {
                key: 'contact',
                title: this.tr('Contact'),
                type: 'data_select',
                options: contacts,
                addNoneOption: true,
                hideIfEmpty: true,
                noOptions: AddContact,
                additionalProps: {
                    companies_id: activity.companies_id,
                    customers_id: activity.customers_id,
                },
                onItemCreated: (contact) => {
                    const contacts = cloneDeep(this.state.contacts);
                    contacts.unshift(contact);
                    this.setState({
                        contacts,
                        activity: {
                            ...this.state.activity,
                            contact: contact.id,
                        },
                    });
                },
            },
        ];

        return fields;
    };

    onCreateNew = () => {
        const { activity } = this.state;
        this.onClose();
        setTimeout(() => {
            this.context.functions.openActivitySlider({
                companies_id: activity.companies_id,
                type_id: activity.type_id,
                description: '',
                customers_id: activity.customers_id,
                projects_id: activity.projects_id,
            });
        }, 1000);
    };

    onMarkAsDone = (createNewAfter = false) => {
        const { activity } = this.state;
        this.onSave({
            ...activity,
            ready: 1,
        });
        if (createNewAfter) {
            this.onCreateNew();
        } else {
            this.onClose();
        }
    };

    render() {
        const { activity, open } = this.state;
        return (
            <FieldEditSlider
                ref={this.fieldEditSlider}
                className={styles.addActivitySlider}
                open={open}
                onClose={this.onClose}
                title={activity.id && activity.id != -1 ? this.tr('Edit activity') : this.tr('Add activity')}
                onSave={this.onSave}
                onItemChanged={this.onItemChanged}
                item={activity}
                fields={this.getFields()}
                options={
                    activity.id &&
                    activity.id != -1 &&
                    (Number(activity.ready || 0) > 0
                        ? [
                              {
                                  key: 'createNew',
                                  label: this.tr('Create new activity'),
                                  onClick: this.onCreateNew,
                              },
                          ]
                        : [
                              {
                                  key: 'markAsDone',
                                  label: this.tr('Mark as done'),
                                  onClick: () => this.onMarkAsDone(),
                              },
                              {
                                  key: 'markAsDoneAndCreateNew',
                                  label: this.tr('Mark as done and create new'),
                                  onClick: () => this.onMarkAsDone(true),
                              },
                          ])
                }
            />
        );
    }
}

export default withSnackbar(AddActivitySlider);
