import React from 'react';
/*context*/
import { SettingsContext } from '../SettingsContext';

/* local components */
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import ContextMenu from '../general/ContextMenu';
import AutoCompleteCell from '../list/cells/AutoCompleteCell';
import TextInputCell from '../list/cells/TextInputCell';
import LinkListCell from '../list/LinkListCell';
import List from '../list/List';
import ListCell from '../list/ListCell';
import PropsOnlyListRow from '../list/PropsOnlyListRow';
import TaimerComponent from '../TaimerComponent';
import FieldEditSlider from './../general/FieldEditSlider';

import { ReactComponent as RemoveIcon } from './../general/icons/remove.svg';

/*material-ui*/
import { MenuItem } from '@mui/material';

/*datahandler*/
import DataHandler from '../general/DataHandler';

/*extras*/
import { Business, CopyAll, Email, Phone } from '@mui/icons-material';
import { languages } from 'country-data';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import validator from 'validator';
import cardStyles from '../general/styles/CardStyles.module.scss';
import styles from './TabPersonalDetails.module.scss';

import cloneDeep from 'lodash/cloneDeep';
import Skeleton from 'react-loading-skeleton';
import { validEmail } from "../dialogs/Validate.js";
import Link from '../general/Link';
import AddTag from '../general/no-options/AddTag';
import SliderFieldGroup from '../general/SliderFieldGroup';
import TaimerAvatar from '../general/TaimerAvatar';
import ToggleableContainerList from '../general/ToggleableContainerList';

export const validators = {
    email: (e) => !e.length || validator.isEmail(e),
};

const customertype = [
    { id: '1', name: 'Customer', value: '1', label: 'Customer' },
    { id: '2', name: 'Sub Contractor', value: '2', label: 'Sub Contractor' },
];

class RelationSlider extends TaimerComponent {
    static defaultProps = {
        afterSave: () => {},
        closeSlider: () => {},
        relationExists: () => {},
        showRelationExistsSnackbar: () => {},
        contactId: -1,
        itemBase: {}
    };

    constructor(props, context) {
        super(props, context, "contacts/RelationSlider");

        this.state = {
            item: {
                companyId: this.context.userObject.companies_id,
                accountId: -1,
                contactType: 1,
                title: "",
                position: -1,
                email: "",
                phone: "",
                ...props.itemBase
            },
            saving: false,
            relationExists: false
        };

        this.contactRelationTypes = [
            { id: null, name: '', value: null, label: '' },
            { id: '1', name: this.tr('Contact'), value: '1', label: this.tr('Contact') },
            { id: '2', name: this.tr('Partner'), value: '2', label: this.tr('Partner') },
        ];
    }


    sortOptions = (options) => {
        return options.sort((a, b) => {
            if(a.label < b.label) {
                return -1;
            }

            if(a.label > b.label) {
                return +1;
            }

            return 0;
        });
    };

    save = (data) => {
        const {
            contactId,
            closeSlider,
            afterSave,
            relationExists,
            // showRelationExistsSnackbar
        } = this.props;

        if(relationExists(data)) {
            // Can't show this thing from under the slider..
            // showRelationExistsSnackbar();

            this.setState({
                relationExists: true
            });

            return;
        }

        const postData = {
            customers_id: data.accountId,
            companies_id: data.companyId,
            customer_partner: data.contactType,
            email: data.email,
            phone: data.phone,
            title: data.title,
            position: data.position,
            mailing_lists: (data?.emailLists || []).map(el => el.id)
        };

        this.setState({
            saving: true,
            relationExists: false
        }, async () => {
            // For some reason the url says update_relation, 
            // but we're using it to create relations too.
            await DataHandler.post({ url: `contacts/${contactId}/update_relation/-1` }, { data: postData });

            afterSave();
            closeSlider();
        });
    };


    handleItemChange = item => {
        const {
            relationExists
        } = this.props;

        this.setState({ 
            item,
            relationExists: relationExists(item)
        });
    };


    render() {
        const { 
            item,
            saving,
            relationExists
        } = this.state;
        const {
            companyId
        } = item;
        const { 
            tr 
        } = this;
        const {
            closeSlider
        } = this.props;
        const {
            companies,
            customersMap
        } = this.props.sharedData;
        const {
            // titles,
            positions,
            all_mailing_lists
        } = this.props.sharedData.autoCompleteData;

        return (
            <FieldEditSlider 
                item={item}
                open={true} 
                saving={saving}
                title={tr("Add account association")}
                onItemChanged={this.handleItemChange}
                onSave={this.save}
                onClose={closeSlider}
                fields={[
                    {
                        key: 'companyId',
                        required: true,
                        title: this.tr('Company'),
                        type: 'data_select',
                        error: relationExists,
                        options: this.sortOptions(companies).map(c => {
                            c.label = c.name;

                            return c;
                        }),
                    },
                    {
                        key: 'accountId',
                        required: true,
                        title: this.tr('Account'),
                        type: 'data_select',
                        error: relationExists,
                        options: this.sortOptions(customersMap[companyId]).map(c => {
                            c.label = c.name;

                            return c;
                        }),
                    },
                    {
                        key: 'contactType',
                        required: true,
                        title: this.tr('Contact type'),
                        type: 'data_select',
                        error: relationExists,
                        errorMessage: relationExists ? tr("This relation already exists") : "",
                        options: this.contactRelationTypes,
                    },
                    {
                        key: 'title',
                        required: false,
                        title: this.tr('Title'),
                        type: 'text',
                    },
                    {
                        key: 'email',
                        required: false,
                        title: this.tr('Email'),
                        type: 'text',
                        validation: "email"
                    },
                    {
                        key: 'phone',
                        required: false,
                        title: this.tr('Phone'),
                        type: 'text',
                    },
                    {
                        key: 'position',
                        required: false,
                        title: this.tr('Position'),
                        type: 'data_select',
                        options: this.sortOptions(positions),
                    },
                    {
                        key: 'emailLists',
                        required: false,
                        title: this.tr('Email lists'),
                        type: 'select',
                        isMulti: true,
                        options: this.sortOptions(all_mailing_lists).filter(m => {
                            return parseInt(m.companies_id) === parseInt(item.companyId)
                        })
                    },
                ]}
            />
        );
    }
    
}

class CustomerRelationRow extends PropsOnlyListRow {
    constructor(props, subtypeState = {}, rowTypeConfig = {}) {
        super(props, {}, {}, 'contacts/CustomerRelationRow');

        this.cellEdited = this.cellEdited.bind(this);
        this.saveNew = this.saveNew.bind(this);

        this.contactRelationTypes = [
            { id: null, name: '', value: null, label: '' },
            { id: '1', name: this.tr('Contact'), value: '1', label: this.tr('Contact') },
            { id: '2', name: this.tr('Partner'), value: '2', label: this.tr('Partner') },
        ];
    }

    shouldComponentUpdate(nextProps, nextState) {
        return super.shouldComponentUpdate(nextProps, nextState) || this.props.sharedData !== nextProps.sharedData;
    }

    saveNew() {
        this.create(this.props.data);
    }

    defineClassName() {
        return "contactCustomerRelationRow";
    }

    cellEdited(name, value) {
        this.setDataAndUpdate(name, value);
    }

    defineCells() {
        const {
            tr
        } = this;
        const {
            data,
            attributes,
            listRef,
            rowProps
        } = this.props;

        const {
            checkPrivilege
        } = rowProps;

        const {
            companies_id,
            title,
            positions_id,
            phone,
            email,
            mailing_lists,
        } = data;

        const contact = this.props.sharedData.contact;
        const companies = this.props.sharedData.companies;

        const {
            // titles,
            positions,
            emailLists
        } = this.props.sharedData;

        const contactRelationTypes = data.id < 0 ? this.contactRelationTypes : this.contactRelationTypes.filter((t) => t.id !== null);

        const menuPlacement = 'bottom';

        const customerReadPriv = checkPrivilege("customers", "read");

        const cells = {
            context:
                data.id > 0 ? (
                    <ListCell onlyDisplay={true}>
                        {contact > 0 && data.companies_id == this.context.userObject.companies_id && (
                            <ContextMenu
                                disablePortal={false}
                                className="cell row-menu"
                                label={<MoreHoriz />}
                                buttonProps={{ className: 'action-menu' }}
                                style={{ width: "100%" }}
                                // width={this.props.columnWidthMap['context']}
                                // style={{ width: this.props.columnWidthMap['context'] + 'px', flex: this.props.columnWidthMap['context'] + ' 1 0px' }}
                                noExpandIcon>
                                <MenuItem
                                    className="delete"
                                    onClick={() => {
                                        this.delete();
                                    }}>
                                    <RemoveIcon className="Delete" />&nbsp;
                                    {this.tr('Delete')}
                                </MenuItem>
                            </ContextMenu>
                        )}
                    </ListCell>
                ) : (
                    <ListCell onlyDisplay={true}>
                        {contact > 0 && <CheckCircle titleAccess="Save" className="saveNewRowCheckCircleIcon" onClick={() => this.saveNew()} />}
                        {contact > 0 && <Cancel titleAccess="Cancel" className={contact > 0 && 'cancelIcon'} onClick={() => this.delete(this.state.data)} />}
                    </ListCell>
                ),

            companies_id: (
                <AutoCompleteCell
                    autoCompleteData={companies.map(c => {
                        c.value = c.id;

                        return c;
                    })}
                    menuPlacement={menuPlacement}
                    name="companies_id"
                    value={data.companies_id || this.context.functions.getCompany('persons', 'read')}
                    editable={companies.length > 1}
                    listCellProps={
                        data.initialRow != undefined && {
                            inEditMode: !!data.initialRow,
                        }
                    }
                    menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                    onEdit={(value) => {
                        this.cellEdited('companies_id', value.id);
                    }}
                />
            ),

            customers_id: (
                <AutoCompleteCell
                    autoCompleteData={(this.props.sharedData.customersMap 
                        ? this.props.sharedData.customersMap[data.companies_id] 
                        : []).map((c) => ({ ...c, value: c.id }))
                        .sort((a, b) => {
                            const aName = a?.name?.toLowerCase() || "";
                            const bName = b?.name?.toLowerCase() || "";

                            if(aName < bName) {
                                return -1;
                            }

                            if(aName > bName) {
                                return 1;
                            }

                            return 0;
                        })}
                    menuPlacement={menuPlacement}
                    name="customers_id"
                    value={data.customers_id}
                    editable={customerReadPriv}
                    listCellProps={{
                        inEditMode: !!data.initialRow,
                        presentValue: !customerReadPriv 
                            ? () => "-"
                            : undefined
                    }}
                    menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                    onEdit={(value) => {
                        this.cellEdited('customers_id', value.id);
                    }}
                />
            ),

            contact_type: (
                <AutoCompleteCell
                    autoCompleteData={contactRelationTypes}
                    menuPlacement={menuPlacement}
                    name="customer_partner"
                    value={data.customer_partner}
                    menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                    editable={true}
                    listCellProps={
                        data.initialRow != undefined && {
                            inEditMode: !!data.initialRow,
                        }
                    }
                    onEdit={(value) => {
                        this.cellEdited('customer_partner', value.id);
                    }}
                />
            ),
            title: <TextInputCell 
                name="title"
                value={title}
                onEdit={this.cellEdited}
                />,
            // title: <AutoCompleteCell 
                // menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                // menuPlacement={menuPlacement}
                // value={titles_id}
                // autoCompleteData={titles} 
                // onEdit={(value) => {
                    // this.cellEdited('titles_id', value.id);
                // }} />,
            email: <TextInputCell 
                value={email} 
                listCellType={LinkListCell}
                listCellProps={{
                    inEditMode: attributes?.invalidEmail,
                    showErrorBorder: attributes?.invalidEmail || false,
                    urlHandler: value => `mailto:${value}`,
                    valueHandler: value => value
                }}
                onEdit={async (value) => {
                    if(!validEmail(value)) {
                        await listRef.editAttributes({ invalidEmail: true }, data.id);
                        listRef.editData({ email: value }, data.id);

                        this.props.rowProps.enqueueSnackbar(tr("Please enter a valid email address"), { variant: "error" });

                        return;
                    }

                    await listRef.editAttributes({ invalidEmail: false }, data.id);

                    this.cellEdited('email', value);
                }} 
                />,
            phone: <TextInputCell 
                value={phone} 
                onEdit={(value) => {
                    this.cellEdited('phone', value);
                }} />,
            position: <AutoCompleteCell 
                menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                menuPlacement={menuPlacement}
                value={positions_id}
                autoCompleteData={positions} 
                onEdit={(value) => {
                    this.cellEdited('positions_id', value.id);
                }} />,
            mailing_lists: <AutoCompleteCell 
                value={emailLists.filter(el => {
                    return mailing_lists.indexOf(el.id) > -1;
                })}
                multiple={true}
                menuPortalTarget={this.props.rowProps.outerContainerRef.current}
                menuPlacement={menuPlacement}
                autoCompleteData={emailLists.filter(list => {
                    return parseInt(list.companies_id) === parseInt(companies_id);
                })} 
                onEdit={(values) => {
                    this.cellEdited('mailing_lists', values.map(v => v.id));
                }} />,
        };
        return cells;
    }
}

class ContactsProjectRow extends PropsOnlyListRow {
    constructor(props) {
        super(props, {}, {}, 'contacts/ContactsProjectRow');
    }

    defineClassName() {
        return 'contacts-project-row';
    }

    defineCells() {
        const data = this.props.data;

        const cells = {
            project: (
                <LinkListCell
                    name="project"
                    urlHandler={(value) => `index.html?module=projects&action=view&id=${this.props.data.projects_id}`}
                    value={data['name'] + ' (' + data['project_id'] + ')'}
                    editable={false}
                    width={this.props.columnWidthMap['project']}
                    noTab={true}
                />
            ),
            tags: <ListCell name="tags" value={data.tags} editable={false} showTooltipForOverflownText={true} width={this.props.columnWidthMap['tags']} />,
        };
        return cells;
    }
}

class TabPersonalDetails extends TaimerComponent {
    static contextType = SettingsContext;

    state = {
        autoCompleteDataDone: false,
        data: [],
        errors: {},
        companies: [],
        contactRelationData: [],
        customerlist: [],
        customerTreeList: [],
        positionOptions: [],
        contactRelationOption: [],
        langs: [],
        projects: [],
        tagPoolSettingsData: {
            use_separate_tag_pools: 0,
            create_tags_only_from_settings: 1,
        },
        all_contacts: [],
    };

    constructor(props, context) {
        super(props, context, 'contacts/TabPersonalDetails');

        this.outerContainerRef = React.createRef();
        this.customerRelationList = React.createRef();

        this.showContactNameInHeader = false;
        this.autoCompleteData = {};

        this.relationTemplate = {
            companyId: -1,
            accountId: -1,
            contactType: 1,
            title: -1
        };

        this.detailsSections = [
            {
                key: 'details',
                title: this.tr('Contact details'),
                initiallyOpen: true,
            },
            {
                key: 'address',
                title: this.tr('Address'),
                initiallyOpen: true,
            },
        ];

        this.contactRelationTypes = [
            { id: null, name: '', value: null, label: '' },
            { id: '1', name: this.tr('Contact'), value: '1', label: this.tr('Contact') },
            { id: '2', name: this.tr('Partner'), value: '2', label: this.tr('Partner') },
        ];

        this.newContact = {
            id: -1,
            lastname: '',
            firstname: '',
            on_mailing_lists: [],
            vacance: {
                id: 0,
            },
            branch_of_business: '',
            title: '',
            customer_types: '',
            customersMap: {},
            email: '',
            phone: '',
            modified_by: '',
            contact_owner: {
                id: context.userObject.usersId,
            },
            lang: '',
            other: '',
            tags: [],
            street_address: '',
            postal_code: '',
            city: '',
            state: '',
            country: '',
            contact_history: [
                {
                    id: 1,
                    initialRow: true,
                    companies_id: props.company || context.userObject.companies_id,
                    customers_id: '',
                    customer_partner: null,
                },
            ],
            contactSettings: {},
        };

        this.state.data.companies_id = this.state.data.companies_id ? this.state.data.companies_id : context.userObject.companies_id;
    }
    componentDidMount = async () => {
        super.componentDidMount();

        let mode = 'none';
        let contact = this.props.id;
        let company = this.props.company;

        const contactSettings = await DataHandler.get({ url: `settings/company/${company}/contact_settings` });

        this.fetchAutoCompleteData().done(() => {
            DataHandler.get({ 
                url: 'get_contacts', 
                mode: mode, 
                contact: contact, 
                company: this.props.company ? this.props.company : this.state.data.companies_id 
            }).done((data) => {
                this.setState({ 
                    contactSettings: contactSettings, 
                    data: data.contacts[0] ? data.contacts[0] : {}, 
                    autoCompleteDataDone: true 
                });
            });
            this.fetchContactRelations();
            this.fetchProjects();
        });
        DataHandler.get({ url: `subjects/companies/persons/read` }).done((companies) => this.setState({ companies }));
    };

    fetchAutoCompleteData = () => {
        const company = this.props.company || this.context.userObject.companies_id;

        return DataHandler.get({ url: `autocomplete_data/contacts/${company}` }).done((autoCompleteData) => {
            autoCompleteData.modified_by = autoCompleteData.modified_by.map((ac) => {
                if (ac.companies_id < 1) ac.name = `${ac.name} (${this.tr('freelancer')})`;
                return ac;
            });

            autoCompleteData.users = autoCompleteData.users.map((ac) => {
                if (ac.companies_id < 1) ac.name = `${ac.name} (${this.tr('freelancer')})`;
                return ac;
            });

            this.autoCompleteData = autoCompleteData;

            DataHandler.get({ url: `settings/company/${company}/langs` }).done((languages) => {
                this.setState({ langs: languages, positionOptions: autoCompleteData['positions'], all_contacts: autoCompleteData['users'] });
                this.forceUpdate();
            });

            this.getTagSettings(company);
        });
    };

    getTagSettings = (company) => {
        DataHandler.get({ url: `settings/company/${company}/tagSettings` })
            .done((response) => {
                if (response.tagPoolSettingsData) this.setState({ tagPoolSettingsData: response.tagPoolSettingsData });
            })
            .fail((response) => {
                console.log('Error', response);
            });
    };

    onContactEdited = (data, exclude = []) => {
        const fieldMap = {
            "primary_title": "title",
            "primary_vacance": "vacance",
            "primary_phone": "phone",
            "primary_email": "email",
        };

        Object.keys(fieldMap).forEach(key => {
            data[fieldMap[key]] = data[key];
        });

        const currentData = this.state.data;

        if(data.on_mailing_lists) {
            data.on_mailing_lists = data.on_mailing_lists.map((ml) => ml.id || ml);
        }

        const formattedData = {
            ...currentData,
            ...data
        };

        formattedData.vacance = formattedData.vacance?.id || formattedData.vacance;

        // This module.
        delete formattedData.relation_id;

        // Juuh.
        exclude.forEach(f => delete formattedData[f]);

        this.setState({ 
            data: formattedData, 
            companies_id: data.companies_id 
        }, () => {
            DataHandler.post({ url: `contacts_save/${data.id}` }, formattedData).done(() => {
                this.fetchData().done(() => this.fetchAutoCompleteData());
            });
        });

    };

    fetchData = () => {
        const {
            id
        } = this.state.data;

        this.fetchContactRelations();

        return DataHandler.get({ 
            url: 'get_contacts', 
            mode: 'none', 
            contact: id, 
            company: this.props.company 
                ? this.props.company 
                : this.state.data.companies_id 
        }).done((data) => {
            this.setState({ data: data.contacts[0], autoCompleteDataDone: true });
        });
    };

    fetchContactRelations = () => {
        let { 
            id 
        } = (this.state.data.id
            ? this.state.data
            : this.props);

        if(!id) {
            id = -1;
        }

        Promise.all([
            DataHandler.get({ url: `contacts/customer_relations/${id}` }),
            DataHandler.get({ url: `contacts/relation_options` })
        ]).then(([relationData, optionData]) => {
            this.setState({ 
                contactRelationData: relationData.contact_relations, 
                customerlist: relationData.customers_list,
                contactRelationOption: optionData.crosettings
            }, () => {
                this.fetchContactCustomerTreeList(this.props.company ? this.props.company : this.state.data.companies_id);    
            });
        });
    };

    fetchProjects() {
        const contactId = this.state.data.id ? this.state.data.id : this.props.id;

        DataHandler.get({ url: `contacts/${contactId}/projects` }).then((projects) => {
            this.setState({ projects });
        });
    }

    fetchContactCustomerTreeList = (c) => {
        let contact = this.state.data.id ? this.state.data.id : this.props.id;

        DataHandler.get({ url: `get_corporationTreeCustomers/${contact}` }).done((data) => {
            this.setState({
                customersMap: data.customers_map,
            });
        });
    };

    contactHistory = (data) => {
        const contact_history = cloneDeep(this.state.data.contact_history);
        const foundIndex = contact_history.findIndex((x) => x.id == data.id);
        if (foundIndex != -1) {
            contact_history[foundIndex] = data;
        }
        this.setState({ data: { ...this.state.data, contact_history } });
    };

    relationExists = (d) => {
        const current = this.customerRelationList.current.getData();

        return current.findIndex(r => {
            return parseInt(d.companyId) === parseInt(r.companies_id)
                && parseInt(d.accountId) === parseInt(r.customers_id)
                && parseInt(d.contactType) === parseInt(r.customer_partner);
        }) > -1;
    };

    showRelationExistsSnackbar = () => {
        this.props.enqueueSnackbar(this.tr("This type of relation already exists"), {
            variant: "error",
            anchorOrigin: {
                vertical: "top",
                horizontal: "left"
            }
        });
    };

    renderContentForDetailsSection = (section) => {
        const { data, all_contacts, positionOptions, contactSettings = {} } = this.state;
        const {
            functions: { checkPrivilege, hasPrivilege },
        } = this.context;
        const writePrivileges = checkPrivilege('persons', 'read');
        data.tags = data.tags || []; /* WHY I HAVE TO DO THIS?? */
        const tn = _.uniqBy(_.map(data.tags, (t) => t.name));

        const taimerLangs = [
            'fin',
            'swe',
            'nor',
            'dan',
            'icl',
            'est',
            'lit',
            'lav',
            'eng',
            'deu',
            'ita',
            'fra',
            'spa',
            'por',
            'rus',
            'pol',
            'bul',
            'hrv',
            'ces',
            'ell',
            'hun',
            'gle',
            'mlt',
            'ron',
            'slk',
            'slv',
            'zho',
            'jpn',
            'nld',
            'ara',
            'hin',
            'pan',
            'ind',
        ];
        const languageOptions = languages.all
            .filter((l) => taimerLangs.indexOf(l.alpha3) > -1)
            .filter((ln) => ['Panjabi', 'Flemish', 'Castilian', 'Moldavian'].indexOf(ln.name) == -1)
            .map((language) => {
                if (language.alpha3 === 'ell') return { id: language.alpha3, value: language.alpha3, name: this.tr('Greek'), label: this.tr('Greek') };
                else return { id: language.alpha3, value: language.alpha3, name: this.tr(language.name), label: this.tr(language.name) };
            });

        let mlOptions = [];
        let currentMl = [];
        let ctOptions = [];
        let currentCt = [];
        let mailList = this.autoCompleteData['mailing_list']?.filter((ml) => data['on_mailing_lists'] && data['on_mailing_lists'].indexOf(ml.id) > -1) || [];
        let contactOwner = this.autoCompleteData['users']?.find((co) => data['contact_owner'] === co.id) || {};
        let customerType = customertype.filter((ct) => data['customer_types'] && data['customer_types'].indexOf(ct.id) > -1);
        let tagOptions = this.autoCompleteData['tags']?.filter((t) => Number(t.disabled) < 1 && tn.indexOf(t.name) === -1);
        if (this.newContact['contact_owner']['id'] == 0 && (!this.props.id || this.props.id == -1) && !contactOwner['id']) {
            contactOwner['id'] = this.context.userObject.usersId;
        }

        currentMl = _.map(mailList, 'id');
        (this.autoCompleteData['mailing_list'] || []).forEach((element) => {
            if (currentMl.indexOf(element.id) === -1) mlOptions.push(element);
        });

        currentCt = _.map(customerType, 'id');
        customertype.forEach((element) => {
            if (currentCt.indexOf(element.id) === -1) ctOptions.push(element);
        });

        const {
            taimerAccount: { showState },
        } = this.context;

        const {
            contactRelationData,
            companies,
            titles
        } = this.state;

        const usMode = /* (data['state']) || */ showState;

        switch (section) {
            case 'details':
                return (
                    <SliderFieldGroup
                        items={[data]}
                        editingDisabled={!writePrivileges}
                        editButtonLocation="top"
                        sliderEditTitle={this.tr('Edit contact')}
                        onItemSaved={this.onContactEdited}
                        fields={[
                            {
                                key: 'primary_relations_id',
                                title: this.tr('Primary account'),
                                type: 'data_select',
                                disabled: !writePrivileges,
                                options: contactRelationData.map(cr => {
                                    const companyName = companies.find(c => {
                                        return parseInt(c.id) === parseInt(cr.companies_id);
                                    })?.name || "";

                                    const customerPartner = cr.customer_partner === "1"
                                        ? this.tr("Contact")
                                        : this.tr("Partner");

                                    console.log("taimer-debug", cr);

                                    const customerNamePart = cr.customers_name_priv
                                        ? `${cr.customers_name_priv} / `
                                        : "";

                                    cr.label = `${customerNamePart}${companyName} (${customerPartner})`;

                                    return cr;
                                }),
                                setOtherValuesWithSelection: (_, value) => {
                                    const relation = contactRelationData.find(cr => cr.id === value);

                                    return {
                                        title: relation.title,
                                        vacance: relation.positions_id,
                                        phone: relation.phone,
                                        email: relation.email
                                    };
                                },
                            },
                            {
                                key: 'firstname',
                                title: this.tr('First Name'),
                                disabled: !writePrivileges,
                                required: true,
                            },
                            {
                                key: 'lastname',
                                title: this.tr('Surname'),
                                disabled: !writePrivileges,
                                required: true,
                            },
                            {
                                key: 'primary_email',
                                title: this.tr('Email'),
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_email === '1',
                                validation: 'email',
                            },
                            {
                                key: 'primary_phone',
                                title: this.tr('Phone'),
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_phone === '1',
                                validation: 'phone',
                            },
                            {
                                key: 'primary_title',
                                title: this.tr('Title'),
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_title === '1',
                                type: 'text',
                            },
                            {
                                key: 'primary_vacance',
                                title: this.tr('Position'),
                                type: 'data_select',
                                options: positionOptions,
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_position === '1',
                                addNoneOption: true,
                            },
                            {
                                key: 'lang',
                                title: this.tr('Language'),
                                type: 'data_select',
                                options: languageOptions,
                                disabled: !writePrivileges,
                                required: contactSettings.contacts_force_language === '1',
                            },
                            {
                                key: 'contact_owner',
                                title: this.tr('Contact owner'),
                                type: 'data_select',
                                options: all_contacts,
                                required: contactSettings.contacts_force_owner === '1',
                                disabled: !writePrivileges,
                                isHidden: () => !hasPrivilege('persons', 'contact_owner_read'),
                            },
                            {
                                key: 'other',
                                title: this.tr('Other'),
                                disabled: !writePrivileges,
                            },
                            {
                                key: 'tags',
                                title: this.tr('Tags'),
                                type: 'select',
                                isMulti: true,
                                options: tagOptions,
                                disabled: !writePrivileges,
                                noOptions: AddTag,
                                additionalProps: {
                                    company: data.companies_id,
                                    tagType: 3,
                                },
                            },
                            // {
                                // key: 'on_mailing_lists',
                                // title: this.tr('Email lists'),
                                // type: 'select',
                                // isMulti: true,
                                // options: (this.autoCompleteData['mailing_list'] || []).map((ml) => ({ ...ml, label: ml.name, value: ml.id })),
                                // formatMultiValue: (value, options) => options.find((o) => (value.id ? value.id == o.id : o.id == value)),
                                // strictMultiValue: true,
                                // disabled: !writePrivileges,
                                // Disabled due to the changes in TR-274: from now on
                                // contact's relations, not contacts themselves, are
                                // added to mailing lists.
                                // disabled: true,
                            // },
                            {
                                key: 'created',
                                title: this.tr('Created'),
                                type: 'date',
                                disabled: true,
                            },
                            {
                                key: 'modified',
                                title: this.tr('Modified'),
                                type: 'date',
                                disabled: true,
                            },
                            {
                                key: 'modified_by',
                                title: this.tr('Modified By'),
                                type: 'data_select',
                                options: this.autoCompleteData['modified_by'],
                                disabled: true,
                            },
                        ]}
                    />
                );
            case 'address':
                return (
                    <SliderFieldGroup
                        items={[data]}
                        editButtonLocation="top"
                        onItemSaved={this.onContactEdited}
                        editingDisabled={!writePrivileges}
                        fields={[
                            {
                                key: 'street_address',
                                title: this.tr('Street Address'),
                                forceVisible: true,
                            },
                            ...(usMode
                                ? [
                                      {
                                          key: 'city',
                                          title: this.tr('City'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'state',
                                          title: this.tr('State'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'postal_code',
                                          title: this.tr('Zip code'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'country',
                                          title: this.tr('Country'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                  ]
                                : [
                                      {
                                          key: 'postal_code',
                                          title: this.tr('Zip code'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'city',
                                          title: this.tr('City'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                      {
                                          key: 'country',
                                          title: this.tr('Country'),
                                          disabled: !writePrivileges,
                                          forceVisible: true,
                                      },
                                  ]),
                        ]}
                    />
                );
            case 'add_relation':
                return null;
            default:
                return null;
        }
    };

    renderDetailsTopSection = () => {
        const { data: contact, contactRelationData } = this.state;
        const validContactRelations = (contactRelationData || []).filter((c) => c.customers_id != 0);
        return (
            <div className={`${cardStyles.detailsTopSection} ${styles.detailsTopSection}`}>
                <div className={cardStyles.titleRow}>
                    {contact.id > -1 && <TaimerAvatar noavatar name={`${contact.firstname} ${contact.lastname}`} />}
                    <div className={`${cardStyles.titles} ${!this.props.id ? cardStyles.noTopMargin : ''}`}>
                        <h1>{contact.id == -1 ? this.tr('New contact') : contact.firstname ? `${contact.firstname} ${contact.lastname}` : <Skeleton />}</h1>
                        {contact.id != -1 && contact.title && <p>{contact.title}</p>}
                    </div>
                </div>
                {contact.id > -1 && (
                    <div className={`${cardStyles.keyInfo} ${cardStyles.iconSection}`}>
                        {validContactRelations.length > 0 && (
                            <div className={cardStyles.iconRow}>
                                <div className={cardStyles.icon}>
                                    <Business />
                                </div>
                                <p>
                                    {_.uniqBy(validContactRelations, (val) => val.customers_id)
                                        .map((r) => r.customers_name)
                                        .join(', ')}
                                </p>
                            </div>
                        )}
                        {contact.phone && (
                            <div className={cardStyles.iconRow}>
                                <div className={cardStyles.icon}>
                                    <Phone />
                                </div>
                                <Link url={`tel:${contact.primary_phone}`}>{contact.primary_phone}</Link>
                                <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr('Copy to clipboard')} placement="right" arrow>
                                    <button
                                        onClick={() => {
                                            this.props.enqueueSnackbar(this.tr('Copied to clipboard!'), { variant: 'success' });
                                            navigator.clipboard.writeText(contact.primary_phone);
                                        }}
                                    >
                                        <CopyAll />
                                    </button>
                                </Tooltip>
                            </div>
                        )}
                        <div className={cardStyles.iconRow}>
                            <div className={cardStyles.icon}>
                                <Email />
                            </div>
                            <Link url={`mailto:${contact.primary_email}`}>{contact.primary_email}</Link>
                            <Tooltip classes={{ tooltip: 'darkblue-tooltip' }} title={this.tr('Copy to clipboard')} placement="right" arrow>
                                <button
                                    onClick={() => {
                                        this.props.enqueueSnackbar(this.tr('Copied to clipboard!'), { variant: 'success' });
                                        navigator.clipboard.writeText(contact.primary_email);
                                    }}
                                >
                                    <CopyAll />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    onDetailsScroll = (e) => {
        if (!this.props.id) return;
        if (e.currentTarget.scrollTop >= 100 && !this.showContactNameInHeader) {
            this.showContactNameInHeader = true;
            this.props.setShowContactNameInHeader(true);
        } else if (e.currentTarget.scrollTop < 100 && this.showContactNameInHeader) {
            this.showContactNameInHeader = false;
            this.props.setShowContactNameInHeader(false);
        }
    };

    showAccountRelationSlider = () => {
        // TODO: account/project switch.
        const { 
            showSlider, 
            closeSlider 
        } = this.context.functions;
        const {
            companies,
            customerlist,
            customersMap,
            data
        } = this.state;

        showSlider(<RelationSlider 
            afterSave={() => this.fetchContactRelations()}
            contactId={this.props.id}
            closeSlider={closeSlider}
            relationExists={this.relationExists}
            showRelationExistsSnackbar={this.showRelationExistsSnackbar}
            itemBase={{
                title: data.primary_title,
                position: data.primary_vacance,
                phone: data.primary_phone,
                email: data.primary_email,
                accountId: data.account.id
            }}
            sharedData={{
                autoCompleteData: this.autoCompleteData,
                companies: companies,
                customers: customerlist,
                customersMap: customersMap,
            }} />);
    };

    render() {
        const { data, companies, contactRelationData, contactRelationOption } = this.state;

        const commonConfig = { sortable: false };
        const columnConfig = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false, ...commonConfig };

        const contactData = data;

        const contact_history = {
            id: -1,
            companies_id: this.props.company || this.context.userObject.companies_id,
            customers_id: '',
            customer_partner: null,
            mailing_lists: []
        };

        return (
            <div id={styles.personalDetails} ref={this.outerContainerRef}>
                {(!this.state.autoCompleteDataDone || !this.state.data) && (
                    <div className={styles.savingOverlay}>
                        <img src={require('../dashboard/insights/img/loading.svg').default} />
                    </div>
                )}
                <div className={cardStyles.detailsBox} onScroll={this.onDetailsScroll}>
                    {this.renderDetailsTopSection()}
                    <div className={styles.detailsSections}>
                        <ToggleableContainerList sections={this.detailsSections} renderContentForSection={this.renderContentForDetailsSection} />
                    </div>
                </div>
                <div className={cardStyles.mainContent}>
                    <div className={styles.listContainer}>
                        <h3>{this.tr('Associated accounts')}</h3>
                        <div>
                            <List
                                ref={this.customerRelationList}
                                height="fitRemaining"
                                disableInitialFocusOnRow={true}
                                userListSettingsKey="contact_relations_list"
                                saveColumnConfig={true}
                                fluid={true}
                                fixFluidOverflow={true}
                                noStateData={true}
                                manualCreate={true}
                                beforeNew={() => {
                                    this.showAccountRelationSlider();
                                    return false;
                                }}
                                sharedData={{
                                    companies,
                                    contactRelationOption,
                                    contact: this.state.data.id,
                                    company: this.props.company || companies.companies_id,
                                    customersMap: this.state.customersMap,
                                    titles: this.autoCompleteData?.titles || [],
                                    positions: this.autoCompleteData?.positions || [],
                                    emailLists: this.autoCompleteData?.all_mailing_lists || []
                                }}
                                columns={[
                                    { field: 'context', name: 'context', header: '', columnHeaderType: this.props.id > 0 ? 'roundButton' : null, width: 160, ...columnConfig },
                                    { field: 'companies_id', name: 'companies_id', header: this.tr('Company'), width: 300, resizeable: true, moveable: true, ...commonConfig },
                                    { field: 'customers_id', name: 'customers_id', header: this.tr('Account'), width: 300, resizeable: true, moveable: true, ...commonConfig },
                                    { field: 'contact_type', name: 'contact_type', header: this.tr('Contact type'), width: 300, resizeable: true, moveable: true, ...commonConfig },
                                    { field: 'title', name: 'title', header: this.tr('Title'), width: 300, resizeable: true, moveable: true, ...commonConfig },
                                    { field: 'email', name: 'email', header: this.tr('Email'), width: 300, resizeable: true, moveable: true, ...commonConfig },
                                    { field: 'phone', name: 'phone', header: this.tr('Phone'), width: 300, resizeable: true, moveable: true, ...commonConfig },
                                    { field: 'position', name: 'position', header: this.tr('Position'), width: 300, resizeable: true, moveable: true, ...commonConfig },
                                    { field: 'mailing_lists', name: 'mailing_lists', header: this.tr('Email lists'), width: 300, resizeable: true, moveable: true, ...commonConfig },
                                ]}
                                data={this.state.data.id > 0 ? contactRelationData : data.contact_history}
                                newRow={contact_history}
                                listRowType={CustomerRelationRow}
                                rowProps={{
                                    checkPrivilege: this.context.functions.checkPrivilege,
                                    outerContainerRef: this.outerContainerRef,
                                    enqueueSnackbar: this.props.enqueueSnackbar,
                                    onDelete: (data) => {
                                        if (data.id > 0) {
                                            return DataHandler.delete({ url: 'delete_relation' }, { id: data.id, company: this.state.data.companies_id }, data).then(() =>
                                                setTimeout(() => {
                                                    this.fetchContactRelations();
                                                }, 1000)
                                            );
                                        } else {
                                            this.customerRelationList.current.removeRow(data.id);
                                        }
                                    },
                                    onUpdate: async (data) => {
                                        const postData = {
                                            id: data.id,
                                            customers_id: data.customers_id,
                                            companies_id: data.companies_id,
                                            customer_partner: data.customer_partner,
                                            email: data.email,
                                            phone: data.phone,
                                            title: data.title,
                                            position: data.positions_id,
                                            mailing_lists: data.mailing_lists
                                        };

                                        // If the user is editing the relation that's
                                        // been marked as the primary one, these changes
                                        // need to be propagated to the contact's basic information.
                                        if(data.id === contactData.primary_relations_id) {
                                            this.onContactEdited({
                                                title: data.title,
                                                vacance: data.positions_id,
                                                email: data.email,
                                                phone: data.phone
                                            }, ["on_mailing_lists"]); 
                                            // Exclude on_mailing_lists
                                            // to prevent overwriting
                                            // what we just potentially deleted.
                                        }

                                        if(this.relationExists(data)) {
                                            this.showRelationExistsSnackbar();

                                            return;
                                        }

                                        await DataHandler.post({ url: `contacts/${this.props.id}/update_relation/${data.id}` }, 
                                            { data: postData });

                                        this.fetchContactRelations();


                                        // if (this.state.data.id < 0) {
                                            // this.contactHistory(data);
                                            // return;
                                        // }
                                        // let contact = this.props.id;
                                        // return DataHandler.post({ url: 'contacts/' + contact + '/update_relation' }, { data, company: data.companies_id }).done((data) => {
                                            // setTimeout(() => this.fetchContactCustomerTreeList(data.companies_id), 1000); is already done inside fetchContactRelations
                                            // setTimeout(() => {
                                                // this.fetchContactRelations();
                                            // }, 1000);
                                        // });
                                    },
                                }}
                            />
                        </div>
                    </div>
                    {this.state.projects.length > 0 && (
                        <div className={styles.listContainer}>
                            <h3>{this.tr('Associated projects')}</h3>
                            <div>
                                <List
                                    userListSettingsKey="contacts_projects"
                                    saveColumnConfig={true}
                                    noStateData={true}
                                    fluid={true}
                                    height="fitRemaining"
                                    columns={[
                                        { field: 'project', name: 'project', header: this.tr('Project'), width: 1, resizeable: true, moveable: true, showMenu: false, showResizeMarker: true },
                                        { field: 'tags', name: 'tags', header: this.tr('Tags'), width: 1, resizeable: true, moveable: true, showMenu: false, showResizeMarker: true },
                                    ]}
                                    data={this.state.projects}
                                    listRowType={ContactsProjectRow}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withSnackbar(TabPersonalDetails);
