import TaimerComponent from '../TaimerComponent';
import StyledTooltip from '../general/StyledTooltip';

import styles from './CellRenderer.module.scss';

export interface Tooltip {
    content: any;
    placement: string;
}

interface Props {
    value: string;
    tooltip?: Tooltip;
    icon?: any;
    justifyContent?: "left" | "right";
}

const renderIcon = (Icon, tooltip) => {
    if (tooltip) {
        return  (
            <StyledTooltip {...tooltip}>
                <Icon className={styles.icon} />
            </StyledTooltip>
    )}

    return <Icon className={styles.icon} />;
}

export default class IconCell extends TaimerComponent<Props> {
    render() {

        const { value, icon, justifyContent, tooltip } = this.props;
        const contentPlacement = justifyContent == "right" ? "right" : "left";
 
        return (
            <div className={styles.iconCell}>
                <div className={`${styles.container} ${contentPlacement == "right" ? styles.contentEnd : ''} ${icon ? styles.hasIcon : ''}`}> 
                    {contentPlacement == "left" && value}
                    {icon && renderIcon(icon, tooltip)}
                    {contentPlacement == "right" && value}
                </div>
            </div>
        )
    }
}