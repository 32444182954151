import React from 'react'

/* css */
import './ActivitiesInsightList.css';

import withStyles from '@mui/styles/withStyles';

/* others */
import _ from 'lodash';
import List from "../../../list/List";
import DataHandler from './../../../general/DataHandler';
import TaimerComponent from "../../../TaimerComponent";
import { SettingsContext } from '../../../../src/SettingsContext';
import ActivitiesInsightListRow from './ActivitiesInsightListRow';
import MassDialog from '../../../dialogs/mass_operations/CoreDialog';
import { endOfMonth, startOfMonth, addMonths } from "date-fns";
import { withSnackbar } from 'notistack';
import colors from '../../../colors';

const styles = theme => ({
    chip_due: {
        color: 'white',
        height: '27px',
        'background-color': '#ffb822',
        width: '100%'
    },
    chip_done: {
        color: 'white',
        height: '27px',
        'background-color': colors.greenish_cyan,
        width: '100%'
    },
    chip_overdue: {
        color: 'white',
        height: '27px',
        'background-color': '#fa273d',
        width: '100%'
    }
});

class ActivitiesInsightList extends TaimerComponent {

    static contextType = SettingsContext;

    constructor(props, context){
        super(props, context, "dashboard/insights/activities/ActivitiesInsightList");

        this.state = {
            data: {},
            open: true,
            loaded: false,
            dialogOpen: false,
            dialogData: {},
            activityTypes: {},
            dialogOpenEdit: false,
            elementWidthSet: false,
            sortedActivities: {},
            emptyDataOverlay: false,
            dialogModifyOpen: false,
            autoCompleteData: false,
            selectedUser: {name: context.userObject.fullname, id: context.userObject.usersId},
            activities: props.activities,
            company: props.company
        }

        const columnConfigKey = "taimer_list_settings_for_activitiesinsight_list";
        let savedColumns = _.cloneDeep(JSON.parse(localStorage.getItem(columnConfigKey)));
        
        // Make sure that context column goes to right place if column order is saved
        if (savedColumns) {
            const contextColumnIndex = savedColumns.findIndex(s => s.name == "context");
            if (contextColumnIndex > 0) {
                savedColumns.splice(contextColumnIndex, 1);
                savedColumns.unshift({ name: "context", width: 50, visible: true });
                localStorage.setItem(columnConfigKey, JSON.stringify(savedColumns));
            }
        }

        this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
        this.deleteActivity    = this.deleteActivity.bind(this);
    }
    
    componentDidMount(){
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.company !== this.state.company) {
            this.setState({company: this.props.company});
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.activities) this.setState({activities: nextProps.activities})
    }

    //Marks activity done based on this id and project_id
    //(if project_id is empty then its customer activity).
    onMarkDone = (data) => {
        let activity = {
			activity_id: data.id,
            projects_id: data.projects_id,
			ready: 1,
		}
        DataHandler.post({url: `activities/state`},{activity}).done((response) => {
            this.props.getActivityData();
        });
    };

    //Marks activity undone based on this id and project_id
    //(if project_id is empty then its customer activity)
    onMarkUndone = (data) => {
        let activity = {
			activity_id: data.id,
			projects_id: data.projects_id,
			ready: 0,
		}
        DataHandler.post({url: `activities/state`},{activity}).done((response) => {
            this.props.getActivityData();
        });
    };

    onActivityDelete(data) {
        this.setState({ openDeleteDialog: true, deleteDialogData: data })
    }

    closeDeleteDialog() {
        this.setState({ openDeleteDialog: false, deleteDialogData: undefined })
    }

    //Deletes the activity based on this id and type
    deleteActivity = () => {
        let activityId = this.state.deleteDialogData.id;
        let type = this.state.deleteDialogData.type;
        DataHandler.request("DELETE", {url: "activities/" + activityId + "/" + type }).done((response) => {
            this.props.onListSettingsChange({ page: 1 });
            this.props.enqueueSnackbar(this.tr('Activity deleted successfully!'), {
                variant: 'success'
            });
        }).fail(response => {
            this.props.getActivityData();
        });

        this.closeDeleteDialog();
    };

    //Closes activity dialog
    onCloseActivityDialog = (action) => {
        this.props.getActivityData();
        action && this.props.updateProjectData && this.props.updateProjectData();
        action && this.props.updateAccountData && this.props.updateAccountData();
    }

    //Opens and closes List
    handleOpen = () => {
        this.setState({open: !this.state.open});
    }

    //Opens activity dialog
    onActivityDialog = (data = false) => {

        if(data) {
            const activity = {
                activity_id: data.id,
                projects_id: data.projects_id
            }

            const activityModule = data.type;

            DataHandler.get({url: `activities/get`, activity}).done(data => {
                const dialogData = {
                    id: data.id,
                    activityModule,
                    type_id: data.type_id,
                    description: data.description,
                    ready: data.ready,
                    date: data.date,
                    customers_id: data.customers_id,
                    projects_id: data.projects_id,
                    assigned_to_id: data.assigned_to,
                    contact: data.contact_id,
                    companies_id: this.state.company
                };
                this.context.functions.openActivitySlider(dialogData, { afterSave: this.onCloseActivityDialog })
            })
        } else {

            if(this.props.accountId) {

                if(this.props.projectId) {
                    const dialogData = {
                        customers_id: this.props.accountId,
                        projects_id: this.props.projectId,
                        companies_id: this.state.company,
                        activityModule: "project"
                    };
                    this.context.functions.openActivitySlider(dialogData, { afterSave: this.onCloseActivityDialog })
                } else {
                    const dialogData = {
                        customers_id: this.props.accountId,
                        companies_id: this.state.company,
                        activityModule: "customer"
                    };
                    this.context.functions.openActivitySlider(dialogData, { afterSave: this.onCloseActivityDialog })
                }       
            } else {
                this.context.functions.openActivitySlider();
            }
        } 
    }

    render() {
        let columns = [      
            { name: "type", header: this.tr("Type"), width: 65, resizeable: true, moveable: true },
            { name: "status", header: this.tr("Status"), width: 110, resizeable: true },
            { name: "created", header: this.tr("Created"), width: 100, resizeable: true },
            { name: "reminder", header: this.tr("Reminder"), width: 100, resizeable: true },
            { name: "customer", header: this.tr("Account"), width: 200, resizeable: true },
            { name: "project", header: this.tr("Project"), width: 200, resizeable: true },
            { name: "description", header: this.tr("Description"), width: 200, resizeable: true },
            { name: "contact", header: this.tr("Contact"), width: 200, resizeable: true },
            { name: "createdby", header: this.tr("Created By"), width: 200, resizeable: true },
            { name: "targetto", header: this.tr("Target To"), width: 200, resizeable: true },
        ];

        const haswritepermission = this.props.haswritepermission || this.props.has_write_permission;
        columns.unshift({ name: "context", header: "", width: 50, columnHeaderType: haswritepermission ? "roundButton" : "", resizeable: false, showResizeMarker: false, moveable: false, hideable: false, showMenu: false });
        
        let pageProps = {
            showPageSelector: false, 
        };

        if(this.props.showPageSelector) {
            pageProps = {
                showPageSelector: true,
                pageCount: this.props.pageData.pageCount,
                totalCount: this.props.pageData.activitiesCount,
                perpage: this.props.pageData.perpage,  
                page: this.props.pageData.page
            };
        }

        return (
            <React.Fragment>
                {this.state.openDeleteDialog &&
                    <MassDialog
                        onDialogClose={this.closeDeleteDialog}
                        onDialogSave={this.deleteActivity}
                        dialogType={"delete"}
                        dialogProps={{
                            wider: true,
                            onCloseClick: this.closeDeleteDialog,
                            open: this.state.openDeleteDialog,
                            close: this.closeDeleteDialog,
                            header: this.tr("Delete activity") + '?',
                            warning: () => {
                                return <p id="product-delete-dialog-warning">{this.tr("Are you sure you want to delete activity \"${activity}\"?", {activity: this.state.deleteDialogData.description})}</p>;
                            },
                            onConfirm: () => {
                                this.deleteActivity();
                            }

                        }}
                    />
                }
                <List
                    ref={this.list}
                    columns={columns}
                    fluid={true}
                    data={this.state.activities}
                    height={ this.props.height && this.props.height }
                    trimHeight={ this.props.trimHeight && this.props.trimHeight }
                    rowKey="key"
                    noStateData={true}
                    listRowType={ActivitiesInsightListRow}
                    rowProps={{
                        enqueueSnackbar: this.props.enqueueSnackbar,
                        onActivityDialog: (data) => {
                            this.onActivityDialog(data);
                        },
                        onActivityDelete: (data) => {
                            this.onActivityDelete(data);
                        },
                        onMarkUndone: (data) => {
                            this.onMarkUndone(data);
                        },
                        onMarkDone: (data) => {
                            this.onMarkDone(data);
                        },
                        onCellEdited: (activity_id, type, name, value) => {
                            DataHandler.post({url: `activities/update`}, { activity_id, type, name, value }).done((response) => {
                                this.props.getActivityData();
                            });
                        },
                        company: this.props.company,
                        module: this.props.module,
                        showLockedUsersWithTag: true
                    }}
                    {...pageProps}
                    userListSettingsKey="activitiesinsight_list"
                    saveColumnConfig={true}
                    roundbutton={()=>this.onActivityDialog()}
                    onPerPageChange={perpage => {
                        this.props.onListSettingsChange({ perpage: perpage, page: 1 });
                    }}
                    onPageChange={page => {
                        this.props.onListSettingsChange({ page: page });
                    }}
                    onSortRows={(colName, asc) => {
                        this.props.onListSettingsChange({ sortby: colName, sortasc: asc, page: 1 });
                    }}
                    controlPage={true}
                    ignoreRowPropsChange={false}
                    onCheckAll={() => {  }}
                    onUncheckAll={() => {  }} />
            </React.Fragment>
        )
    }
}

export default withSnackbar(ActivitiesInsightList);